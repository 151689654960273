<template>
    <div id="app">
        <component :is="headerComponent"></component>

        <div style="display: flex; flex-direction: column;">
            <div class="content-wrapper">
                <!-- Левый сайдбар -->
                <aside v-if="!isMobile" class="sidebar-left">
                    <div class="nav-block">
                        <nav class="nav-menu">
                            <button v-for="item in navItems" :key="item.id"
                                :class="['nav-item', { active: activeNavItem === item.id }]"
                                @click="handleNavItemClick(item.id)">
                                <div class="icon-container" v-html="iconItems[item.id]"></div>
                                <span class="nav-text">{{ item.text }}</span>
                            </button>
                        </nav>
                        <!-- profile-nav с обработчиком клика и ссылкой на ref -->
                        <div class="profile-nav" @click.stop="toggleProfileMenu" ref="profileNavRef">
                            <img src="https://storage.yandexcloud.net/testbucketplatforma34/woman_blue_background.jpeg"
                                class="profile-nav">
                        </div>
                        <div v-if="isProfileMenuOpen">
                            <div class="profile-menu-container">
                                <ProfileMenu />
                            </div>
                            <!-- <ProfileMenu /> -->
                            <!-- <div style="background-color: lightblue; padding: 20px;">
                                    <p>Меню профиля</p>
                                </div> -->
                        </div>
                    </div>
                </aside>


                <main class="main-content">
                    <router-view />
                </main>
                <component :is="footerComponent"></component>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, ref, onMounted, onBeforeUnmount } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { useRouter, useRoute } from 'vue-router'

import HeaderDefault from './components/Layouts/HeaderDefault.vue'
import HeaderLanding from './components/Layouts/HeaderLanding.vue'

import FooterComponentVue from './components/Layouts/FooterComponent.vue'
import FooterLandingComponent from './components/Layouts/FooterLandingComponent.vue'

import ProfileMenu from './components/Layouts/ProfileMenu.vue'

import HeaderMobile from './components/Layouts/HeaderMobile.vue'

export default {
    name: "App",
    components: {
        FooterComponentVue,
        HeaderDefault,
        HeaderLanding,
        FooterLandingComponent,
        ProfileMenu,
    },
    setup() {
        const { width } = useWindowSize()
        // edited
        // const isMobile = computed(() => width.value < 768)
        const isMobile = computed(() => width.value < 1024)
        const activeNavItem = ref<string | undefined>(undefined)
        const router = useRouter()
        const route = useRoute()

        const navItems = [
            { id: 'calendar', text: 'Календарь', route: '/CalendarPage' },
            { id: 'chats', text: 'Чаты', route: '/ChatsPage' },
            { id: 'create', text: 'Создать', route: '/CreateEventPage' },
            { id: 'menteeLanding', text: 'Ученику', route: '/LandingMenteePage' },
            { id: 'mentorLanding', text: 'Наставнику', route: '/Home' },
        ]

        const iconItems = {
            calendar: `
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.11111 6.88889V2M17.8889 6.88889V2M6.88889 11.7778H19.1111M4.44444 24H21.5556C22.9056 24 24 22.9056 24 21.5556V6.88889C24 5.53886 22.9056 4.44444 21.5556 4.44444H4.44444C3.09442 4.44444 2 5.53886 2 6.88889V21.5556C2 22.9056 3.09441 24 4.44444 24Z" stroke="currentColor" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            chats: `
                <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5.11149L11.6441 11.5409C12.4652 12.0883 13.5348 12.0883 14.3559 11.5409L24 5.11149M4.44444 18.5559H21.5556C22.9056 18.5559 24 17.4615 24 16.1115V3.88927C24 2.53924 22.9056 1.44482 21.5556 1.44482H4.44444C3.09442 1.44482 2 2.53924 2 3.88927V16.1115C2 17.4615 3.09441 18.5559 4.44444 18.5559Z" stroke="currentColor" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            create: `
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0004 1.22217V20.7777M20.7782 10.9999L1.22266 10.9999" stroke="currentColor" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            menteeLanding: `
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.428 12.4282C18.1488 12.149 17.7932 11.9587 17.406 11.8812L15.0185 11.4037C13.7101 11.1421 12.3519 11.324 11.1585 11.9207L10.8411 12.0793C9.64772 12.676 8.28948 12.8579 6.98113 12.5963L5.04938 12.2099C4.39366 12.0788 3.71578 12.284 3.24294 12.7569M6.9998 1H14.9998L13.9998 2V7.17157C13.9998 7.70201 14.2105 8.21071 14.5856 8.58579L19.5856 13.5858C20.8455 14.8457 19.9532 17 18.1714 17H3.82823C2.04642 17 1.15409 14.8457 2.41401 13.5858L7.41402 8.58579C7.78909 8.21071 7.9998 7.70201 7.9998 7.17157V2L6.9998 1Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            mentorLanding: `
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.428 12.4282C18.1488 12.149 17.7932 11.9587 17.406 11.8812L15.0185 11.4037C13.7101 11.1421 12.3519 11.324 11.1585 11.9207L10.8411 12.0793C9.64772 12.676 8.28948 12.8579 6.98113 12.5963L5.04938 12.2099C4.39366 12.0788 3.71578 12.284 3.24294 12.7569M6.9998 1H14.9998L13.9998 2V7.17157C13.9998 7.70201 14.2105 8.21071 14.5856 8.58579L19.5856 13.5858C20.8455 14.8457 19.9532 17 18.1714 17H3.82823C2.04642 17 1.15409 14.8457 2.41401 13.5858L7.41402 8.58579C7.78909 8.21071 7.9998 7.70201 7.9998 7.17157V2L6.9998 1Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
        };

        const handleNavItemClick = (itemId: string) => {
            activeNavItem.value = itemId
            const selectedItem = navItems.find(item => item.id === itemId)
            if (selectedItem) {
                router.push(selectedItem.route)
            }
        }

        const headerComponent = computed(() => {
            if (isMobile.value) {
                return HeaderMobile;
            }
            else {
                const headerType = route.meta.header; // Получаем метаданные текущего маршрута
                return headerType === 'Landing' ? HeaderLanding : HeaderDefault;
            }
        });

        const footerComponent = computed(() => {
            const footerType = route.meta.footer;
            return footerType === 'Landing' ? FooterLandingComponent : FooterComponentVue;
        });

        // Состояние для управления видимостью меню профиля
        const isProfileMenuOpen = ref(false)

        // Ссылка на элемент profile-nav
        const profileNavRef = ref<HTMLElement | null>(null)

        // Функция для переключения меню профиля
        const toggleProfileMenu = () => {
            isProfileMenuOpen.value = !isProfileMenuOpen.value
            console.log('Menu toggled:', isProfileMenuOpen.value)
        }

        // Функция для закрытия меню при клике вне области
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement
            if (profileNavRef.value && !profileNavRef.value.contains(target)) {
                isProfileMenuOpen.value = false
                console.log('Clicked outside, menu closed')

            }
        }

        // Обработчик нажатия клавиши Esc
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                isProfileMenuOpen.value = false
                console.log('Escape pressed, menu closed')
            }
        }

        onMounted(() => {
            document.addEventListener('click', handleClickOutside)
            document.addEventListener('keydown', handleKeyDown)
        })

        onBeforeUnmount(() => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        })

        return {
            isMobile,
            activeNavItem,
            navItems,
            handleNavItemClick,
            headerComponent,
            footerComponent,
            iconItems,
            isProfileMenuOpen,
            toggleProfileMenu,
            profileNavRef,
        }
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'PlatformaFont';
    src: url('/root/Platforma_maxim/Platforma/static/frontend/vueapp/src/assets/fonts/TildaSans-VF_TTF_Web/TildaSans-VF.ttf') format('truetype');
}

#app {
    font-family: 'PlatformaFont', sans-serif;
    max-width: 100%;
}

.main-content {
    flex: 1;
    padding: 24px;
    background: #F3F4F5;
    margin-left: 130px;
}

.content-wrapper {
    display: flex;
    margin-top: 64px;
    flex-direction: column;
}

.sidebar-left {
    position: fixed;
    min-height: calc(100vh - 64px);
    background: #f3f4f5;
    border-right: 1px solid #E5E7EB;
    padding: 24px 16px;
    z-index: 100;
    max-width: 130px;
}

.nav-menu {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.nav-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #6B7280;
    text-align: center;
    /* transition: color 0.3s; */
}

.nav-text {
    font-size: 14px;
    /* transition: color 0.3s; */
}

.nav-item:hover {
    background: #F3F4F6;
}

.nav-item.active {
    color: #705CE2;
}

.icon-container {
    width: 40px;
    height: 40px;
    background: #F3F4F5;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
}

.nav-item.active .icon-container {
    background: #E0DFFF;
    /* Лёгкий фиолетовый оттенок для активного состояния */
}

.icon-container svg {
    width: 20px;
    height: 20px;
    transition: stroke 0.3s;
}

.search-section {
    display: flex;
    align-items: center;
    gap: 16px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background: #F3F4F6;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 14px;
}

.dropdown-arrow {
    font-size: 10px;
    color: #6B7280;
}

.dropdown-content {
    display: none;
    position: absolute;
    background: white;
    min-width: 160px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a {
    color: #1F2937;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 14px;
}

.dropdown-content a:hover {
    background: #F3F4F6;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}

.search-input {
    width: 100%;
    padding: 8px 8px 8px 36px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    background: transparent;
}

.search-input:focus {
    outline: none;
}

.search-container:focus-within {
    border-color: #8162E9;
    box-shadow: 0 0 0 2px rgba(129, 98, 233, 0.1);
}

.search-button {
    position: absolute;
    left: 8px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
}

.profile {
    width: 42px;
    height: 42px;
    background: #F3F4F6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    object-fit: cover;
}

.logo-section {
    cursor: pointer;
}

/* edited */
/* @media (max-width: 767px) { */
@media (max-width: 1023px) {
    .main-content {
        margin-left: 0;
        padding: 20px;
    }

    .sidebar-left {
        display: none;
    }

    .content-wrapper {
        margin-top: 136px;
    }
}

.profile-nav {
    position: relative;
    /* Для позиционирования меню внутри */
    width: 56px;
    height: 56px;
    background: #8162E9;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    object-fit: cover;
    margin-top: auto;
}

.profile-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-menu-container {
    position: absolute;
    /* top: 50%; */
    /* Выравнивание по вертикали */
    /* left: 100%; */
    /* Позиционирование справа */
    /* transform: translateY(-50%) scale(0.95); */
    margin-left: 70px;
    bottom: 50px;
    margin-bottom: auto;
    /* Отступ между аватаркой и меню */
    /* z-index: 9999; */
    /* Чтобы меню было поверх других элементов */
    /* Адаптивность */
    /* max-width: 300px; */
    /* width: 100%; */
    /* opacity: 0; */
    /* transition: opacity 0.3s ease, transform 0.3s ease; */
}

/* Корректные классы для переходов */
.fade-scale-enter-active,
.fade-scale-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-scale-enter-from {
    opacity: 0;
    transform: translateY(-50%) scale(0.95);
}

.fade-scale-enter-to {
    opacity: 1;
    transform: translateY(-50%) scale(1);
}

.fade-scale-leave-from {
    opacity: 1;
    transform: translateY(-50%) scale(1);
}

.fade-scale-leave-to {
    opacity: 0;
    transform: translateY(-50%) scale(0.95);
}

.nav-block {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    height: calc(100vh - 110px);
    justify-content: space-between;
    align-items: center;
}
</style>
