<template>
    <div class="app">
        <div class="main-container">
            <main class="main-content">
                <h1 class="page-title">Настройки</h1>

                <div class="tabs">
                    <button v-for="tab in tabs" :key="tab.id" :class="['tab', { active: activeTab === tab.id }]"
                        @click="activeTab = tab.id">
                        {{ tab.name }}
                    </button>
                </div>

                <component :is="currentComponent" />
            </main>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed } from 'vue'
import { SearchIcon, PlusIcon, CalendarIcon, MessageSquareIcon } from 'lucide-vue-next'
import Profile from '../components/Profile.vue'
import Education from '../components/Education.vue'
import Privacy from '../components/Privacy.vue'
import Blacklist from '../components/Blacklist.vue'

const activeTab = ref('profile')

const tabs = [
    { id: 'profile', name: 'Профиль' },
    { id: 'education', name: 'Образование и опыт' },
    // { id: 'privacy', name: 'Приватность' },
    // { id: 'blacklist', name: 'Чёрный список' }
]

const currentComponent = computed(() => {
    switch (activeTab.value) {
        case 'profile':
            return Profile
        case 'education':
            return Education
        // case 'privacy':
        //     return Privacy
        // case 'blacklist':
        //     return Blacklist
        default:
            return null
    }
})
</script>

<style scoped>

body {
    margin: 0;
    padding: 0;
    background-color: #F8F8F8;
}

.app {
    min-height: 100vh;
}

.header {
    background-color: white;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: #7F56D9;
    font-size: 24px;
    font-weight: 600;
}

.header-controls {
    display: flex;
    align-items: center;
    gap: 8px;
}

.select-wrapper {
    position: relative;
}

.select-wrapper select {
    appearance: none;
    background-color: #F2F4F7;
    color: #667085;
    padding: 8px 32px 8px 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
}

.select-wrapper::after {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #667085;
}

.search-wrapper {
    position: relative;
}

.search-wrapper input {
    background-color: #F2F4F7;
    border: none;
    border-radius: 4px;
    padding: 8px 12px 8px 36px;
    width: 230px;
    font-size: 14px;
}

.search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #667085;
    width: 16px;
    height: 16px;
}

.notifications {
    display: flex;
    gap: 8px;
    margin-left: 8px;
}

.notification {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}

.notification.red {
    background-color: #FEE4E2;
    color: #D92D20;
}

.notification.orange {
    background-color: #FEF0C7;
    color: #B54708;
}

.main-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}

.sidebar {
    width: 200px;
    padding-top: 24px;
    padding-right: 32px;
}

.sidebar-button {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 12px;
    border: none;
    background: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    cursor: pointer;
    transition: background-color 0.2s;
}

.sidebar-button:hover,
.sidebar-button.active {
    background-color: #F4EBFF;
}

.sidebar-button.active {
    color: #7F56D9;
}

.main-content {
    flex: 1;
    padding-top: 24px;
    padding-bottom: 64px;
}

.page-title {
    font-size: 28px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 24px;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #D0D5DD;
    margin-bottom: 32px;
}

.tab {
    padding: 12px 4px;
    margin-right: 24px;
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: #667085;
    cursor: pointer;
    position: relative;
}

.tab.active {
    color: #7F56D9;
}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #7F56D9;
}
</style>