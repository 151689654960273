<template>
    <div id="Mentor" style="padding: 0">
        <BackButtonVue></BackButtonVue>
        <Spacer32 />
        <div class="MentorHero">

            <div class="MentorHeroWrapper">
                <div class="MentorAvatar">
                    <div class="profile-container">
                        <div class="profile-photo">
                            <img src="https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200"
                                :alt="alt" @error="handleImageError">
                        </div>
                    </div>
                </div>
                <div class="MentorInfo">
                    <div class="InfoWrapper">
                        <div class="verified-badge">
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9.9999 19.6004C15.3018 19.6004 19.5999 15.3023 19.5999 10.0004C19.5999 4.69846 15.3018 0.400391 9.9999 0.400391C4.69797 0.400391 0.399902 4.69846 0.399902 10.0004C0.399902 15.3023 4.69797 19.6004 9.9999 19.6004ZM14.4484 8.44892C14.9171 7.98029 14.9171 7.22049 14.4484 6.75186C13.9798 6.28323 13.22 6.28323 12.7514 6.75186L8.7999 10.7033L7.24843 9.15186C6.7798 8.68323 6.02 8.68323 5.55137 9.15186C5.08275 9.62049 5.08275 10.3803 5.55137 10.8489L7.95137 13.2489C8.42 13.7175 9.1798 13.7175 9.64843 13.2489L14.4484 8.44892Z"
                                        fill="#998BF8" />
                                </svg>
                            </span>

                            <span class="verified-text">
                                Профиль подтверждён
                            </span>

                        </div>

                        <!-- <h1 class="profile-name">{{ mentor.mentor_name + mentor.mentor_surname }}</h1> -->
                        <h1 class="profile-name">Сергей Мейерхольд</h1>
                        <p class="profile-title">Профессиональный бариста</p>

                        <div class="profile-actions">
                            <a :href="telegramLink" class="telegram-button">
                                <span class="telegram-icon">
                                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.5 0C5.6 0 0 5.824 0 13C0 20.176 5.6 26 12.5 26C19.4 26 25 20.176 25 13C25 5.824 19.4 0 12.5 0ZM18.3 8.84C18.1125 10.894 17.3 15.886 16.8875 18.187C16.7125 19.162 16.3625 19.487 16.0375 19.526C15.3125 19.591 14.7625 19.032 14.0625 18.551C12.9625 17.797 12.3375 17.329 11.275 16.601C10.0375 15.756 10.8375 15.288 11.55 14.534C11.7375 14.339 14.9375 11.31 15 11.037C15.0087 10.9957 15.0075 10.9527 14.9966 10.9119C14.9857 10.8712 14.9654 10.8338 14.9375 10.803C14.8625 10.738 14.7625 10.764 14.675 10.777C14.5625 10.803 12.8125 12.012 9.4 14.404C8.9 14.755 8.45 14.937 8.05 14.924C7.6 14.911 6.75 14.664 6.1125 14.443C5.325 14.183 4.7125 14.04 4.7625 13.585C4.7875 13.351 5.1 13.117 5.6875 12.87C9.3375 11.219 11.7625 10.127 12.975 9.607C16.45 8.099 17.1625 7.839 17.6375 7.839C17.7375 7.839 17.975 7.865 18.125 7.995C18.25 8.099 18.2875 8.242 18.3 8.346C18.2875 8.424 18.3125 8.658 18.3 8.84Z"
                                            fill="white" />
                                    </svg>
                                </span>
                                Написать в Telegram
                            </a>
                            <button class="bookmark-button" @click="toggleBookmark">
                                <span class="bookmark-icon" :class="{ 'active': isBookmarked }">
                                    <svg viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="currentColor"
                                            d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2z" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="MentorDescription">
                    <component :is="isMobile ? 'DescriptionComponent' : 'DescriptionComponentDesktop'"
                        :description="mentorDescription">
                    </component>
                </div>
                <!-- <div class="MentorDescription">
                    <div class="DescriptionWrapper">
                        <div class="BigInfoText">Dmitry Kotkov</div>
                        <br>
                        <div class="MentorDescriptionText">
                            fhejwndn revjfrr wfevjdgvjifrd nfrjdinrudg
                        </div>
                    </div>
                </div> -->
                <div class="MentorExperience">
                    <component :is="isMobile ? 'ExperienceBlock' : 'ExperienceBlockDesktop'" :items="experienceItems"
                        :initialItemsCount="1">
                    </component>
                </div>
                <div class="MentorEducation">
                    <component :is="isMobile ? 'EducationBlock' : 'EducationBlockDesktop'" :items="educationItems"
                        :initialItemsCount="1">
                    </component>
                </div>
            </div>
            <Spacer32 />
            <div class="reviews">
                <ReviewBlock :instance="instance" :id="mentor_id" :reviews="reviews"></ReviewBlock>
            </div>
            <Spacer32 />
        </div>



        <!-- <div class="ActualMero">
            <div class="Title"> Актуальные мероприятия</div>
            <Spacer32 />
            <div class="card-list">
                <div v-for="event of events" :key="event.id">
                    <link-to-event :id="event.id">
                        <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                            :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                            :event_ageRestriction="event.event_ageRestriction"
                            :event_availableSpots="event.event_availableSpots"
                            :event_totalSpots="event.event_totalSpots" :event_category="event.event_category"
                            :id="event.id" :event_id_mentor="event.event_id_mentor">
                        </EventCardVue>
                    </link-to-event>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
/* eslint-disable */
import EventCardVue from "../components/EventCard.vue";
import BackButtonVue from '../components/Navigations/BackButton.vue';
import ReviewBlock from "../components/Review/ReviewBlock.vue";
import axios from 'axios'
import Spacer32 from "../components/Spacers/SpacerModule32.vue";
import LinkToEvent from '../components/Links/LinkToEvent.vue';
import ExperienceBlock from '../components/pages/mentor/ExperienceComponent.vue';
import EducationBlock from '../components/pages/mentor/EducationComponent.vue';
import DescriptionComponent from '../components/pages/mentor/DescriptionComponent.vue';
import ExperienceBlockDesktop from '../components/pages/mentor/ExperienceBlockDesktop.vue';
import EducationBlockDesktop from '../components/pages/mentor/EducationBlockDesktop.vue';
import DescriptionComponentDesktop from '../components/pages/mentor/DescriptionComponentDesktop.vue';
import ref from 'vue';


export default {
    name: 'MentorPage2',
    components: {
        EventCardVue,
        Spacer32,
        BackButtonVue,
        ReviewBlock,
        LinkToEvent,
        ExperienceBlock,
        EducationBlock,
        ExperienceBlockDesktop,
        EducationBlockDesktop,
        DescriptionComponentDesktop,
        DescriptionComponent
    },
    data() {
        return {
            user_name: 'Котков Дмитрий',
            // user_surname: '',
            formats: ['Формат 1', 'Формат 2', 'Формат 3'],
            categories: [
                'Все категории',
                'Здоровье',
                'Карьера',
                'Образование',
                'Красота',
                'Хобби',
                'Просвещение'
            ],
            selectedCategory: 'Все категории',
            events: [],
            reviews: [],
            mentor: null,
            mentor_id: this.$route.params.mentorId,
            isMobile: false,
            viewChanged: 'mentor',
            instance: 'mentor',
            isBookmarked: false,
            experienceItems: [
                {
                    dateRange: 'Янв 2020 — Настоящее время',
                    position: 'Владелец',
                    company: 'Грана кафе 12'
                },
                {
                    dateRange: 'Янв 2018 — Дек 2019',
                    position: 'Управляющий',
                    company: 'Кафе Центральное'
                },
                {
                    dateRange: 'Март 2015 — Дек 2017',
                    position: 'Шеф-повар',
                    company: 'Ресторан Премиум'
                }
            ],
            educationItems: [
                {
                    program: 'Технология аналитического контроля химических соединений',
                    institution: 'РАНХиГС',
                    years: '2011 - 2014'
                },
                {
                    program: 'Химическая технология органических веществ',
                    institution: 'МГУ',
                    years: '2014 - 2016'
                }
            ],
            mentorDescription: "Я профессиональный инструктор с более чем 10-летним опытом работы. Специализируюсь на проведении тренингов по личностному росту и развитию коммуникативных навыков. Мой подход основан на индивидуальном подходе к каждому клиенту, что позволяет достигать максимальных результатов. Я постоянно совершенствую свои методики, следя за последними тенденциями в области психологии и коучинга.",
            // gridHeight: 0,
        };
    },
    methods: {
        handleFormatSelect(option) {
            console.log('Selected format:', option);
        },
        handleImageError(e) {
            e.target.src = this.fallbackUrl;
        },
        toggleBookmark() {
            this.isBookmarked = !this.isBookmarked
        },
        async fetchData() {
            console.log("Started")
            try {
                const response_mentor = await axios.get('https://platforma34.tw1.ru/api/users/GetUser/?id=' + this.$route.params.mentorId);
                const response_events = await axios.get('https://platforma34.tw1.ru/api/events/GetUserEvents/?user_id=' + this.$route.params.mentorId);
                const response_reviews = await axios.get('https://platforma34.tw1.ru/api/user_reviews/GetUserReviews/?to_user_id=' + this.$route.params.mentorId);
                this.events = response_events.data;
                this.reviews = response_reviews.data;
                mentor.value = response_mentor.data;
                console.log(response_events.data)
                console.log(response_reviews.data)
                console.log(response_mentor.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        checkMobile() {
            this.isMobile = window.innerWidth <= 768;
        },
    },
    mounted() {
        this.fetchData();
        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },
    // computed() {
    //     var height = ref(MentorExperience).height;
    //     gridHeight = height;
    // }
}
</script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.MentorImage {
    width: calc((100vw - 3.3333vw) / 3 - 0.8333vw);
    height: calc(5 / 4 * calc((100vw - 3.3333vw) / 3 - 0.8333vw));
    object-fit: cover;
    border-radius: 0.5208vw;
}

.MentorDescription {
    height: 100%;
    min-height: 0;
    grid-area: B;
}

.DescriptionWrapper {
    border-radius: 12px;
    background-color: #F6F6F6;
    padding: 24px;
    display: flex;
    /* gap:20px; */
    flex-direction: column;
}

.MentorContainerDesciption {
    display: flex;
}

.MentorExperience {
    grid-area: C;
    height: 100%;
    min-height: 0;
    /* background-color: #F6F6F6;
    border-radius: 12px;
    padding: 24px;
    justify-content: space-between;
    flex-direction: column;
    display: flex; */
}

.MentorEducation {
    grid-area: D;
    height: 100%;
    min-height: 0;
    /* background-color: #F6F6F6;
    border-radius: 12px;
    padding: 24px;
    justify-content: space-between;
    flex-direction: column;
    display: flex; */
}

.MentorAvatar {
    grid-area: A;
    background-color: #F6F6F6;
    border-radius: 0.5208vw;
    /* padding: 1.5vw; */
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    border: solid lightgray 1px;
    border-radius: 12px;
}

.MentorInfo {
    grid-area: E;
}

.InfoWrapper {
    background-color: white;
    /* border: solid lightgray 1px; */
    border-radius: 12px;
    padding: 24px;
    height: 160px
}

.MentorHeroWrapper {
    display: grid;
    width: 100%;
    gap: 0.4167vw;
    grid-template-columns: repeat(3, 1fr);
    /* Шесть равных колонок */
    grid-template-areas:
        "A B B"
        "E C D";
    /* grid-template-areas:
        "A A B B B B"
        "A A B B B B"
        "E E C C D D"
        "E E C C D D"; */
}

.MentorHero {
    display: grid;
    gap: 8px;
    width: 100%;
    /* grid-template-columns: 1fr 2fr; */
}

.Title {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
}

.ActualMero {
    display: flex;
    flex-direction: column;
}

.MentorRating {
    padding: 1.5vw;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
}

.BigInfoText {
    font-size: 2.4vw;
    font-weight: 500;
    /* height: 25px; */
    color: #454152;
    text-align: left;
}

.MentorDescriptionText {
    color: #454152;
    font-size: 20px;
    font-weight: 300;
}

.SmallInfoText {
    color: #454152;
    font-size: 1vw;
    font-weight: 300;
}

.profile-container {
    width: 100%;
    height: 100%;
    /* aspect-ratio: 2 / 1; */
    background: repeating-linear-gradient(45deg,
            #f0f0f0,
            #f0f0f0 10px,
            #e0e0e0 10px,
            #e0e0e0 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.verified-badge {
    display: flex;
    /* align-items: center; */
    gap: 6px;
    font-size: 14px;
    margin-bottom: 12px;
}

.verified-text {
    color: #6B4EE6;
}

.profile-name {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 4px 0;
    color: #1A1A1A;
}

.profile-title {
    font-size: 16px;
    color: #666;
    /* margin: 0 0 20px 0; */
}

.profile-actions {
    display: flex;
    gap: 12px;
    align-items: center;
}

.telegram-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #6B4EE6;
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    text-decoration: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    flex-grow: 1;
}

.telegram-button:hover {
    background: #5B3ED6;
}

.bookmark-button {
    background: #F5F5F5;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bookmark-button:hover {
    background: #EBEBEB;
}

.bookmark-icon {
    color: #666;
}

.bookmark-icon.active {
    color: #6B4EE6;
}

.telegram-icon,
.bookmark-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reviews {
    margin-bottom: 2rem;
    width: 88vw;
    /* max-width: calc(100%-); */
}

@media (max-width: 768px) {
    .MentorHeroWrapper {
        gap: 20px;
        grid-template-columns: 1fr;
        grid-template-areas:
            "A"
            "E"
            "B"
            "C"
            "D";
    }

    .MentorExperience,
    .MentorEducation {
        height: auto;
    }

    .BigInfoText {
        font-size: 24px;
    }

    .MentorDescriptionText,
    .SmallInfoText {
        font-size: 16px;
    }

    .ReviewsSection {
        overflow-x: auto;
    }

    .ReviewsSection .review-container {
        display: flex;
        scroll-snap-type: x mandatory;
    }

    .ReviewsSection .review-item {
        flex: 0 0 100%;
        scroll-snap-align: start;
    }
}
</style>
