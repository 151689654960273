<template>
    <div class="border">
        <div class="image">
            <img src='../../assets/images/landing/tormen.svg' alt='Top Left Image' class="tormen-img">
        </div>
        <div class="txtleftcolumn">
            <div class="title">
                Станьте наставником для увлеченных людей со всего мира
            </div>
            <div class="text">
                Делитесь своими знаниями и зарабатывайте на этом.
                Проводите индивидуальные занятия или открытые мастер-классы,
                а мы найдем вам первых учеников и возьмем всю организационную работу на себя
            </div>
        </div>
        <SpacerModule16 />
        <div class="magics">
            <div class="magic">
                <div class="photos">
                    <img v-for="(src, index) in imageURLs" :key="index" :src="src" :alt="'Image ' + (index + 1)"
                        class="overlay-image" :style="getStyle(index)">
                </div>
                <div>
                    <div class="text1">
                        2000+ наставников уже с нами
                    </div>
                    <Spacer8 />
                    <div class="text2"> 
                        Специалисты в разных сферах и с разным опытом
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <div @click="navigateToPage('/register')" class="button1">
                Стать наставником
            </div>
            <div class="button2">
                Подробнее
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import SpacerModule16 from '../Spacers/SpacerModule16.vue';


export default defineComponent({
    setup() {
        const imageURLs = [
            'https://avatars.mds.yandex.net/get-ydo/1529510/2a0000017f372787863ed19a50646fe713cc/diploma',
            'https://www.salonsecret.ru/media/setka_editor/post/strizhka-asimmetriya3.jpg',
            'https://steamuserimages-a.akamaihd.net/ugc/97231695407152082/808B9027F233F687BD7C488933DF855690853372/',
            'https://i.dailymail.co.uk/i/pix/2012/08/10/article-2186300-0B48F2B7000005DC-805_1024x615_large.jpg',

        ];
        const offset = 14;

        function getStyle(index: number) {
            return {
                marginLeft: index === 0 ? '0px' : `-${offset}px`,
                zIndex: imageURLs.length + index,
            };
        }

        const router = useRouter();


        const navigateToPage = (route: string) => {
            router.push(route);
        };

        return {
            imageURLs,
            getStyle,
            navigateToPage,
        }
    },
    components: {
        SpacerModule16,
    }
})
</script>

<style scoped>
.border {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #525A7D;
}

.image {
    width: 100%;
    object-fit: cover;
}

img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    max-height: 300px;
}

.txtleftcolumn {
    /* padding: 156px 56px; */
    height: 100%;
    padding-top: 15px;
}

.title {
    color: var(--fg-soft, rgba(82, 90, 125, 1));
    padding-right: 26px;
    font-size: 40px;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.text {

    color: var(--neutral-neutral1000, rgba(58, 64, 88, 1));

    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;



    padding-top: 30px;
    text-align: left;
}

.magics {
    background: white;
    border-radius: 20px;
}

.magic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 20px;
}

.photos {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    width: 50%;


    position: relative;
}

.overlay-image {
    border-radius: 100px;
    width: 64px;
    height: 64px;
    object-fit: cover;
    border: 2px solid white;
}

.overlay-image:first-child {
    margin-left: 0;
    /* No offset for the first image */
}

.text1 {
    padding-top: 18px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
}

.text2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}


.buttons {
    display: flex;
    padding-top: 25px;
    flex-direction: row;
    justify-content: space-around;
}

.button1 {
    display: flex;
    border-radius: 8px;
    padding: 14px 24px;
    background: var(--accent-accent600, #705CE2);
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.button2 {
    display: flex;
    border-radius: 8px;
    padding: 14px 24px;
    /* border: 1px solid #DAD9DC; */
    border: none;
    color: #2F3448;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral700, #525A7D);
}
</style>>