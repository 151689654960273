import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "description-block",
  ref: "blockRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "description-header" }, [
      _createElementVNode("span", null, "Обо мне")
    ], -1)),
    _createElementVNode("div", {
      class: "description-content",
      style: _normalizeStyle(_ctx.contentStyle),
      ref: "contentRef"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["description-text", { 'truncated': !_ctx.isExpanded }])
      }, _toDisplayString(_ctx.description), 3)
    ], 4),
    (_ctx.description.length > 150)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpand && _ctx.toggleExpand(...args))),
          class: "show-more-btn"
        }, _toDisplayString(_ctx.isExpanded ? 'Скрыть' : 'Показать всё'), 1))
      : _createCommentVNode("", true)
  ], 512))
}