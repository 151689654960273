<template>
    <div class="app">
        <!-- Header -->
        <header class="header">
            <div class="header-content">
                <div class="logo">ФОРМА</div>
                <div class="header-controls">
                    <div class="select-wrapper">
                        <select>
                            <option>Выбрать</option>
                        </select>
                    </div>
                    <div class="search-wrapper">
                        <input type="text" placeholder="Искать менторов">
                        <SearchIcon class="search-icon" />
                    </div>
                    <div class="notifications">
                        <div class="notification red">1</div>
                        <div class="notification orange">7</div>
                    </div>
                </div>
            </div>
        </header>

        <div class="main-container">
            <!-- Sidebar -->
            <aside class="sidebar">
                <nav>
                    <button class="sidebar-button active">
                        <PlusIcon />
                        <span>Создать</span>
                    </button>
                    <button class="sidebar-button">
                        <CalendarIcon />
                        <span>Календарь</span>
                    </button>
                    <button class="sidebar-button">
                        <MessageSquareIcon />
                        <span>Чаты</span>
                    </button>
                </nav>
            </aside>

            <!-- Main Content -->
            <main class="main-content">
                <h1 class="page-title">Настройки</h1>

                <!-- Tabs -->
                <div class="tabs">
                    <button v-for="tab in tabs" :key="tab.id" :class="['tab', { active: activeTab === tab.id }]"
                        @click="activeTab = tab.id">
                        {{ tab.name }}
                    </button>
                </div>

                <!-- Profile Form -->
                <div class="profile-form">
                    <div v-for="field in fields" :key="field.id" class="form-field">
                        <div>
                            <div class="field-label">{{ field.label }}</div>
                            <div class="field-value">{{ field.value }}</div>
                        </div>
                        <button class="edit-button" @click="editField(field.id)">
                            Редактировать
                        </button>
                    </div>

                    <!-- Profile Cover -->
                    <div class="profile-cover">
                        <div class="cover-header">
                            <div class="field-label">Обложка профиля</div>
                            <button class="edit-button">Редактировать</button>
                        </div>
                        <div class="cover-upload">
                            <button class="upload-button">
                                <UploadIcon />
                                <span>Загрузите обложку</span>
                            </button>
                        </div>
                        <div class="cover-info">
                            Добавьте фото или видео обложку. Фото 1920x1080 до 5 мб. Видео 1920x1080 до 15 мб.
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script setup>
  /* eslint-disable */

import { ref } from 'vue'
import {
    SearchIcon,
    ChevronDownIcon,
    PlusIcon,
    CalendarIcon,
    MessageSquareIcon,
    UploadIcon
} from 'lucide-vue-next'

const activeTab = ref('profile')

const tabs = [
    { id: 'profile', name: 'Профиль' },
    { id: 'education', name: 'Образование и опыт' },
    { id: 'privacy', name: 'Приватность' },
    { id: 'blacklist', name: 'Черный список' }
]

const fields = [
    { id: 'name', label: 'Имя', value: 'Сергей Мейерхольд' },
    { id: 'gender', label: 'Пол', value: 'Мужской' },
    { id: 'country', label: 'Страна пребывания', value: 'Россия' },
    { id: 'expertise', label: 'Экспертиза', value: 'Бариста, Общепит' },
    { id: 'profession', label: 'Профессия', value: 'Бариста' },
    { id: 'languages', label: 'Языки', value: 'Русский, English' }
]

const editField = (fieldId) => {
    console.log('Editing field:', fieldId)
}
</script>

<style scoped>

body {
    margin: 0;
    padding: 0;
    background-color: #F8F8F8;
}

.app {
    min-height: 100vh;
}

.header {
    background-color: white;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: #7F56D9;
    font-size: 24px;
    font-weight: 600;
}

.header-controls {
    display: flex;
    align-items: center;
    gap: 8px;
}

.select-wrapper {
    position: relative;
}

.select-wrapper select {
    appearance: none;
    background-color: #F2F4F7;
    color: #667085;
    padding: 8px 32px 8px 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
}

.select-wrapper::after {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #667085;
}

.search-wrapper {
    position: relative;
}

.search-wrapper input {
    background-color: #F2F4F7;
    border: none;
    border-radius: 4px;
    padding: 8px 12px 8px 36px;
    width: 230px;
    font-size: 14px;
}

.search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #667085;
    width: 16px;
    height: 16px;
}

.notifications {
    display: flex;
    gap: 8px;
    margin-left: 8px;
}

.notification {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}

.notification.red {
    background-color: #FEE4E2;
    color: #D92D20;
}

.notification.orange {
    background-color: #FEF0C7;
    color: #B54708;
}

.main-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}

.sidebar {
    width: 200px;
    padding-top: 24px;
    padding-right: 32px;
}

.sidebar-button {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 12px;
    border: none;
    background: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    cursor: pointer;
    transition: background-color 0.2s;
}

.sidebar-button:hover,
.sidebar-button.active {
    background-color: #F4EBFF;
}

.sidebar-button.active {
    color: #7F56D9;
}

.main-content {
    flex: 1;
    padding-top: 24px;
    padding-bottom: 64px;
}

.page-title {
    font-size: 28px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 24px;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #D0D5DD;
    margin-bottom: 32px;
}

.tab {
    padding: 12px 4px;
    margin-right: 24px;
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: #667085;
    cursor: pointer;
    position: relative;
}

.tab.active {
    color: #7F56D9;
}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #7F56D9;
}

.profile-form {
    max-width: 640px;
}

.form-field {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 24px;
    border-bottom: 1px solid #EAECF0;
    margin-bottom: 24px;
}

.field-label {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin-bottom: 4px;
}

.field-value {
    color: #101828;
}

.edit-button {
    background: none;
    border: none;
    color: #7F56D9;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.edit-button:hover {
    color: #6941C6;
}

.profile-cover {
    padding-top: 24px;
}

.cover-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.cover-upload {
    background-color: #F2F4F7;
    border-radius: 8px;
    aspect-ratio: 2 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background-color: white;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #475467;
    cursor: pointer;
    transition: background-color 0.2s;
}

.upload-button:hover {
    background-color: #F9FAFB;
}

.cover-info {
    font-size: 14px;
    color: #475467;
    margin-top: 8px;
}
</style>