<template>
    <div class="event-manager">
        <!-- Header -->
        <header class="header">
            <div class="header-content">
                <div class="header-left">
                    <h1 class="logo">ФОРМА</h1>
                    <nav class="main-nav">
                        <a href="#" class="nav-link">Мероприятия</a>
                        <a href="#" class="nav-link">
                            Сообщения
                            <span class="notification-badge">9+</span>
                        </a>
                        <a href="#" class="nav-link">Избранное</a>
                        <a href="#" class="nav-link">Настройки</a>
                    </nav>
                </div>
                <div class="header-right">
                    <div class="search-container">
                        <input type="text" placeholder="Найти" class="search-input" />
                        <button class="search-button">
                            <span class="search-icon"></span>
                        </button>
                    </div>
                </div>
            </div>
        </header>

        <!-- Main Content -->
        <main class="main-content">
            <h2 class="page-title">Управляйте своим мероприятиями</h2>

            <div class="content-grid">
                <!-- New Event Form -->
                <div class="content-section">
                    <h3 class="section-title">Новое мероприятие</h3>
                    <p class="section-description">
                        Вы всегда сможете изменить детали мероприятия
                    </p>

                    <div class="upload-cover">
                        <button class="upload-button">
                            <span class="upload-icon"></span>
                            <span>Загрузите обложку</span>
                        </button>
                    </div>

                    <form @submit.prevent="submitForm" class="event-form">
                        <div class="form-group">
                            <label for="title" class="form-label">Напишите название</label>
                            <input v-model="form.title" type="text" id="title" class="form-input"
                                placeholder="Сияй изнутри" />
                        </div>

                        <div class="form-group">
                            <label for="description" class="form-label">Добавьте описание</label>
                            <textarea v-model="form.description" id="description" rows="4" class="form-input"
                                placeholder="Сияй изнутри Сияй изнутри Сияй изнутри"></textarea>
                        </div>

                        <div class="form-row">
                            <div class="form-group">
                                <label for="date" class="form-label">Дата</label>
                                <input v-model="form.date" type="date" id="date" class="form-input" />
                            </div>
                            <div class="form-group">
                                <label for="time" class="form-label">Время</label>
                                <input v-model="form.time" type="time" id="time" class="form-input" />
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="price" class="form-label">Назначьте цену (опционально)</label>
                            <input v-model="form.price" type="number" id="price" class="form-input"
                                placeholder="500 руб" />
                        </div>

                        <div class="form-group">
                            <label for="discount" class="form-label">Добавьте скидку (опционально)</label>
                            <input v-model="form.discount" type="number" id="discount" class="form-input"
                                placeholder="50%" />
                        </div>

                        <div class="form-group">
                            <label for="maxParticipants" class="form-label">Максимальное число человек</label>
                            <input v-model="form.maxParticipants" type="number" id="maxParticipants" class="form-input"
                                placeholder="200" />
                        </div>

                        <div class="form-group">
                            <label for="minAge" class="form-label">Минимальный возраст</label>
                            <input v-model="form.minAge" type="number" id="minAge" class="form-input"
                                placeholder="18" />
                        </div>

                        <div class="form-group checkbox-group">
                            <input v-model="form.isPublic" type="checkbox" id="isPublic" class="form-checkbox" />
                            <label for="isPublic" class="checkbox-label">Публичное мероприятие</label>
                        </div>

                        <button type="button" class="btn btn-secondary" @click="saveTemplate">
                            Сохранить как шаблон
                        </button>

                        <div class="button-group">
                            <button type="button" class="btn btn-outline">
                                Выбрать шаблон
                            </button>
                            <button type="submit" class="btn btn-primary">
                                Опубликовать
                            </button>
                        </div>
                    </form>
                </div>

                <!-- Templates Section -->
                <div class="content-section">
                    <h3 class="section-title">Мои шаблоны</h3>
                    <p class="section-description">
                        Используйте сохранённые шаблоны чтобы создать аналогичное мероприятие
                    </p>

                    <div class="templates-list">
                        <div v-for="template in templates" :key="template.id" class="template-item">
                            <div class="template-content">
                                <span class="plus-icon"></span>
                                <div>
                                    <h4 class="template-title">{{ template.title }}</h4>
                                    <p class="template-details">{{ template.time }} · {{ template.duration }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Calendar Section -->
                <div class="content-section">
                    <div class="calendar-header">
                        <button class="calendar-nav-button">
                            <span class="chevron-left"></span>
                        </button>
                        <h3 class="calendar-title">27 Октября - 02 Ноября</h3>
                        <button class="calendar-nav-button">
                            <span class="chevron-right"></span>
                        </button>
                    </div>

                    <div class="calendar-grid">
                        <div v-for="day in ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']" :key="day" class="calendar-day-name">
                            {{ day }}
                        </div>
                    </div>

                    <div class="calendar-grid">
                        <div v-for="day in calendarDays" :key="day.date" 
                            class="calendar-day" 
                            :class="{ 'is-today': day.isToday }">
                            <span class="day-number">
                                {{ day.dayNumber }}
                            </span>
                            <span v-if="day.hasEvent" class="event-indicator"></span>
                        </div>
                    </div>

                    <div class="events-list">
                        <div v-for="event in todayEvents" :key="event.id" class="event-item">
                            <div class="event-date">
                                <span>{{ event.day }}</span>
                            </div>
                            <div class="event-details">
                                <h4 class="event-title">{{ event.title }}</h4>
                                <p class="event-time">{{ event.time }} · {{ event.duration }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { ref, defineComponent } from 'vue'

export default defineComponent({
    name: 'PersonalPage2',
    setup() {
        const form = ref({
            title: '',
            description: '',
            date: '',
            time: '',
            price: '',
            discount: '',
            maxParticipants: '',
            minAge: '',
            isPublic: false
        })

        const templates = ref([
            {
                id: 1,
                title: 'Сияй изнутри: питание..',
                time: '12:00',
                duration: '1 час'
            },
            {
                id: 2,
                title: 'Сияй изнутри: питание..',
                time: '12:00',
                duration: '1 час'
            }
        ])

        const calendarDays = ref([
            { date: '2024-10-27', dayNumber: '27', isToday: true, hasEvent: true },
            { date: '2024-10-28', dayNumber: '28', isToday: false, hasEvent: false },
            { date: '2024-10-29', dayNumber: '29', isToday: false, hasEvent: false },
            { date: '2024-10-30', dayNumber: '30', isToday: false, hasEvent: false },
            { date: '2024-10-31', dayNumber: '31', isToday: false, hasEvent: false },
            { date: '2024-11-01', dayNumber: '01', isToday: false, hasEvent: false },
            { date: '2024-11-02', dayNumber: '02', isToday: false, hasEvent: false }
        ])

        const todayEvents = ref([
            {
                id: 1,
                day: '27',
                title: 'Сияй изнутри: питание..',
                time: '12:00',
                duration: '1 час'
            },
            {
                id: 2,
                day: '27',
                title: 'Сияй изнутри: питание..',
                time: '12:00',
                duration: '1 час'
            }
        ])

        const submitForm = () => {
            console.log('Form submitted:', form.value)
        }

        const saveTemplate = () => {
            console.log('Template saved:', form.value)
        }

        return {
            form,
            templates,
            calendarDays,
            todayEvents,
            submitForm,
            saveTemplate
        }
    },
})
</script>

<style scoped>
/* Base styles */
body {
    line-height: 1.6;
    color: #333;
    background-color: #f5f5f5;
}

.event-manager {
    min-height: 100vh;
}

/* Header styles */
.header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: #8162E9;
    font-size: 1.5rem;
    font-weight: bold;
}

.main-nav {
    display: flex;
    gap: 2rem;
}

.nav-link {
    color: #333;
    text-decoration: none;
}

.nav-link:hover {
    color: #8162E9;
}

.notification-badge {
    background-color: #F58C88;
    color: white;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    margin-left: 0.25rem;
}

.search-container {
    position: relative;
}

.search-input {
    width: 16rem;
    padding: 0.5rem 1rem;
    padding-right: 2.5rem;
    border-radius: 9999px;
    border: 2px solid #B2A9FA;
    background-color: #fff;
}

.search-button {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

/* Main content styles */
.main-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.page-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #1A1C26;
}

.content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.content-section {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.section-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #1A1C26;
}

.section-description {
    font-size: 0.875rem;
    color: #80849A;
    margin-bottom: 1rem;
}

/* Form styles */
.upload-cover {
    background-color: #C8C2FB;
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.upload-button {
    background-color: #2F3448;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
}

.form-group {
    margin-bottom: 1rem;
}

.form-label {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    color: #3A4058;
}

.form-input {
    width: 100%;
    padding: 0.5rem;
    border: 2px solid #EBECEE;
    border-radius: 0.25rem;
    font-size: 1rem;
}

.form-row {
    display:  grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.checkbox-group {
    display: flex;
    align-items: center;
}

.form-checkbox {
    margin-right: 0.5rem;
}

.checkbox-label {
    font-size: 0.875rem;
    color: #434966;
}

.btn {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-secondary {
    background-color: #80849A;
    color: #fff;
    border: none;
}

.btn-secondary:hover {
    background-color: #6c7080;
}

.button-group {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.btn-outline {
    background-color: transparent;
    border: 1px solid #3A4058;
    color: #3A4058;
}

.btn-outline:hover {
    background-color: #f0f0f0;
}

.btn-primary {
    background-color: #705CE2;
    color: #fff;
    border: none;
}

.btn-primary:hover {
    background-color: #5a4ac7;
}

/* Templates styles */
.templates-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.template-item {
    background-color: #f0f0f0;
    border-radius: 0.5rem;
    padding: 1rem;
}

.template-content {
    display: flex;
    align-items: center;
}

.template-title {
    font-size: 1rem;
    font-weight: 500;
    color: #1A1C26;
}

.template-details {
    font-size: 0.875rem;
    color: #434966;
}

/* Calendar styles */
.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.calendar-title {
    font-size: 1rem;
    font-weight: normal;
    color: #1A1C26;
}

.calendar-nav-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.25rem;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.calendar-day-name {
    text-align: center;
    font-size: 0.75rem;
    color: #80849A;
}

.calendar-day {
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
}

.calendar-day.is-today {
    background-color: #DCD8FC;
}

.day-number {
    font-size: 0.875rem;
    font-weight: 600;
    color: #3A4058;
}

.event-indicator {
    width: 0.25rem;
    height: 0.25rem;
    background-color: #3A4058;
    border-radius: 50%;
    margin-top: 0.25rem;
}

.events-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.event-item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.event-date {
    width: 3rem;
    height: 3rem;
    background-color: #DCD8FC;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
    color: #3A4058;
}

.event-title {
    font-size: 1rem;
    font-weight: 500;
    color: #1A1C26;
}

.event-time {
    font-size: 0.875rem;
    color: #434966;
}

/* Icon styles */
.search-icon::before {
    content: '🔍';
    font-size: 14px;
}

.upload-icon::before {
    content: '📁';
    font-size: 24px;
    display: block;
}

.plus-icon::before {
    content: '+';
    font-size: 18px;
    color: #9CA3AF;
    margin-right: 0.75rem;
}

.chevron-left::before {
    content: '←';
    font-size: 18px;
}

.chevron-right::before {
    content: '→';
    font-size: 18px;
}
</style>