<template>
    <div class="profile-menu">
        <div class="profile-header">
            <img src="https://storage.yandexcloud.net/testbucketplatforma34/woman_blue_background.jpeg" alt="Profile photo" class="profile-image">
            <div class="profile-info">
                <h2 class="profile-name">{{ name }}</h2>
                <a href="#" class="profile-link">Показать профиль</a>
            </div>
        </div>

        <nav class="menu-list">
            <a v-for="(item, index) in navItems" :key="item.id"
                :class="['menu-item', { 'mentor-button': index === 0, active: activeNavItem === item.id }]"
                @click="handleNavItemClick(item.id)">
                <span class="icon" v-html="iconItems[item.id]"></span>
                <span class="text">{{ item.text }}</span>
                <span class="chevron" v-html="iconItems['chevron']"></span>
                <hr v-if="navItems.length == 2" />
            </a>
            
        </nav>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
    name: 'ProfileMenu',
    setup() {
        const activeNavItem = ref<string | undefined>(undefined)
        const name = "Сергей Мейрхольд";
        const router = useRouter()
        const navItems = [
            { id: 'stars', text: 'Стать наставником', route: '/' },
            { id: 'myMentee', text: 'Мои ученики', route: '/MyMentee' },
            { id: 'myMentors', text: 'Мои наставники', route: '/MyMentors' },
            { id: 'settings', text: 'Настройки', route: '/SettingsPage' },
            { id: 'support', text: 'Задать вопрос в поддержку', route: '/Support' },
            { id: 'logOut', text: 'Выйти из аккаунта', route: '/Logout' },
        ]

        const iconItems = {
            stars: `
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.44444 2V6.88889M2 4.44444H6.88889M5.66667 19.1111V24M3.22222 21.5556H8.11111M14.2222 2L17.0159 10.381L24 13L17.0159 15.619L14.2222 24L11.4286 15.619L4.44444 13L11.4286 10.381L14.2222 2Z" stroke="#705CE2" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            myMentee: `
                <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.1112 20.778H26.2223V18.3335C26.2223 16.3085 24.5807 14.6669 22.5556 14.6669C21.3876 14.6669 20.3472 15.2129 19.6758 16.0637M20.1112 20.778H7.88894M20.1112 20.778V18.3335C20.1112 17.5315 19.9566 16.7655 19.6758 16.0637M7.88894 20.778H1.77783V18.3335C1.77783 16.3085 3.41945 14.6669 5.4445 14.6669C6.61246 14.6669 7.65288 15.2129 8.32435 16.0637M7.88894 20.778V18.3335C7.88894 17.5315 8.04346 16.7655 8.32435 16.0637M8.32435 16.0637C9.22545 13.8126 11.4271 12.2224 14.0001 12.2224C16.5731 12.2224 18.7747 13.8126 19.6758 16.0637M17.6667 4.88908C17.6667 6.91412 16.0251 8.55574 14.0001 8.55574C11.975 8.55574 10.3334 6.91412 10.3334 4.88908C10.3334 2.86403 11.975 1.22241 14.0001 1.22241C16.0251 1.22241 17.6667 2.86403 17.6667 4.88908ZM25.0001 8.55574C25.0001 9.90577 23.9056 11.0002 22.5556 11.0002C21.2056 11.0002 20.1112 9.90577 20.1112 8.55574C20.1112 7.20572 21.2056 6.1113 22.5556 6.1113C23.9056 6.1113 25.0001 7.20572 25.0001 8.55574ZM7.88894 8.55574C7.88894 9.90577 6.79453 11.0002 5.4445 11.0002C4.09447 11.0002 3.00005 9.90577 3.00005 8.55574C3.00005 7.20572 4.09447 6.1113 5.4445 6.1113C6.79453 6.1113 7.88894 7.20572 7.88894 8.55574Z" stroke="#525A7D" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            myMentors: `
                <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 13.4444L24 7.33328L13 1.22217L2 7.33328L13 13.4444ZM13 13.4444L20.5278 9.26226C21.191 10.9347 21.5556 12.758 21.5556 14.6665C21.5556 15.5237 21.482 16.3637 21.3409 17.1805C18.1508 17.4907 15.2599 18.8229 13 20.8456C10.7401 18.8229 7.8492 17.4907 4.65909 17.1805C4.51798 16.3637 4.44444 15.5237 4.44444 14.6665C4.44444 12.758 4.80896 10.9346 5.47218 9.26225L13 13.4444ZM8.11111 20.7777V11.6111L13 8.89501" stroke="#525A7D" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            settings: `
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.9523 3.61004C11.4735 1.46332 14.5265 1.46332 15.0477 3.61005C15.3843 4.9968 16.9731 5.6549 18.1918 4.91237C20.0783 3.76292 22.2371 5.92175 21.0876 7.80823C20.3451 9.02687 21.0032 10.6157 22.39 10.9523C24.5367 11.4735 24.5367 14.5265 22.39 15.0477C21.0032 15.3843 20.3451 16.9731 21.0876 18.1918C22.2371 20.0783 20.0783 22.2371 18.1918 21.0876C16.9731 20.3451 15.3843 21.0032 15.0477 22.39C14.5265 24.5367 11.4735 24.5367 10.9523 22.39C10.6157 21.0032 9.02687 20.3451 7.80823 21.0876C5.92175 22.2371 3.76292 20.0783 4.91237 18.1918C5.6549 16.9731 4.9968 15.3843 3.61005 15.0477C1.46332 14.5265 1.46332 11.4735 3.61004 10.9523C4.9968 10.6157 5.6549 9.02687 4.91237 7.80823C3.76292 5.92175 5.92175 3.76292 7.80823 4.91237C9.02687 5.6549 10.6157 4.9968 10.9523 3.61004Z" stroke="#525A7D" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.6667 13C16.6667 15.025 15.025 16.6667 13 16.6667C10.975 16.6667 9.33333 15.025 9.33333 13C9.33333 10.975 10.975 9.33333 13 9.33333C15.025 9.33333 16.6667 10.975 16.6667 13Z" stroke="#525A7D" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            support: `
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.9773 13C22.9773 18.5103 18.5103 22.9773 13 22.9773V25.0227C19.64 25.0227 25.0227 19.64 25.0227 13H22.9773ZM13 22.9773C7.4897 22.9773 3.02273 18.5103 3.02273 13H0.977273C0.977273 19.64 6.36003 25.0227 13 25.0227V22.9773ZM3.02273 13C3.02273 7.4897 7.4897 3.02273 13 3.02273V0.977273C6.36003 0.977273 0.977273 6.36003 0.977273 13H3.02273ZM13 3.02273C18.5103 3.02273 22.9773 7.4897 22.9773 13H25.0227C25.0227 6.36003 19.64 0.977273 13 0.977273V3.02273ZM16.8662 13C16.8662 15.1352 15.1352 16.8662 13 16.8662V18.9116C16.2649 18.9116 18.9116 16.2649 18.9116 13H16.8662ZM13 16.8662C10.8648 16.8662 9.13384 15.1352 9.13384 13H7.08838C7.08838 16.2649 9.7351 18.9116 13 18.9116V16.8662ZM9.13384 13C9.13384 10.8648 10.8648 9.13384 13 9.13384V7.08838C9.7351 7.08838 7.08838 9.7351 7.08838 13H9.13384ZM13 9.13384C15.1352 9.13384 16.8662 10.8648 16.8662 13H18.9116C18.9116 9.7351 16.2649 7.08838 13 7.08838V9.13384ZM20.055 4.49865L15.7338 8.81986L17.1801 10.2662L21.5014 5.945L20.055 4.49865ZM15.7338 17.1801L20.055 21.5014L21.5014 20.055L17.1801 15.7338L15.7338 17.1801ZM10.2662 8.81986L5.945 4.49865L4.49865 5.945L8.81986 10.2662L10.2662 8.81986ZM8.81986 15.7338L4.49865 20.055L5.945 21.5014L10.2662 17.1801L8.81986 15.7338Z" fill="#525A7D"/>
                </svg>
            `,
            logOut: `
                <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.1111 15.8888L24 10.9999M24 10.9999L19.1111 6.11106M24 10.9999L6.88889 10.9999M14.2222 15.8888V17.1111C14.2222 19.1361 12.5806 20.7777 10.5556 20.7777H5.66667C3.64162 20.7777 2 19.1361 2 17.1111V4.88883C2 2.86379 3.64162 1.22217 5.66667 1.22217H10.5556C12.5806 1.22217 14.2222 2.86379 14.2222 4.88883V6.11106" stroke="#525A7D" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
            chevron: `
                <svg width="11" height="20" viewBox="0 0 17 8.5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3335 1.44431L9.88905 9.99986L1.3335 18.5554" stroke="#A2A5B3" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `,
        };

        const handleNavItemClick = (itemId: string) => {
            activeNavItem.value = itemId
            const selectedItem = navItems.find(item => item.id === itemId)
            if (selectedItem) {
                router.push(selectedItem.route)
            }
        }

        return {
            iconItems,
            navItems,
            name,
            handleNavItemClick
        }
    }
}
</script>

<style scoped>
.profile-menu {
    width: 360px;
    /* Установите фиксированную ширину или адаптивную */
    background: white;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 6px 6px 6px 6px rgba(0.1, 0.1, 0.1, 0.1);
    z-index: 9999;
}

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
}

.profile-info {
    display: flex;
    flex-direction: column;
}

.profile-name {
    font-size: 18px;
    color: #2a2037;
    margin: 0 0 4px 0;
    font-weight: 600;
}

.profile-link {
    color: #525A7D;
    text-decoration: none;
    font-size: 14px;
}

.menu-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 12px;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.2s;
    font-size: 20px;
    font-weight: 500;
    color: #434966;
}

.menu-item:hover {
    background-color: #f5f5f5;
}

.mentor-button {
    background-color: #dcd8fc;
    color: #705CE2;
}

.mentor-button:hover {
    background-color: #d0cbfb;
}

.icon {
    margin-right: 8px;
    font-size: 18px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    flex: 1;
}

.chevron {
    margin-left: 8px;
    color: #999;
    font-size: 18px;
}

.separator {
    height: 1px;
    background-color: #eee;
    margin: 8px 0;
}

/* Для поддержки мобильных устройств */
@media (max-width: 480px) {
    .profile-menu {
        padding: 12px;
        width: 250px;
    }

    .profile-image {
        width: 50px;
        height: 50px;
    }

    .profile-name {
        font-size: 16px;
    }

    .menu-item {
        padding: 10px;
    }
}
</style>
