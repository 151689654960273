<template>
    <div class="container">
        <section class="work-experience">
            <h2>Опыт работы</h2>

            <!-- Work Experience Cards -->
            <transition-group name="card-list" tag="div" class="card-list">
                <div v-for="job in sortedJobs" :key="'job-' + job.id" class="experience-card saved-card">
                    <div class="experience-info">
                        <div class="experience-years">
                            {{ formatDate(job.start_date) }} — {{ formatDate(job.end_date) }}
                        </div>
                        <div class="experience-details">
                            <div class="job-position">{{ job.position_title }}</div>
                            <div class="workplace-name">{{ job.company_name }}</div>
                        </div>
                    </div>
                    <div class="card-actions">
                        <button class="edit-button" @click="editJob(job)">✎</button>
                        <button class="close-button" @click="removeJob(job.id)">×</button>
                    </div>
                </div>
            </transition-group>

            <!-- Work Experience Form -->
            <transition name="form">
                <div v-if="showJobForm">
                    <div class="experience-card editing-card">
                        <div class="form-header">
                            <h3>Место работы</h3>
                            <button class="close-button" @click="cancelJobEdit">×</button>
                        </div>

                        <div class="form-group">
                            <label>Должность</label>
                            <input v-model="editingJob.position_title" type="text" class="input-field"
                                :class="{ 'error': editingJob.attempted_save && editingJob.errors.position_title }"
                                placeholder="Владелец" @input="validateJob" @blur="validateJob">
                            <span v-if="editingJob.attempted_save && editingJob.errors.position_title"
                                class="error-message">Обязательное поле</span>
                        </div>

                        <div class="form-group">
                            <label>Место работы</label>
                            <input v-model="editingJob.company_name" type="text" class="input-field"
                                :class="{ 'error': editingJob.attempted_save && editingJob.errors.company_name }"
                                placeholder="Гранд Кафе 12" @input="validateJob" @blur="validateJob">
                            <span v-if="editingJob.attempted_save && editingJob.errors.company_name"
                                class="error-message">Обязательное поле</span>
                        </div>

                        <div class="date-range">
                            <div class="date-group">
                                <label>Начало работы</label>
                                <div class="date-selects">
                                    <select v-model="editingJob.start_month" class="select-field"
                                        :class="{ 'error': editingJob.attempted_save && editingJob.errors.start_date }"
                                        @change="updateJobDate('start')">
                                        <option v-for="(month, index) in months" :key="month" :value="index">{{ month }}
                                        </option>
                                    </select>
                                    <select v-model="editingJob.start_year" class="select-field"
                                        :class="{ 'error': editingJob.attempted_save && editingJob.errors.start_date }"
                                        @change="updateJobDate('start')">
                                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                    </select>
                                </div>
                                <span v-if="editingJob.attempted_save && editingJob.errors.start_date"
                                    class="error-message">Обязательное поле</span>
                            </div>

                            <div class="date-group">
                                <label>Конец работы</label>
                                <div class="date-selects">
                                    <select v-model="editingJob.end_month" class="select-field"
                                        :class="{ 'error': editingJob.attempted_save && editingJob.errors.end_date, 'warning': editingJob.warnings.end_date }"
                                        @change="updateJobDate('end')">
                                        <option v-for="(month, index) in months" :key="month" :value="index">{{ month }}
                                        </option>
                                    </select>
                                    <select v-model="editingJob.end_year" class="select-field"
                                        :class="{ 'error': editingJob.attempted_save && editingJob.errors.end_date, 'warning': editingJob.warnings.end_date }"
                                        @change="updateJobDate('end')">
                                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                    </select>
                                </div>
                                <span v-if="editingJob.attempted_save && editingJob.errors.end_date"
                                    class="error-message">Обязательное поле</span>
                                <span v-if="editingJob.warnings.end_date" class="warning-message">Дата окончания не
                                    может быть раньше даты начала</span>
                            </div>
                        </div>

                        <button class="save-button" @click="saveJob">Сохранить</button>
                    </div>
                </div>
            </transition>

            <button class="add-button" @click="addJob">
                <span class="plus-icon">+</span> Добавить место работы
            </button>
        </section>

        <section class="education">
            <h2>Образование</h2>

            <!-- Education Cards -->
            <transition-group name="card-list" tag="div" class="card-list">
                <div v-for="edu in sortedEducation" :key="'edu-' + edu.id" class="education-card saved-card">
                    <div class="education-info">
                        <div class="education-years">{{ edu.start_year }} - {{ edu.end_year }}</div>
                        <div class="education-details">
                            <div class="institution-name">{{ edu.university_name }}</div>
                            <div class="education-specialty">{{ edu.specialty }}</div>
                        </div>
                    </div>
                    <div class="card-actions">
                        <button class="edit-button" @click="editEducation(edu)">✎</button>
                        <button class="close-button" @click="removeEducation(edu.id)">×</button>
                    </div>
                </div>
            </transition-group>

            <!-- Education Form -->
            <transition name="form">
                <div v-if="showEduForm">
                    <div class="education-card editing-card">
                        <div class="form-header">
                            <h3>Образование</h3>
                            <button class="close-button" @click="cancelEducationEdit">×</button>
                        </div>

                        <div class="form-group">
                            <label>Место обучения</label>
                            <input v-model="editingEducation.university_name" type="text" class="input-field"
                                :class="{ 'error': editingEducation.attempted_save && editingEducation.errors.university_name }"
                                placeholder="РАНХиГС" @input="validateEducation"
                                @blur="editingEducation.touched.university_name = true; validateEducation">
                            <span v-if="editingEducation.attempted_save && editingEducation.errors.university_name"
                                class="error-message">Обязательное поле</span>
                        </div>

                        <div class="form-group">
                            <label>Специальность</label>
                            <input v-model="editingEducation.specialty" type="text" class="input-field"
                                :class="{ 'error': editingEducation.attempted_save && editingEducation.errors.specialty }"
                                placeholder="Технология аналитического контроля химических соединений"
                                @input="validateEducation"
                                @blur="editingEducation.touched.specialty = true; validateEducation">
                            <span v-if="editingEducation.attempted_save && editingEducation.errors.specialty"
                                class="error-message">Обязательное поле</span>
                        </div>

                        <div class="date-range">
                            <div class="date-group">
                                <label>Год начала</label>
                                <select v-model="editingEducation.start_year" class="select-field"
                                    :class="{ 'error': editingEducation.attempted_save && editingEducation.errors.start_year }"
                                    @change="validateEducation">
                                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                </select>
                                <span v-if="editingEducation.attempted_save && editingEducation.errors.start_year"
                                    class="error-message">Обязательное поле</span>
                            </div>

                            <div class="date-group">
                                <label>Год окончания</label>
                                <select v-model="editingEducation.end_year" class="select-field"
                                    :class="{ 'error': editingEducation.attempted_save && editingEducation.errors.end_year, 'warning': editingEducation.warnings.end_year }"
                                    @change="validateEducation">
                                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                </select>
                                <span v-if="editingEducation.attempted_save && editingEducation.errors.end_year"
                                    class="error-message">Обязательное поле</span>
                                <span v-if="editingEducation.warnings.end_year" class="warning-message">Год окончания не
                                    может быть раньше года начала</span>
                            </div>
                        </div>

                        <button class="save-button" @click="saveEducation">Сохранить</button>
                    </div>
                </div>
            </transition>

            <button class="add-button" @click="addEducation">
                <span class="plus-icon">+</span> Добавить образование
            </button>
        </section>

        <button v-if="hasUnsavedChanges" @click="saveChanges" class="save-changes-button">Сохранить изменения</button>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'

const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
const years = Array.from({ length: 50 }, (_, i) => 2024 - i)

const jobs = ref([])
const education = ref([])
const showJobForm = ref(false)
const showEduForm = ref(false)
const hasUnsavedChanges = ref(false)

const editingJob = ref(createEmptyJob())
const editingEducation = ref(createEmptyEducation())

const jobsToUpdate = ref([])
const jobsToAdd = ref([])
const jobsToDelete = ref([])
const educationToUpdate = ref([])
const educationToAdd = ref([])
const educationToDelete = ref([])

const sortedJobs = computed(() => [...jobs.value].sort((a, b) => new Date(b.end_date) - new Date(a.end_date)))
const sortedEducation = computed(() => [...education.value].sort((a, b) => b.end_year - a.end_year))

function createEmptyJob() {
    return {
        id: null,
        position_title: '',
        company_name: '',
        start_date: null,
        end_date: null,
        start_month: '',
        start_year: '',
        end_month: '',
        end_year: '',
        user_id: 167,
        errors: {},
        warnings: {},
        touched: {},
        attempted_save: false
    }
}

function createEmptyEducation() {
    return {
        id: null,
        start_year: '',
        end_year: '',
        university_name: '',
        specialty: '',
        user_id: 167,
        errors: {},
        warnings: {},
        touched: {},
        attempted_save: false
    }
}

onMounted(async () => {
    await fetchWorkExperience()
    await fetchEducation()
})


async function fetchWorkExperience() {
    try {
        const response = await axios.get('https://platforma34.tw1.ru/api/users/GetWorkExperience/?user_id=167')
        jobs.value = response.data.map(job => ({
            ...job,
            start_month: new Date(job.start_date).getMonth(),
            start_year: new Date(job.start_date).getFullYear(),
            end_month: new Date(job.end_date).getMonth(),
            end_year: new Date(job.end_date).getFullYear(),
        }))
    } catch (error) {
        console.error('Error fetching work experience:', error)
    }
}

async function fetchEducation() {
    try {
        const response = await axios.get('https://platforma34.tw1.ru/api/users/GetEducation/?user_id=167')
        education.value = response.data.map(edu => ({
            ...edu,
            start_year: edu.start_year,
            end_year: edu.end_year,
        }))
    } catch (error) {
        console.error('Error fetching education:', error)
    }
}

function addJob() {
    showJobForm.value = true
    editingJob.value = createEmptyJob()
}

function editJob(job) {
    showJobForm.value = true
    editingJob.value = { ...job, errors: {}, warnings: {}, touched: {}, attempted_save: false }
}

function cancelJobEdit() {
    showJobForm.value = false
    editingJob.value = createEmptyJob()
}

function validateJob() {
    const job = editingJob.value
    job.errors = {}
    job.warnings = {}
    if (!job.position_title) job.errors.position_title = true
    if (!job.company_name) job.errors.company_name = true
    if (!job.start_date) job.errors.start_date = true
    if (!job.end_date) job.errors.end_date = true

    if (job.start_date && job.end_date && new Date(job.end_date) < new Date(job.start_date)) {
        job.warnings.end_date = true
    }
    return Object.keys(job.errors).length === 0 && Object.keys(job.warnings).length === 0
}

function updateJobDate(type) {
    const job = editingJob.value
    const year = job[`${type}_year`]
    const month = job[`${type}_month`]
    if (year && month !== '') {
        job[`${type === 'start' ? 'start_date' : 'end_date'}`] = new Date(year, month).toISOString().split('T')[0]
    } else {
        job[`${type === 'start' ? 'start_date' : 'end_date'}`] = null
    }
    validateJob()
}

async function saveJob() {
    const job = editingJob.value
    if (validateJob()) {
        if (job.id) {
            const index = jobs.value.findIndex(j => j.id === job.id)
            if (index !== -1) {
                jobs.value[index] = { ...job, errors: {}, warnings: {} }
                jobsToUpdate.value.push(job)
            } else {
                jobs.value.push({ ...job, errors: {}, warnings: {} })
                jobsToAdd.value.push(job)
            }
        } else {
            const newJob = { ...job, id: Date.now(), errors: {}, warnings: {} }
            jobs.value.push(newJob)
            jobsToAdd.value.push(newJob)
        }
        showJobForm.value = false
        hasUnsavedChanges.value = true
    }
    job.attempted_save = true
}

function removeJob(id) {
    const index = jobs.value.findIndex(job => job.id === id)
    if (index !== -1) {
        const removedJob = jobs.value.splice(index, 1)[0]
        if (removedJob.id) {
            jobsToDelete.value.push(removedJob)
            // Remove from other lists if present
            jobsToAdd.value = jobsToAdd.value.filter(job => job.id !== id)
            jobsToUpdate.value = jobsToUpdate.value.filter(job => job.id !== id)
        }
        hasUnsavedChanges.value = true
    }
}

function addEducation() {
    showEduForm.value = true
    editingEducation.value = createEmptyEducation()
}

function editEducation(edu) {
    showEduForm.value = true
    editingEducation.value = { ...edu, errors: {}, warnings: {}, touched: {}, attempted_save: false }
}

function cancelEducationEdit() {
    showEduForm.value = false
    editingEducation.value = createEmptyEducation()
}

function validateEducation() {
    const edu = editingEducation.value
    edu.errors = {}
    edu.warnings = {}
    if (!edu.university_name) edu.errors.university_name = true
    if (!edu.specialty) edu.errors.specialty = true
    if (!edu.start_year) edu.errors.start_year = true
    if (!edu.end_year) edu.errors.end_year = true

    if (edu.start_year && edu.end_year && parseInt(edu.end_year) < parseInt(edu.start_year)) {
        edu.warnings.end_year = true
    }
    return Object.keys(edu.errors).length === 0 && Object.keys(edu.warnings).length === 0
}

async function saveEducation() {
    const edu = editingEducation.value
    if (validateEducation()) {
        if (edu.id) {
            const index = education.value.findIndex(e => e.id === edu.id)
            if (index !== -1) {
                education.value[index] = { ...edu, errors: {}, warnings: {} }
                educationToUpdate.value.push(edu)
            } else {
                education.value.push({ ...edu, errors: {}, warnings: {} })
                educationToAdd.value.push(edu)
            }
        } else {
            const newEdu = { ...edu, id: Date.now(), errors: {}, warnings: {} }
            education.value.push(newEdu)
            educationToAdd.value.push(newEdu)
        }
        showEduForm.value = false
        hasUnsavedChanges.value = true
    }
    edu.attempted_save = true
}

function removeEducation(id) {
    const index = education.value.findIndex(edu => edu.id === id)
    if (index !== -1) {
        const removedEdu = education.value.splice(index, 1)[0]
        if (removedEdu.id) {
            educationToDelete.value.push(removedEdu)
            // Remove from other lists if present
            educationToAdd.value = educationToAdd.value.filter(edu => edu.id !== id)
            educationToUpdate.value = educationToUpdate.value.filter(edu => edu.id !== id)
        }
        hasUnsavedChanges.value = true
    }
}

function formatDate(date) {
    if (!date) return ''
    const d = new Date(date)
    const month = months[d.getMonth()]
    const year = d.getFullYear()
    return `${month} ${year}`
}

async function saveChanges() {
    try {
        // Update and add work experiences
        // for (const job of [...jobsToUpdate.value, ...jobsToAdd.value]) {
        for (const job of [...jobsToUpdate.value]) {
            const jobData = {
                id: job.id,
                start_date: job.start_date,
                end_date: job.end_date,
                company_name: job.company_name,
                position_title: job.position_title,
                user_id: job.user_id
            }
            await axios.put('https://platforma34.tw1.ru/api/users/UpdateWorkExperience/', jobData)
        }
        for (const job of [...jobsToAdd.value]) {
            const jobData = {
                start_date: job.start_date,
                end_date: job.end_date,
                company_name: job.company_name,
                position_title: job.position_title,
                user_id: 167,
            }
            await axios.post('https://platforma34.tw1.ru/api/users/AddWorkExperience/', jobData)
        }

        // Delete work experiences
        for (const job of jobsToDelete.value) {
            await axios.delete(`https://platforma34.tw1.ru/api/users/DeleteWorkExperience/?id=${job.id}`)
        }

        // Update and add education
        for (const edu of [...educationToUpdate.value]) {
            const eduData = {
                id: edu.id,
                specialty: edu.specialty,
                start_year: edu.start_year,
                end_year: edu.end_year,
                university_name: edu.university_name,
                user_id: edu.user_id
            }
            await axios.put('https://platforma34.tw1.ru/api/users/UpdateEducation/', eduData)
        }

        for (const edu of [...educationToAdd.value]) {
            const eduData = {
                specialty: edu.specialty,
                start_year: edu.start_year,
                end_year: edu.end_year,
                university_name: edu.university_name,
                user_id: 167
            }
            await axios.post('https://platforma34.tw1.ru/api/users/AddEducation/', eduData)
        }

        // Delete education
        for (const edu of educationToDelete.value) {
            await axios.delete(`https://platforma34.tw1.ru/api/users/DeleteEducation/?id=${edu.id}`)
        }

        // Reset change tracking
        jobsToUpdate.value = []
        jobsToAdd.value = []
        jobsToDelete.value = []
        educationToUpdate.value = []
        educationToAdd.value = []
        educationToDelete.value = []
        hasUnsavedChanges.value = false

        alert('Изменения успешно сохранены')
    } catch (error) {
        console.error('Error saving changes:', error)
        alert('Произошла ошибка при сохранении изменений: ' + error.message)
    }
}
</script>

<style scoped>
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.work-experience,
.education {
    margin-bottom: 40px;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.card-list {
    position: relative;
}

.experience-card,
.education-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
}

.saved-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.experience-info,
.education-info {
    flex: 1;
}

.experience-years,
.education-years {
    color: #666;
    margin-bottom: 5px;
}

.job-position,
.institution-name {
    font-weight: 500;
    margin-bottom: 2px;
}

.workplace-name,
.education-specialty {
    color: #666;
    font-size: 14px;
}

.form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.input-field,
.select-field {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.input-field.error,
.select-field.error {
    border-color: #ff4d4f;
}

.select-field.warning {
    border-color: #faad14;
}

.error-message {
    color: #ff4d4f;
    font-size: 12px;
    margin-top: 4px;
    display: block;
}

.warning-message {
    color: #faad14;
    font-size: 12px;
    margin-top: 4px;
    display: block;
}

.date-range {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.date-group {
    flex: 1;
}

.date-selects {
    display: flex;
    gap: 10px;
}

.save-button {
    background: #6c5ce7;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.add-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #6c5ce7;
    cursor: pointer;
    font-size: 14px;
}

.plus-icon {
    margin-right: 8px;
}

.card-actions {
    display: flex;
    gap: 10px;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    color: #666;
    cursor: pointer;
}

.edit-button {
    background: none;
    border: none;
    color: #6c5ce7;
    cursor: pointer;
}

button:hover {
    opacity: 0.8;
}

.form-enter-active,
.form-leave-active {
    transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    max-height: 1000px;
    overflow: hidden;
}

.form-enter-from,
.form-leave-to {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
}

.card-list-move {
    transition: transform 0.5s ease;
}

.card-list-enter-active,
.card-list-leave-active {
    transition: opacity 0.5s ease;
    max-height: none;
    overflow: visible;
}

.card-list-enter-from,
.card-list-leave-to {
    opacity: 0;
}

.card-list-leave-active {
    position: absolute;
    width: 100%;
}

.save-changes-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #6c5ce7;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.save-changes-button:hover {
    background-color: #5b4bc7;
}

.error {
    border-color: red;
}

.warning {
    border-color: orange;
}
</style>
