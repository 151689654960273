<template>
    <div class="new-bordering">
        <div class="col-left">
            <div class="txtleftcolumn">
                <div class="title">
                    Станьте наставником для увлеченных людей со всего мира
                </div>
                <div class="text">
                    Делитесь своими знаниями и зарабатывайте на этом.
                    Проводите индивидуальные занятия или открытые мастер-классы,
                    а мы найдем вам первых учеников и возьмем всю организационную работу на себя
                </div>
                <Spacer16 />
                <Spacer16 />
                <div class="buttons">
                    <div @click="navigateToPage('/register')" class="button1">
                        Стать наставником
                    </div>
                    <div class="button2">
                        Подробнее
                    </div>
                </div>
            </div>
        </div>
        <div class="col-right">
            <img src='../../assets/images/landing/tormen.svg' alt='Top Left Image' class="tormen-img">
        </div>
        <div class="magics">
            <div class="magic">
                <div class="photos">
                    <img v-for="(src, index) in this.imageURLs" :key="index" :src="src" :alt="'Image ' + (index + 1)"
                        class="overlay-image" :style="getStyle(index)">
                </div>
                <div>
                    <div class="text1">
                        2000+ наставников уже с нами
                    </div>
                    <Spacer8 />
                    <div class="text2">
                        Специалисты в разных сферах и с разным опытом
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spacer16 from '../Spacers/SpacerModule16.vue';
import Spacer8 from '../Spacers/SpacerModule8.vue';

export default {
    name: 'HomeQuestionerMain',
    components: {
        Spacer16,
        Spacer8,
    },
    data() {
        return {
            imageURLs: [
                'https://avatars.mds.yandex.net/get-ydo/1529510/2a0000017f372787863ed19a50646fe713cc/diploma',
                'https://www.salonsecret.ru/media/setka_editor/post/strizhka-asimmetriya3.jpg',
                'https://steamuserimages-a.akamaihd.net/ugc/97231695407152082/808B9027F233F687BD7C488933DF855690853372/',
                'https://i.dailymail.co.uk/i/pix/2012/08/10/article-2186300-0B48F2B7000005DC-805_1024x615_large.jpg',

            ],
            offset: 14,
        };
    },
    methods: {
        getStyle(index) {
            return {
                marginLeft: index === 0 ? '0px' : `-${this.offset}px`,
                zIndex: this.imageURLs.length + index,
            };
        },
        navigateToPage(route) {
            this.$router.push(route);
        }
    }
}

</script>

<style scoped>
html,
body {
    margin: 0;
}

.new-bordering {
    display: grid;
    /* grid-template-columns: 2fr 1fr; */
    grid-template-areas:
        "A A B"
        "A A B"
        "A A C";
    /* Две колонки по ширине */
    /* grid-template-rows: 1fr 1fr; */
    /* Две строки одинаковой высоты */
    /* height: 50em; */
    /* Высота 95% */
    gap: 30px;
    color: #525A7D;
}

.col {
    border-radius: 32px;
    background-color: #F3F4F5;
    display: grid;
    /* Используем Grid для центрирования текста */
}

.col-left {
    grid-area: A;
}

/*
.col-left {
    grid-column: 1;
    grid-row: span 2;
}
.col-right:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
}

.col-right:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
    background: white;
    border-radius: 20px;
}
*/

.magics {
    background: white;
    border-radius: 20px;
    grid-area: C;
}

.col-right {
    height: 100%;
    grid-area: B;
    /*
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    */
}
.txtleftcolumn {
    padding-right: 55px;
    padding-left: 60px;
    /* padding: 156px 56px; */
    background: white;
    border-radius: 20px;
    height: 100%;
}

.title {
    padding-top: 80px;
    font-size: 70px;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--fg-soft, rgba(82, 90, 125, 1));

    /*
        font-size: 80px;
        font-weight: 550;
        line-height: 84px;
        letter-spacing: -0.02em;
        text-align: left;
    */
}

.text {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral1000, rgba(58, 64, 88, 1));


    padding-top: 30px;
    /*
    max-width: 556px;
    font-size: 20px;
    font-weight: 350;
    line-height: 24px;
    */
    padding-right: 179px;
    text-align: left;
}

.buttons {
    display: flex;
    padding-top: 20px;
    flex-direction: row;
    gap: 12px;
}

.button1 {
    display: flex;
    border-radius: 8px;
    padding: 14px 24px;
    background-color: #7B65F7;
    color: white;
}

.button2 {
    display: flex;
    border-radius: 8px;
    padding: 14px 24px;
    background-color: #fff;
    /* border: 1px solid #DAD9DC; */
    border: none;
    color: #2F3448
}

.button1:hover {
    cursor: pointer;
    background-color: #5343a9;
}

.button2:hover {
    cursor: pointer;
    /* background-color: #DAD9DC */
}

.overlay-image {
    border-radius: 100px;
    width: 64px;
    height: 64px;
    object-fit: cover;
    border: 2px solid white;
}

.overlay-image:first-child {
    margin-left: 0;
    /* No offset for the first image */
}

.photos {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    width: 50%;


    position: relative;
}

.magic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 20px;
}

.text1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
}

.text2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}

.tormen-img {
    width: 100%;
    max-height: 45vh;
    border-radius: 20px;
    object-fit: cover;
}
</style>
