import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "experience-block",
  ref: "blockRef"
}
const _hoisted_2 = { class: "experience-header" }
const _hoisted_3 = { class: "experience-header-text" }
const _hoisted_4 = { class: "items-count" }
const _hoisted_5 = {
  class: "experience-content",
  ref: "contentRef"
}
const _hoisted_6 = { class: "date" }
const _hoisted_7 = { class: "position" }
const _hoisted_8 = { class: "company" }
const _hoisted_9 = { class: "date" }
const _hoisted_10 = { class: "position" }
const _hoisted_11 = { class: "company" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.items.length), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleItems, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "experience-item",
          ref_for: true,
          ref: "itemRefs"
        }, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(item.dateRange), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(item.position), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(item.company), 1)
        ]))
      }), 128))
    ], 512),
    (_ctx.items.length > _ctx.initialItemsCount)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args))),
          class: "show-more-btn"
        }, " Показать всё "))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          (_ctx.isModalOpen)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "modal-overlay",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, [
                _createElementVNode("div", {
                  class: "modal-content platforma-font",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                    class: "close-btn"
                  }, "×"),
                  _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "experience-item"
                    }, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(item.dateRange), 1),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(item.position), 1),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(item.company), 1)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ], 512))
}