<template>
    <div>

        <transition name="fade" @before-leave="beforeLeave" @leave="leave">
            <div v-if="loading" class="loading">
                <JumpingDotLoaderWithShadows />
            </div>
        </transition>

        <div v-if="!loading">
            <div class="event-page">
                <div class="content">
                    <main class="main">
                        <div class="main-content">
                            <div class="tags">
                                <span class="tag" style="background: var(--orange-orange200, #FDE2D3);">18+</span>
                                <span class="tag">Еда</span>
                                <span class="tag">Здоровье</span>
                                <span class="tag">ЗОЖ</span>
                            </div>
                            <h1 class="title">{{ currentEvent.event_title }}</h1>
                            <p class="date">17 окт, 12:00 (GMT +05:00)</p>
                            <img :src="currentEvent.event_imageUrl" alt="Event image" class="event-image" />
                            <div class="mobile-only">
                                <div
                                    style="font-size: 18px; font-weight: 500; line-height: 24px; letter-spacing: 0.01em; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: var(--neutral-neutral700, #525A7D); margin-bottom: 10px;">
                                    Отправь ссылку на мероприятие другу</div>
                                <div class="mobile-only">
                                    <div class="share-link-box">
                                        <div class="link-text">
                                            {{ "https://platforma34.tw1.ru/event_info/" + currentEvent.id }}</div>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.66683 6.014V12.6807C6.66683 13.6011 7.41302 14.3473 8.3335 14.3473H13.3335M6.66683 6.014V4.34733C6.66683 3.42686 7.41302 2.68066 8.3335 2.68066H12.155C12.376 2.68066 12.588 2.76846 12.7442 2.92474L16.4228 6.60325C16.579 6.75953 16.6668 6.9715 16.6668 7.19251V12.6807C16.6668 13.6011 15.9206 14.3473 15.0002 14.3473H13.3335M6.66683 6.014H5.3335C4.22893 6.014 3.3335 6.90943 3.3335 8.014V16.014C3.3335 16.9345 4.07969 17.6807 5.00016 17.6807H11.3335C12.4381 17.6807 13.3335 16.7852 13.3335 15.6807V14.3473"
                                                stroke="#A2A5B3" stroke-width="2.04" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    style="display: flex; flex-direction: row; gap: 1rem; align-items: center; margin-bottom: 1rem;">
                                    <svg width="161" height="57" viewBox="0 0 161 57" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" data-v-55449466="">
                                        <g clip-path="url(#clip0_56165_4056)" data-v-55449466="">
                                            <rect y="0.674805" width="56" height="56" rx="28" fill="white"
                                                data-v-55449466=""></rect>
                                            <rect y="0.674805" width="56" height="56" data-v-55449466=""></rect>
                                        </g>
                                        <rect x="0.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3"
                                            data-v-55449466=""></rect>
                                        <g clip-path="url(#clip1_56165_4056)" data-v-55449466="">
                                            <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                data-v-55449466=""></rect>
                                            <rect x="35" y="0.674805" width="56" height="56" data-v-55449466=""></rect>
                                        </g>
                                        <rect x="35.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3"
                                            data-v-55449466=""></rect>
                                        <g clip-path="url(#clip2_56165_4056)" data-v-55449466="">
                                            <rect x="70" y="0.674805" width="56" height="56" rx="28" fill="#F3F4F5"
                                                data-v-55449466=""></rect>
                                        </g>
                                        <rect x="70.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3"
                                            data-v-55449466=""></rect>
                                        <g clip-path="url(#clip3_56165_4056)" data-v-55449466="">
                                            <rect x="105" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                data-v-55449466=""></rect>
                                            <rect x="105" y="0.674805" width="56" height="56" data-v-55449466=""></rect>
                                        </g>
                                        <rect x="105.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3"
                                            data-v-55449466=""></rect>
                                        <defs data-v-55449466="">
                                            <clipPath id="clip0_56165_4056" data-v-55449466="">
                                                <rect y="0.674805" width="56" height="56" rx="28" fill="white"
                                                    data-v-55449466=""></rect>
                                            </clipPath>
                                            <clipPath id="clip1_56165_4056" data-v-55449466="">
                                                <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                    data-v-55449466=""></rect>
                                            </clipPath>
                                            <clipPath id="clip2_56165_4056" data-v-55449466="">
                                                <rect x="70" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                    data-v-55449466=""></rect>
                                            </clipPath>
                                            <clipPath id="clip3_56165_4056" data-v-55449466="">
                                                <rect x="105" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                    data-v-55449466=""></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p class="participants mobile-only">133/200 уже участвуют</p>
                                </div>
                            </div>
                            <div
                                style="padding: 1rem; background-color: white; border-radius: 20px; margin-bottom: 1rem;">
                                <h2 class="section-title">Тема</h2>
                                <ol class="topic-list">
                                    <li>Что такое критические путешествия пользователей и почему вы должны их
                                        использовать? </li>
                                    <li>Схема поиска и расстановки приоритетов CUJ</li>
                                    <li>Как превратить CUJ в дорожную карту продукта</li>
                                    <li>Мерный СТАКАН счастья для постоянного совершенствования</li>
                                </ol>
                                <h2 class="section-title">Чему научитесь</h2>
                                <p class="description">
                                    <!-- {{ currentEvent.event_ }} -->
                                    Google был одним из первых сторонников критических пользовательских путешествий
                                    (CUJ) и того, как они помогают продуктовым командам в большей степени
                                    ориентироваться на пользователей. Когда Google рассказывает о CUJ's, это кажется
                                    таким понятным и очевидным - все равно что наблюдать за плавными ударами теннисиста
                                    на Открытом чемпионате США и думать: "Ну, я мог бы так ударить по мячу - по крайней
                                    мере, один раз". Однако дьявол кроется в деталях.
                                </p>
                            </div>
                            <div class="cancellation-policy">
                                <h3 class="policy-title">Правила отмены</h3>
                                <p class="policy-text">
                                    Полный возврат доступен при отмене не позднее чем за 7 дней до начала мастер-класса
                                    или в течение 24 часов с момента бронирования
                                </p>
                            </div>
                            <LinkToMentor :mentorId="mentor.id">
                                <div class="mobile-only">
                                    <div class="trainer-info">
                                        <div style="display: flex; gap: 10px; align-items: center">
                                            <div class="trainer-avatar">
                                                <img :src="mentor.mentor_avatar_url" class="mentor_avatar">
                                            </div>
                                            <div>
                                                <h3 class="trainer-name">{{ mentor.mentor_name + " " +
                                                    mentor.mentor_surname
                                                    }}
                                                </h3>
                                                <p @click="openLink(mentor.mentor_title)" class="trainer-title">{{
                                                    mentor.mentor_tg_title }}</p>


                                            </div>
                                        </div>
                                        <div
                                            style="padding: 9px; background: var(--neutral-neutral200, #EBECEE); border-radius: 10px;">
                                            <svg width="38" height="38" viewBox="0 0 38 38" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.1111 8.11111C8.1111 6.39289 9.50399 5 11.2222 5H26.7778C28.496 5 29.8889 6.39289 29.8889 8.11111V33L19 27.5556L8.1111 33V8.11111Z"
                                                    stroke="#434966" stroke-width="1.92857" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </LinkToMentor>
                            <p class="trainer-description mobile-only">
                                {{ mentor.mentor_description }}
                            </p>
                            <!-- <div class="coffee-image-placeholder mobile-only"></div> -->
                        </div>
                        <div class="sidebar-right desktop-only">
                            <div class="trainer-card">
                                <LinkToMentor :mentorId="mentor.id">
                                    <div class="trainer-info">
                                        <div class="trainer-avatar">
                                            <img :src="mentor.mentor_avatar_url" class="mentor_avatar">

                                        </div>
                                        <div>
                                            <h3 class="trainer-name">{{ mentor.mentor_name + " " + mentor.mentor_surname
                                                }}
                                            </h3>
                                            <p @click="openLink(mentor.mentor_title)" class="trainer-title">{{
                                                mentor.mentor_tg_title }}</p>

                                        </div>
                                    </div>
                                </LinkToMentor>
                                <p class="trainer-description">
                                    {{ mentor.mentor_description }}
                                </p>
                                <div class="coffee-image-placeholder">
                                    <div style="display: flex; flex-direction: row; justify-content: space-between">

                                        <p class="event-price">Бесплатно</p>
                                        <p class="available-seats">Свободно 30 мест</p>
                                    </div>
                                    <div v-if="canParticipate" class="participate-button"
                                        @click="registerMemberToEvent">
                                        Участвовать
                                    </div>
                                    <div v-else class="participate-button-registered">Вы зарегистрированы</div>
                                </div>

                                <div class="share-link-title">
                                    Отправить ссылку на мероприятия другу</div>
                                <div class="share-link-box">

                                    <div class="link-text">
                                        {{ "https://platforma34.tw1.ru/event_info/" + currentEvent.id }}</div>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.66683 6.014V12.6807C6.66683 13.6011 7.41302 14.3473 8.3335 14.3473H13.3335M6.66683 6.014V4.34733C6.66683 3.42686 7.41302 2.68066 8.3335 2.68066H12.155C12.376 2.68066 12.588 2.76846 12.7442 2.92474L16.4228 6.60325C16.579 6.75953 16.6668 6.9715 16.6668 7.19251V12.6807C16.6668 13.6011 15.9206 14.3473 15.0002 14.3473H13.3335M6.66683 6.014H5.3335C4.22893 6.014 3.3335 6.90943 3.3335 8.014V16.014C3.3335 16.9345 4.07969 17.6807 5.00016 17.6807H11.3335C12.4381 17.6807 13.3335 16.7852 13.3335 15.6807V14.3473"
                                            stroke="#A2A5B3" stroke-width="2.04" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div style="display: flex; flex-direction: row; gap: 10px; align-items: center;">
                                    <svg width="161" height="57" viewBox="0 0 161 57" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_56165_4056)">
                                            <rect y="0.674805" width="56" height="56" rx="28" fill="white" />
                                            <rect y="0.674805" width="56" height="56" />
                                        </g>
                                        <rect x="0.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3" />
                                        <g clip-path="url(#clip1_56165_4056)">
                                            <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                            <rect x="35" y="0.674805" width="56" height="56" />
                                        </g>
                                        <rect x="35.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3" />
                                        <g clip-path="url(#clip2_56165_4056)">
                                            <rect x="70" y="0.674805" width="56" height="56" rx="28" fill="#F3F4F5" />
                                        </g>
                                        <rect x="70.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3" />
                                        <g clip-path="url(#clip3_56165_4056)">
                                            <rect x="105" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                            <rect x="105" y="0.674805" width="56" height="56" />
                                        </g>
                                        <rect x="105.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3" />
                                        <defs>
                                            <clipPath id="clip0_56165_4056">
                                                <rect y="0.674805" width="56" height="56" rx="28" fill="white" />
                                            </clipPath>
                                            <clipPath id="clip1_56165_4056">
                                                <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                            </clipPath>
                                            <clipPath id="clip2_56165_4056">
                                                <rect x="70" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                            </clipPath>
                                            <clipPath id="clip3_56165_4056">
                                                <rect x="105" y="0.674805" width="56" height="56" rx="28"
                                                    fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <p class="participants">133/200 уже участвуют</p>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
            </div>
            <div v-if="reviews.length > 0" class="reviews">
                <ReviewBlock instance="event" :id="currentEvent.id" :reviews="reviews">
                </ReviewBlock>
            </div>
            <div>
                <div v-if="events.length > 0" class="events-section-title">Мероприятия</div>
                <div class="masonry-container">
                    <div class="masonry-column" v-for="n in colNum" :key="n">
                        <div v-for="(event, index) in events.filter((_, i) => i % colNum === n - 1)" :key="index"
                            style="margin-bottom: 2rem;">
                            <link-to-event :id="event.id">
                                <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                                    :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                                    :event_ageRestriction="event.event_ageRestriction"
                                    :event_availiableSpots="event.event_availiableSpots"
                                    :event_totalSpots="event.event_totalSpots" :event_category="event.event_category"
                                    :id="event.id" :event_id_mentor="event.event_id_mentor">
                                </EventCardVue>
                            </link-to-event>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile-bottom-block">
                <div class="mobile-bottom-content">
                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                        <div class="event-price">Бесплатно</div>
                        <div v-if="canParticipate" class="participate-button" @click="registerMemberToEvent">
                            Участвовать
                        </div>
                        <div v-else class="participate-button-registered">Вы зарегистрированы</div>
                        <p class="available-seats">30 мест</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, onMounted, ref, watch, computed, onBeforeUnmount } from 'vue';
import axios from 'axios';
import VueJwtDecode from 'vue-jwt-decode';
import { useRoute } from 'vue-router';
import moment from 'moment';

import ReviewBlock from '../components/Review/ReviewBlock.vue';
import EventCardVue from "../components/EventCard.vue";
import LinkToMentor from '../components/Links/LinkToMentor.vue';
import CalendarSection from '../components/pages/personal/CalendarSection.vue'
import JumpingDotLoaderWithShadows from '@/components/Animations/JumpingDotLoaderWithShadows.vue';

export default defineComponent({
    name: 'EventPage',
    components: {
        ReviewBlock,
        EventCardVue,
        LinkToMentor,
        CalendarSection,
        JumpingDotLoaderWithShadows
    },

    setup() {
        const route = useRoute();

        const mentor = ref({});
        const loading = ref(true);

        const colNum = ref(3);

        const events = ref<any[]>([]);
        const currentEvent = ref<any>({});
        const reviews = ref<any[]>([]);

        // var reviews = Array();
        const memberId = ref<any>(null);

        const canParticipate = computed(() => {
            console.log("logg: ", currentEvent.value &&
                currentEvent.value.event_members_id &&
                !(currentEvent.value.event_members_id.indexOf(memberId.value) >= 0));
            return currentEvent.value &&
                currentEvent.value.event_members_id &&
                !(currentEvent.value.event_members_id.indexOf(memberId.value) >= 0);
        });

        const hasEvent = computed(() => {
            const valid = currentEvent.value &&
                currentEvent.value.id &&
                Array.isArray(reviews.value)
            console.log("log: hasEvent is", valid);
            console.log("log: ", reviews.value);

            return valid;
        });


        /* eslint-disable */
        function formated(value: Date) {
            var some_date = moment(String(value.toLocaleString('ru-RU'))).format('DD MMMM hh:mm');
            some_date = some_date.replace("September", "Сентября");
            some_date = some_date.replace("October", "Октября");
            some_date = some_date.replace("November", "Ноября");
            some_date = some_date.replace("December", "Декабря");
            some_date = some_date.replace("January", "Января");
            some_date = some_date.replace("February", "Февраля");
            some_date = some_date.replace("March", "Марта");
            some_date = some_date.replace("April", "Апреля");
            some_date = some_date.replace("May", "Мая");
            some_date = some_date.replace("June", "Июня");
            some_date = some_date.replace("July", "Июля");
            some_date = some_date.replace("August", "Августа");
            return some_date;
        }

        async function fetchData() {
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvents/');
                events.value = response.data;
                console.log(response.data);
                console.log(events.value);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        async function fetchMentor() {
            loading.value = true;
            try {
                const mentorId = currentEvent.value.event_id_mentor;
                const response = await axios.get('https://platforma34.tw1.ru/api/users/GetUser/?id=' + mentorId);
                mentor.value = response.data;
                console.log(response.data)
                console.log(mentor.value)
            } catch (error) {
                console.error('Error fetching mentor data:', error);
            } finally {
                setTimeout(() => {
                    loading.value = false; // Установить индикатор загрузки в false после задержки
                }, 500);
                // loading.value = false;
            }
        }

        async function fetchEvent() {
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvent/?id=' + route.params.id);
                currentEvent.value = response.data;
                console.log(response.data)
                console.log(currentEvent.value)
                // await fetchMentor();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                await fetchMentor();
            }
        }

        async function fetchReviews() {
            try {
                const response_reviews = await axios.get('https://platforma34.tw1.ru/api/event_reviews/GetEventReviews/?event_id=' + route.params.id);
                reviews.value = response_reviews.data;
                console.log(response_reviews.data)
                console.log(reviews.value)
                // console.log(this.items)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        async function getIdFromToken() {
            memberId.value = await VueJwtDecode.decode(localStorage.getItem("accessToken")).user_id
        }

        async function checkMember() {
            if (await currentEvent.value.event_members_id.indexOf(memberId.value) >= 0) {
                console.log(memberId.value)
                return true;
            }
            return false;
        }

        async function registerMemberToEvent() {
            console.log(memberId.value);
            if (currentEvent.value.event_members_id.indexOf(memberId.value) >= 0) {
                return;
            } else {
                currentEvent.value.event_members_id.push(memberId.value);
            }
            try {
                const url = axios.put('https://platforma34.tw1.ru/api/events/UpdateEvent/?id=' + route.params.id, {
                    'event_members_id': currentEvent.value.event_members_id
                });
            } catch (e) {
                console.log("AN ERROR ACCURED" + e);
            }

            try {
                const url = await axios.get('https://platforma34.tw1.ru/api/users/GetUserByATID/?user_id=' + memberId)
                console.log("New url ", url);
                // var menti_registered_events ;
                // menti_registered_events.append(route.params.id);
                // const url = axios.put('https://platforma34.tw1.ru/api/users/UpdateUser/?id=' + memberId, {
                //     'menti_registered_events': menti_registered_events  
                // });
                // console.log(url);
            } catch (e) {
                console.log("AN ERROR ACCURED WHILE ADDING EVENT TO MENTI'S EVENTS LIST" + e);
            }
        }

        function checkIfMobile() {
            if (window.innerWidth < 1024) {
                colNum.value = 2;
                console.log("colNum: ", colNum);
            }
        }
        const handleResize = () => {
            if (window.innerWidth < 1024) {
                colNum.value = 2;
                console.log("colNum: ", colNum);
            } else {
                colNum.value = 3;
                console.log("colNum: ", colNum);
            }
        }
        // watch(
        //     () => currentEvent.value,
        //     (newCurrentEvent) => {
        //         currentEvent.value = newCurrentEvent;
        //     }
        // );

        onMounted(async () => {
            checkIfMobile();
            fetchEvent();
            fetchData();
            fetchReviews();
            getIdFromToken();
            window.addEventListener('resize', handleResize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });


        return {
            registerMemberToEvent,
            // formated,
            currentEvent,
            events,
            memberId,
            canParticipate,
            hasEvent,
            reviews,
            mentor,
            loading,
            colNum
        };
    }
    // Add any necessary component logic here
})
</script>

<style scoped>
.event-page {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    /* Чтобы индикатор покрывал весь экран */
    top: 0;
    left: 0;
    display: flex;
    /* Для центрирования содержимого */
    justify-content: center;
    /* Горизонтальное центрирование */
    align-items: center;
    /* Вертикальное центрирование */
    opacity: 1;
    transition: opacity 0.5s;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
    /* Время анимации */
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active в версиях <2.1.8 */
    {
    opacity: 0;
    /* Начальное значение прозрачности */
}

/*
.header {
    background-color: #8A2BE2;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
}
*/

.avatar {
    width: 2rem;
    height: 2rem;
    background-color: #ccc;
    border-radius: 50%;
}

.main-content {
    padding: 1rem;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.tag {
    background-color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    border: 1px solid var(--neutral-neutral500, #A2A5B3)
}

.title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.date {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, rgba(87, 71, 175, 1));

    /*color: #666; */
    margin-bottom: 1rem;
}

.event-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1rem;
    aspect-ratio: 3/2;
    object-fit: cover;
}

.participation-info {
    margin-bottom: 1rem;
}

.event-price {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral900, #434966);

}

.available-seats {

    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #666;
    /* margin-bottom: 1rem; */
    padding: 4px 12px 4px 12px;
    border: 1px solid var(--neutral-neutral500, #A2A5B3);
    background: var(--neutral-neutral100, #F3F4F5);

    border-radius: 20px;
}

.participate-button {
    background-color: #6553ca;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    cursor: pointer;
    min-width: 30%;
    text-align: center;

    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    /* margin-bottom: 1rem; */
}

.participate-button-registered {
    background-color: #7c7a84;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    min-width: 30%;
    text-align: center;

    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    /* margin-bottom: 1rem; */
}

.participate-button:hover {
    background-color: #454152;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    /* margin-bottom: 1rem; */
}

.share-link-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral700, #525A7D);
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.share-link-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10px;
    border: 1px solid var(--neutral-neutral500, #A2A5B3);
    padding: 14px;
    margin-bottom: 1rem;
}

.link-text {
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral500, #A2A5B3);
}

.participants {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;

    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral900, #434966);

}

.section-title {
    /* font-size: 1.25rem; */
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--Base-Base-Black, #2A2037);

}

.topic-list {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;

    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A1C26;


}

.description {
    margin-bottom: 1.5rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A1C26;
}

.cancellation-policy {
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    background: var(--accent-accent50, rgba(250, 247, 253, 1));
}

.policy-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;

    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, rgba(87, 71, 175, 1));


}

.policy-text {
    font-size: 0.875rem;

    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, rgba(87, 71, 175, 1));


}

.trainer-info {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
}

.trainer-avatar {
    width: 4rem;
    height: 4rem;
    background-color: #ccc;
    border-radius: 50%;
}

.mentor_avatar {
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
}

.trainer-name {
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
}

.trainer-title {
    font-size: 0.875rem;
    color: #666;
}

.trainer-description {
    font-size: 0.875rem;
    margin-bottom: 1rem;

    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.coffee-image-placeholder {
    background: var(--neutral-neutral100, white);
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 16px;
    padding-top: 8px;
}

.mobile-only {
    display: block;
}

.desktop-only {
    display: none;
}

.card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem 2rem;
    width: 100%;
    background-color: #454152 alpha 1;
    padding: 0;
    margin: 0;
}


@media (max-width: 768px) {
    .reviews {
        margin-bottom: 2rem;
        width: auto;
        max-width: 95vw;
    }
}

/* @media (min-width: 1024px) { */
@media (min-width: 1024px) {
    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: block;
    }

    .header {
        padding: 1rem 2rem;
    }

    .content {
        display: flex;
    }

    .sidebar {
        width: 4rem;
        background-color: #ffffff;
        border-right: 1px solid #e0e0e0;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        position: fixed;
        top: 100px;
        bottom: 0;
        left: 0;
    }

    .sidebar-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .main {
        display: flex;
        gap: 2rem;
    }

    .main-content {
        flex: 2.1;
        padding: 0;
        margin-left: 0px;
    }

    .title {
        font-size: 2rem;
    }

    .sidebar-right {
        flex: 1;
        position: sticky;
        top: 90px;
        align-self: flex-start;
        /* height: calc(100vh - 90px); */
        overflow-y: auto;

    }

    .trainer-card {
        /* background-color: #f9f9f9; */
        padding: 1rem;
        border-radius: 8px;
        background: #F3F4F5;
    }

    .reviews {
        margin-bottom: 2rem;
        width: auto;
        max-width: calc(100vw - 200px);
    }

    .reviews2 {
        margin-bottom: 2rem;
        width: auto;
        max-width: calc(100vw - 120px);
    }


    @media (min-width: 1800px) {
        .reviews {
            margin-bottom: 2rem;
            width: auto;
            max-width: 70vw;
        }
    }


    .add-review {
        color: #8A2BE2;
        font-size: 0.875rem;
        text-decoration: none;
        margin-left: 0.5rem;
    }

    .review {
        background-color: #f9f9f9;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 1rem;
        display: flex;
        gap: 1rem;
    }

    .review-avatar {
        width: 3rem;
        height: 3rem;
        background-color: #ccc;
        border-radius: 50%;
    }

    .review-author {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .review-text {
        font-size: 0.875rem;
    }

    .pagination {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
    }

    .pagination-button {
        background: none;
        border: 1px solid #ccc;
        padding: 0.5rem;
        cursor: pointer;
    }

    .event-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    .event-image-placeholder {
        width: 100%;
        height: 150px;
        background-color: #ccc;
        border-radius: 8px;
        margin-bottom: 0.5rem;
    }

    .event-card-title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .event-card-author,
    .event-card-date {
        font-size: 0.875rem;
        color: #666;
        margin-bottom: 0.25rem;
    }

    .event-card-price {
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    .bookmark-button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.25rem;
    }
}

.events-section-title {

    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A1C26;
    padding-bottom: 40px;


}

.masonry-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    margin-left: 2vw;
    margin-right: 2vw;
}

.masonry-column {
    flex: 0 0 calc(33.33% - 2vw);
    max-width: calc(33.33% - 2vw);
}

@media (max-width: 1024px) {
    .masonry-container {
        gap: 4vw;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .masonry-column {
        flex: 0 0 calc(50% - 2vw);
        max-width: calc(50% - 2vw);
    }

    .masonry-container>.masonry-column:nth-child(3) {
        display: none;
    }
}

.mobile-bottom-block {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.mobile-bottom-content {
    padding: 0 1rem;
    padding-bottom: 0.5 rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

@media (max-width: 1023px) {
    .mobile-bottom-block {
        display: block;
    }

    .main-content {
        padding-bottom: 100px;
        /* Adjust this value based on the height of your bottom block */
    }

    /* Hide desktop-only elements */
    .desktop-only {
        display: none;
    }
}


/*
.masonry-container {
    display: flex;
    gap: 2vw;
}

.masonry-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3vw;
    max-width: calc((100vw - 12vw - 2 * 2vw)/3);
}*/
</style>