<template>
    <div class="profile-settings">
        <main class="main-content">
            <div v-if="loading" class="loading">
                Загрузка...
            </div>
            <div v-else-if="error" class="error">
                {{ error }}
            </div>
            <div v-else class="profile-fields">
                <div v-for="(field, key) in fields" :key="key" class="field">
                    <div class="field-header">
                        <h3>{{ field.label }}</h3>
                        <div class="edit-buttons">
                            <template v-if="!field.isEditing">
                                <button class="edit-btn" @click="startEditing(key)">
                                    Редактировать
                                </button>
                            </template>
                            <template v-else>
                                <button class="save-btn" @click="saveField(key)">
                                    Сохранить
                                </button>
                                <button class="cancel-btn" @click="cancelEditing(key)">
                                    Отмена
                                </button>
                            </template>
                        </div>
                    </div>
                    <div class="field-content" :data-field="key">
                        <template v-if="!field.isEditing">
                            {{ field.value }}
                        </template>
                        <template v-else>
                            <textarea v-if="!field.isMultiSelect" v-model="field.editValue" class="edit-input"
                                rows="1"></textarea>
                            <select v-else v-model="field.editValue" multiple class="edit-select">
                                <option v-for="option in field.options" :key="option" :value="option">
                                    {{ option }}
                                </option>
                            </select>
                        </template>
                    </div>
                </div>

                <div class="profile-cover">
                    <h3>Обложка профиля</h3>
                    <p class="cover-description">
                        Добавьте фото или видео обложку. Фото 1920x1080 до 5 мб. Видео 1920x1080 до 15 мб.
                    </p>
                    <div class="cover-upload">
                        <div v-if="userData?.mentor_avatar_url || uploadedImage" class="uploaded-image">
                            <img :src="uploadedImage || userData?.mentor_avatar_url" alt="Profile cover" />
                        </div>
                        <div class="button-group">
                            <button v-if="uploadedImage || userData?.mentor_avatar_url" @click="removeUploadedImage"
                                class="remove-btn">
                                Удалить обложку
                            </button>
                            <label for="cover-upload" class="upload-btn">
                                {{ uploadedImage || userData?.mentor_avatar_url ? 'Сменить обложку' : '⬆️ Загрузите обложку' }}
                            </label>
                        </div>
                        <input type="file" @change="handleFileUpload" accept="image/*" id="cover-upload"
                            class="file-input" />
                    </div>
                </div>

                <button v-if="hasChanges" @click="saveAllChanges" class="save-all-btn">
                    Сохранить изменения
                </button>
            </div>
        </main>
        <SuccessPopup :show="showSuccessPopup" message="Изменения успешно сохранены" />
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick, computed } from 'vue'
import axios from 'axios'
import SuccessPopup from './Popups/SuccessPopup.vue'

const API_URL = 'https://platforma34.tw1.ru/api'

const userData = ref(null)
const loading = ref(true)
const error = ref(null)
const uploadedImage = ref(null)
const originalData = ref(null)
const showSuccessPopup = ref(false)

const fields = reactive({
    name: {
        label: 'Имя',
        value: '',
        editValue: '',
        isEditing: false
    },
    aboutMe: {
        label: 'О себе',
        value: '',
        editValue: '',
        isEditing: false
    },
})

const hasChanges = computed(() => {
    if (!originalData.value) return false
    return fields.name.value !== originalData.value.name ||
        fields.aboutMe.value !== originalData.value.aboutMe ||
        uploadedImage.value !== null
})

const fetchUserData = async () => {
    try {
        loading.value = true
        error.value = null

        const { data } = await axios.get(`${API_URL}/users/GetUser/`, {
            params: {
                id: 164
            }
        })

        userData.value = data

        fields.name.value = `${data.mentor_name} ${data.mentor_surname}`
        fields.name.editValue = `${data.mentor_name} ${data.mentor_surname}`

        fields.aboutMe.value = data.mentor_description
        fields.aboutMe.editValue = data.mentor_description

        originalData.value = {
            name: fields.name.value,
            aboutMe: fields.aboutMe.value,
            avatar: data.mentor_avatar_url
        }

    } catch (e) {
        error.value = e.response?.data?.message || 'Произошла ошибка при загрузке данных'
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    fetchUserData()
})

const adjustTextareaHeight = (event) => {
    const textarea = event.target
    textarea.style.height = 'auto'
    textarea.style.height = textarea.scrollHeight + 'px'
}

const startEditing = (fieldKey) => {
    fields[fieldKey].isEditing = true
    fields[fieldKey].editValue = fields[fieldKey].value
    nextTick(() => {
        const textarea = document.querySelector(`[data-field="${fieldKey}"] textarea`)
        if (textarea) {
            adjustTextareaHeight({ target: textarea })
            textarea.addEventListener('input', adjustTextareaHeight)
        }
    })
}

const saveField = (fieldKey) => {
    fields[fieldKey].value = fields[fieldKey].editValue
    fields[fieldKey].isEditing = false
    const textarea = document.querySelector(`[data-field="${fieldKey}"] textarea`)
    if (textarea) {
        textarea.removeEventListener('input', adjustTextareaHeight)
    }
}

const cancelEditing = (fieldKey) => {
    fields[fieldKey].isEditing = false
    fields[fieldKey].editValue = fields[fieldKey].value
}

const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
            uploadedImage.value = e.target.result
        }
        reader.readAsDataURL(file)
    }
}

const removeUploadedImage = () => {
    uploadedImage.value = null
    if (userData.value) {
        userData.value.mentor_avatar_url = null
    }
}

const saveAllChanges = async () => {
    try {
        loading.value = true
        error.value = null

        const [firstName, ...lastNameParts] = fields.name.value.split(' ')
        const lastName = lastNameParts.join(' ')

        const updatedData = {
            mentor_name: firstName,
            mentor_surname: lastName,
            mentor_description: fields.aboutMe.value,
        }

        if (uploadedImage.value) {
            const base64Response = await fetch(uploadedImage.value)
            const blob = await base64Response.blob()

            const formData = new FormData()
            formData.append('mentor_avatar', blob, 'profile_image.jpg')

            Object.keys(updatedData).forEach(key => {
                formData.append(key, updatedData[key])
            })

            await axios.put(`${API_URL}/users/UpdateUser/?user_id=164`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        } else {
            await axios.put(`${API_URL}/users/UpdateUser/?user_id=164`, updatedData)
        }

        originalData.value = {
            name: fields.name.value,
            aboutMe: fields.aboutMe.value,
            avatar: uploadedImage.value || userData.value.mentor_avatar_url
        }

        uploadedImage.value = null

        showSuccessPopup.value = true
        setTimeout(() => {
            showSuccessPopup.value = false
        }, 3000)
    } catch (e) {
        error.value = e.response?.data?.message || 'Произошла ошибка при сохранении данных'
    } finally {
        loading.value = false
    }
}
</script>

<style scoped>
.profile-settings {
    padding: 2rem;
}

.main-content {
    max-width: 800px;
    margin: 0 auto;
}

.loading,
.error {
    text-align: center;
    padding: 2rem;
    font-size: 1.1rem;
}

.error {
    color: #d32f2f;
}

.field {
    margin-bottom: 2rem;
}

.field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.edit-buttons {
    display: flex;
    gap: 0.5rem;
}

.edit-btn,
.save-btn,
.cancel-btn,
.save-all-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.edit-btn {
    color: #7c4dff;
    background: none;
}

.save-btn,
.save-all-btn {
    background-color: #7c4dff;
    color: white;
}

.cancel-btn {
    background-color: #f5f5f5;
    color: #666;
}

.save-all-btn {
    display: block;
    width: 100%;
    margin-top: 2rem;
    font-size: 1.1rem;
}

.edit-input,
.edit-select {
    width: 100%;
    padding: 0;
    border: none;
    background: white;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    resize: none;
    overflow-y: auto;
    max-height: 12em;
    border-radius: 10px;
    padding: 12px;
    padding-bottom: 0px;
    margin: -12px;
}

.edit-input:focus,
.edit-select:focus {
    outline: none;
}

.edit-select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.profile-cover {
    margin-top: 2rem;
    padding: 2rem;
    background-color: #f5f5f5;
    border-radius: 8px;
    text-align: center;
}

.cover-description {
    color: #666;
    margin-bottom: 1rem;
}

.cover-upload {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploaded-image {
    margin-bottom: 1rem;
    border-radius: 8px;
    overflow: hidden;
    max-width: 100%;
    margin-top: 2rem;
}

.uploaded-image img {
    width: 100%;
    height: auto;
    display: block;
}

.file-input {
    display: none;
}

.button-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
}

.upload-btn {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}

.upload-btn:hover {
    background-color: white;
}

.remove-btn {
    padding: 0.75rem 1.5rem;
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.3);
    color: #d32f2f;
    border-radius: 4px;
    cursor: pointer;
}

.remove-btn:hover {
    background-color: rgba(255, 0, 0, 0.2);
}
</style>