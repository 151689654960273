<template>
    <header class="header">
        <div class="header-top-wrapper">
            <a href="/" class="logo">ФОРМА</a>
            <div class="header-buttons">
                <router-link to="/AllEvents" :class="{ active: currentPath === '/AllEvents' }">
                    <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24 24L16.6667 16.6667M19.1111 10.5556C19.1111 15.2807 15.2807 19.1111 10.5556 19.1111C5.83045 19.1111 2 15.2807 2 10.5556C2 5.83045 5.83045 2 10.5556 2C15.2807 2 19.1111 5.83045 19.1111 10.5556Z"
                            stroke="#998BF8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </router-link>

                <div class="header-button-menu">
                    <svg width="26" height="22" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.932617 2.66647C0.932617 1.63554 1.76835 0.799805 2.79928 0.799805H25.1993C26.2302 0.799805 27.0659 1.63554 27.0659 2.66647C27.0659 3.6974 26.2302 4.53314 25.1993 4.53314H2.79928C1.76835 4.53314 0.932617 3.6974 0.932617 2.66647Z"
                            fill="#998BF8" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.932617 11.9998C0.932617 10.9689 1.76835 10.1331 2.79928 10.1331H25.1993C26.2302 10.1331 27.0659 10.9689 27.0659 11.9998C27.0659 13.0307 26.2302 13.8665 25.1993 13.8665H2.79928C1.76835 13.8665 0.932617 13.0307 0.932617 11.9998Z"
                            fill="#998BF8" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.932617 21.3331C0.932617 20.3022 1.76835 19.4665 2.79928 19.4665H13.9993C15.0302 19.4665 15.8659 20.3022 15.8659 21.3331C15.8659 22.3641 15.0302 23.1998 13.9993 23.1998H2.79928C1.76835 23.1998 0.932617 22.3641 0.932617 21.3331Z"
                            fill="#998BF8" />
                    </svg>
                </div>
            </div>
        </div>
        <div v-if="headerTypeSearch">
            <div class="header-bottom-wrapper">
                <div class="toggle-container">
                    <div class="toggle-wrapper">
                        <div class="toggle-slider"
                            :style="{ transform: `translateX(${currentPath === '/AllMentors' ? '100%' : '0'})`, background: `${(currentPath === '/AllMentors' || currentPath === '/AllEvents') ? 'var(--accent-accent100, #DCD8FC)' : 'none'}` }">
                        </div>
                        <router-link to="/AllEvents" class="toggle-button"
                            :class="{ active: currentPath === '/AllEvents' }">
                            Мероприятия
                        </router-link>
                        <router-link to="/AllMentors" class="toggle-button"
                            :class="{ active: currentPath === '/AllMentors' }">
                            Менторы
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="headerTypeLanding">
            <div class="header-bottom-wrapper">
                <div class="toggle-container">
                    <div class="toggle-wrapper">
                        <div class="toggle-slider"
                            :style="{ transform: `translateX(${currentPath === '/' ? '100%' : '0'})` }">
                        </div>
                        <router-link to="/LandingMenteePage" class="toggle-button"
                            :class="{ active: currentPath === '/LandingMenteePage' }">
                            Хочу учиться
                        </router-link>
                        <router-link to="/" class="toggle-button" :class="{ active: currentPath === '/' }">
                            Хочу учить
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useRoute/*, useRouter*/ } from 'vue-router'

const route = useRoute()
// const router = useRouter()
const currentPath = ref(route.path)

// Watch for route changes
watch(
    () => route.path,
    (newPath) => {
        currentPath.value = newPath
    }
)

// const navigateToPage = (route) => {
//     router.push(route);
// }

// Set initial path on component mount
onMounted(() => {
    currentPath.value = route.path
})

const headerTypeSearch = computed(() => {
    const headerType = route.meta.header;
    return headerType == 'Search';
});

const headerTypeLanding = computed(() => {
    const headerType = route.meta.header;
    return headerType == 'Landing';
});


</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: none;

}

.header-top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px; */
    background-color: #ffffff;
    padding: 20px;
    /* background: var(--neutra l-neutral100, #F3F4F5); */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); */


}

.header-bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #F3F4F5;
    background: var(--neutra l-neutral100, #F3F4F5);
}

.logo {
    color: #998BF8;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.toggle-container {
    position: relative;
}

.toggle-wrapper {
    position: relative;
    display: flex;
    /* background-color: #f3f4f5; */
    background-color: white;

    border-radius: 100px;
    padding: 4px;
    cursor: pointer;
    width: 280px;
}

.toggle-slider {
    position: absolute;
    width: 49%;
    height: calc(100% - 8px);
    background: var(--accent-accent100, #DCD8FC);
    border-radius: 100px;
    transition: all 0.3s ease;

}

.toggle-button {
    position: relative;
    z-index: 1;
    width: 50%;
    padding: 8px 16px;
    border: none;
    background: none;
    color: var(--accent-accent800, #5747AF);

    /*color: #454152;*/
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;
    text-align: center;
}

.toggle-button.active {
    color: var(--accent-accent600, #705CE2);
}

.login-button {
    padding: 8px 24px;
    border: none;
    background: none;
    color: #454152;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.2s ease;
}

.login-button:hover {
    color: #8162e9;
}

.header-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header-button-menu {
    margin-left: 20px;
}
</style>
