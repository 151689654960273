import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "event-manager" }
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = { class: "content-grid" }
const _hoisted_4 = { class: "content-section" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group checkbox-group" }
const _hoisted_15 = { class: "content-section" }
const _hoisted_16 = { class: "templates-list" }
const _hoisted_17 = { class: "template-content" }
const _hoisted_18 = { class: "template-title" }
const _hoisted_19 = { class: "template-details" }
const _hoisted_20 = { class: "content-section" }
const _hoisted_21 = { class: "calendar-grid" }
const _hoisted_22 = { class: "calendar-grid" }
const _hoisted_23 = { class: "day-number" }
const _hoisted_24 = {
  key: 0,
  class: "event-indicator"
}
const _hoisted_25 = { class: "events-list" }
const _hoisted_26 = { class: "event-date" }
const _hoisted_27 = { class: "event-details" }
const _hoisted_28 = { class: "event-title" }
const _hoisted_29 = { class: "event-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[27] || (_cache[27] = _createStaticVNode("<header class=\"header\" data-v-e1a33e4e><div class=\"header-content\" data-v-e1a33e4e><div class=\"header-left\" data-v-e1a33e4e><h1 class=\"logo\" data-v-e1a33e4e>ФОРМА</h1><nav class=\"main-nav\" data-v-e1a33e4e><a href=\"#\" class=\"nav-link\" data-v-e1a33e4e>Мероприятия</a><a href=\"#\" class=\"nav-link\" data-v-e1a33e4e> Сообщения <span class=\"notification-badge\" data-v-e1a33e4e>9+</span></a><a href=\"#\" class=\"nav-link\" data-v-e1a33e4e>Избранное</a><a href=\"#\" class=\"nav-link\" data-v-e1a33e4e>Настройки</a></nav></div><div class=\"header-right\" data-v-e1a33e4e><div class=\"search-container\" data-v-e1a33e4e><input type=\"text\" placeholder=\"Найти\" class=\"search-input\" data-v-e1a33e4e><button class=\"search-button\" data-v-e1a33e4e><span class=\"search-icon\" data-v-e1a33e4e></span></button></div></div></div></header>", 1)),
    _createElementVNode("main", _hoisted_2, [
      _cache[26] || (_cache[26] = _createElementVNode("h2", { class: "page-title" }, "Управляйте своим мероприятиями", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[21] || (_cache[21] = _createStaticVNode("<h3 class=\"section-title\" data-v-e1a33e4e>Новое мероприятие</h3><p class=\"section-description\" data-v-e1a33e4e> Вы всегда сможете изменить детали мероприятия </p><div class=\"upload-cover\" data-v-e1a33e4e><button class=\"upload-button\" data-v-e1a33e4e><span class=\"upload-icon\" data-v-e1a33e4e></span><span data-v-e1a33e4e>Загрузите обложку</span></button></div>", 3)),
          _createElementVNode("form", {
            onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
            class: "event-form"
          }, [
            _createElementVNode("div", _hoisted_5, [
              _cache[11] || (_cache[11] = _createElementVNode("label", {
                for: "title",
                class: "form-label"
              }, "Напишите название", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event)),
                type: "text",
                id: "title",
                class: "form-input",
                placeholder: "Сияй изнутри"
              }, null, 512), [
                [_vModelText, _ctx.form.title]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[12] || (_cache[12] = _createElementVNode("label", {
                for: "description",
                class: "form-label"
              }, "Добавьте описание", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.description) = $event)),
                id: "description",
                rows: "4",
                class: "form-input",
                placeholder: "Сияй изнутри Сияй изнутри Сияй изнутри"
              }, null, 512), [
                [_vModelText, _ctx.form.description]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[13] || (_cache[13] = _createElementVNode("label", {
                  for: "date",
                  class: "form-label"
                }, "Дата", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.date) = $event)),
                  type: "date",
                  id: "date",
                  class: "form-input"
                }, null, 512), [
                  [_vModelText, _ctx.form.date]
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[14] || (_cache[14] = _createElementVNode("label", {
                  for: "time",
                  class: "form-label"
                }, "Время", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.time) = $event)),
                  type: "time",
                  id: "time",
                  class: "form-input"
                }, null, 512), [
                  [_vModelText, _ctx.form.time]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[15] || (_cache[15] = _createElementVNode("label", {
                for: "price",
                class: "form-label"
              }, "Назначьте цену (опционально)", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.price) = $event)),
                type: "number",
                id: "price",
                class: "form-input",
                placeholder: "500 руб"
              }, null, 512), [
                [_vModelText, _ctx.form.price]
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[16] || (_cache[16] = _createElementVNode("label", {
                for: "discount",
                class: "form-label"
              }, "Добавьте скидку (опционально)", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.discount) = $event)),
                type: "number",
                id: "discount",
                class: "form-input",
                placeholder: "50%"
              }, null, 512), [
                [_vModelText, _ctx.form.discount]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[17] || (_cache[17] = _createElementVNode("label", {
                for: "maxParticipants",
                class: "form-label"
              }, "Максимальное число человек", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.maxParticipants) = $event)),
                type: "number",
                id: "maxParticipants",
                class: "form-input",
                placeholder: "200"
              }, null, 512), [
                [_vModelText, _ctx.form.maxParticipants]
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[18] || (_cache[18] = _createElementVNode("label", {
                for: "minAge",
                class: "form-label"
              }, "Минимальный возраст", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.minAge) = $event)),
                type: "number",
                id: "minAge",
                class: "form-input",
                placeholder: "18"
              }, null, 512), [
                [_vModelText, _ctx.form.minAge]
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.isPublic) = $event)),
                type: "checkbox",
                id: "isPublic",
                class: "form-checkbox"
              }, null, 512), [
                [_vModelCheckbox, _ctx.form.isPublic]
              ]),
              _cache[19] || (_cache[19] = _createElementVNode("label", {
                for: "isPublic",
                class: "checkbox-label"
              }, "Публичное мероприятие", -1))
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.saveTemplate && _ctx.saveTemplate(...args)))
            }, " Сохранить как шаблон "),
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "button-group" }, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-outline"
              }, " Выбрать шаблон "),
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary"
              }, " Опубликовать ")
            ], -1))
          ], 32)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[23] || (_cache[23] = _createElementVNode("h3", { class: "section-title" }, "Мои шаблоны", -1)),
          _cache[24] || (_cache[24] = _createElementVNode("p", { class: "section-description" }, " Используйте сохранённые шаблоны чтобы создать аналогичное мероприятие ", -1)),
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templates, (template) => {
              return (_openBlock(), _createElementBlock("div", {
                key: template.id,
                class: "template-item"
              }, [
                _createElementVNode("div", _hoisted_17, [
                  _cache[22] || (_cache[22] = _createElementVNode("span", { class: "plus-icon" }, null, -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("h4", _hoisted_18, _toDisplayString(template.title), 1),
                    _createElementVNode("p", _hoisted_19, _toDisplayString(template.time) + " · " + _toDisplayString(template.duration), 1)
                  ])
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"calendar-header\" data-v-e1a33e4e><button class=\"calendar-nav-button\" data-v-e1a33e4e><span class=\"chevron-left\" data-v-e1a33e4e></span></button><h3 class=\"calendar-title\" data-v-e1a33e4e>27 Октября - 02 Ноября</h3><button class=\"calendar-nav-button\" data-v-e1a33e4e><span class=\"chevron-right\" data-v-e1a33e4e></span></button></div>", 1)),
          _createElementVNode("div", _hoisted_21, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'], (day) => {
              return _createElementVNode("div", {
                key: day,
                class: "calendar-day-name"
              }, _toDisplayString(day), 1)
            }), 64))
          ]),
          _createElementVNode("div", _hoisted_22, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendarDays, (day) => {
              return (_openBlock(), _createElementBlock("div", {
                key: day.date,
                class: _normalizeClass(["calendar-day", { 'is-today': day.isToday }])
              }, [
                _createElementVNode("span", _hoisted_23, _toDisplayString(day.dayNumber), 1),
                (day.hasEvent)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_24))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_25, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.todayEvents, (event) => {
              return (_openBlock(), _createElementBlock("div", {
                key: event.id,
                class: "event-item"
              }, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("span", null, _toDisplayString(event.day), 1)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("h4", _hoisted_28, _toDisplayString(event.title), 1),
                  _createElementVNode("p", _hoisted_29, _toDisplayString(event.time) + " · " + _toDisplayString(event.duration), 1)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}