<template>
    <div>
        <transition name="fade" @before-leave="beforeLeave" @leave="leave">
            <div v-if="loading == true" class="loading">
                <LandingLoadingAnimation />
            </div>
        </transition>
        <div class="landing-page">
            <!-- Hero Section -->
            <section class="hero-section">
                <div class="content-block2 hero-block">
                    <div class="hero-content">
                        <div>Ваш новый путь<br>к хобби и увлечениям</div>
                        <p>
                            Научитесь играть на гитаре, английскому языку или рисовать красивые пейзажи.
                            Онлайн-встречи с профессионалами своего дела и просто знающими людьми,
                            готовыми делиться своими навыками и умениями.
                        </p>
                        <div class="hero-buttons">
                            <button @click="navigateToPage('/AllMentors')" class="button-landing-all-mentors">Выбрать
                                наставника</button>
                            <button class="button-landing-more">Подробнее</button>
                        </div>
                    </div>
                </div>
                <div class="photo-card">
                    <div class="profile2">
                        <img src="https://storage.yandexcloud.net/testbucketplatforma34/pages/LandingMenteePage/redhead_girl.jpeg"
                            alt="Profile">
                    </div>
                </div>
                <div class="stats-card">
                    <div>

                        <svg width="214" height="64" viewBox="0 0 214 64" fill="none" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#EBECEE" />
                            <rect x="50.5" y="0.5" width="63" height="63" rx="31.5" stroke="#EBECEE" />
                            <rect x="100.5" y="0.5" width="63" height="63" rx="31.5" fill="url(#pattern0_55660_5337)"
                                stroke="#EBECEE" />
                            <rect x="150.5" y="0.5" width="63" height="63" rx="31.5" stroke="#EBECEE" />
                            <defs>
                                <pattern id="pattern0_55660_5337" patternContentUnits="objectBoundingBox" width="1"
                                    height="1">
                                    <use xlink:href="#image0_55660_5337" transform="scale(0.0025)" />
                                </pattern>
                            </defs>
                        </svg>
                    </div>

                    <!-- <div> 
                        <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100" alt="Profile"
                            class="stats-avatar">
                    </div> -->
                    <div class="stats-text">
                        <strong>2000+ студентов уже с нами</strong>
                        <p>Будущие специалисты в разных сферах и с реальным опытом</p>
                    </div>
                </div>
            </section>

            <!-- Masterclass Section -->
            <section class="section masterclass-section">
                <div class="section-title-text">Присоединяйтесь к ближайшим <br> мастер-классам</div>
                <div class="masterclass-grid">
                    <div v-for="(class_, index) in masterclasses" :key="index" class="masterclass-card"
                        :class="{ 'masterclass-card--featured': index === 1 }">
                        <link-to-event :id="class_.id">
                            <img :src="class_.image" alt="Masterclass thumbnail" class="masterclass-image">
                            <div class="masterclass-info">
                                <div class="author">
                                    <img :src="class_.authorImage" alt="Author" class="author-avatar">
                                    <span>{{ class_.author }}</span>
                                </div>
                                <h3>{{ class_.title }}</h3>
                                <div class="card-footer">
                                    <div style="display: flex; gap: 10px; align-items: bottom;">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.66667 4.83333V1.5M12.3333 4.83333V1.5M4.83333 8.16667H13.1667M3.16667 16.5H14.8333C15.7538 16.5 16.5 15.7538 16.5 14.8333V4.83333C16.5 3.91286 15.7538 3.16667 14.8333 3.16667H3.16667C2.24619 3.16667 1.5 3.91286 1.5 4.83333V14.8333C1.5 15.7538 2.24619 16.5 3.16667 16.5Z"
                                                stroke="#525A7D" stroke-width="2" stroke-linecap="round" ƒ
                                                stroke-linejoin="round" />
                                        </svg>


                                        <span class="date">{{ class_.date }}</span>
                                    </div>
                                    <span class="likes">
                                        <svg class="heart-icon" viewBox="0 0 24 24">
                                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 
                         2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 
                         4.5 2.09C13.09 3.81 14.76 3 
                         16.5 3 19.58 3 22 5.42 
                         22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                        </svg>
                                        {{ class_.likes }}
                                    </span>
                                </div>
                            </div>
                        </link-to-event>
                    </div>
                </div>
                <button @click="navigateToPage('/AllEvents')" class="btn-more">Показать больше</button>
            </section>

            <!-- Categories Section -->
            <section class="section categories-section">
                <div class="categories-header">
                    <span class="categories-text">У нас доступно</span>
                    <span class="categories-highlight"> более 1000 </span><br>
                    <span class="categories-text">разных направлений!</span>
                </div>
                <div class="categories-grid">
                    <div v-for="category in categories" :key="category.title" class="category-card">
                        <img :src="category.image" :alt="category.title">
                        <span class="category-label">{{ category.title }}</span>
                    </div>
                </div>
            </section>

            <section class="section">
                <div class="about-grinding">
                    <div class="grinding-header">
                        <span style="color: var(--accent-accent200, #C8C2FB);">Мы сделали </span>
                        <span style="color:#FAF7FD;"> получение навыков <br>
                            максимально доступным и удобным</span>
                    </div>
                    <div class="about-grinding-large">
                        <div class="about-grinding-large-card">
                            <div class="grinding-text">
                                <div class="grinding-title">Занятия по видеочату</div>
                                <div class="grinding-sub-text">
                                    Наши видеоуроки проходят в удобном личном<br> кабинете, что позволяет вам заниматься
                                    <br>в комфортной домашней обстановке или любом другом месте с доступом<br> к
                                    интернету.
                                    Вам не нужно тратить время <br>на дорогу или искать подходящее место <br>для встреч
                                    —
                                    всё обучение происходит <br>онлайн, где бы вы ни находились.
                                </div>
                            </div>
                            <div class="grinding-photo">
                                <img src="https://storage.yandexcloud.net/testbucketplatforma34/pages/LandingMenteePage/girl_teachinf.jpeg"
                                    alt="girl teaching">
                            </div>
                        </div>
                        <div class="about-grinding-large-card">
                            <div class="grinding-photo2">
                                <img src="https://storage.yandexcloud.net/testbucketplatforma34/pages/LandingMenteePage/girl_writting.jpeg"
                                    alt="girl teaching">
                            </div>
                            <div class="grinding-text">
                                <div class="grinding-title">Удобное расписание</div>
                                <div class="grinding-sub-text">
                                    Мы предоставляем гибкую систему<br> управления расписанием. Вы можете <br>назначать
                                    и
                                    переносить занятия на любое <br>удобное для вас время, что позволяет
                                    <br>адаптировать
                                    обучение под ваш график.<br> Наша система напоминаний поможет<br> вам не забыть о
                                    предстоящих уроках,<br> напоминая за час до начала.
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="about-grinding-small">
                        <div class="about-grinding-small-card">
                            <div class="small-grinding-text">
                                <div class="small-grinding-title">
                                    Запись<br> занятий
                                    <svg width="26" height="18" viewBox="0 0 26 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17 6.33333L23.0704 3.29814C23.9569 2.85487 25 3.49954 25 4.49071V13.5093C25 14.5005 23.9569 15.1451 23.0704 14.7019L17 11.6667M3.66667 17H14.3333C15.8061 17 17 15.8061 17 14.3333V3.66667C17 2.19391 15.8061 1 14.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V14.3333C1 15.8061 2.19391 17 3.66667 17Z"
                                            stroke="#5747AF" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div class="small-grinding-sub-text">
                                    Все занятия записываются,<br> вы сможете пересмотреть<br> их необходимое
                                    <br>количество
                                    раз <br>для закрепления<br> материала
                                </div>
                            </div>
                        </div>
                        <div class="about-grinding-small-card">
                            <div class="small-grinding-text">
                                <div class="small-grinding-title">
                                    Индивидуальная<br> цена
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1 5L5 6.33333M5 6.33333L1 18.3333C3.36337 20.1112 6.63822 20.1112 9.00159 18.3334M5 6.33333L9.00008 18.3333M5 6.33333L13 3.66667M21 6.33333L25 5M21 6.33333L17 18.3333C19.3634 20.1112 22.6382 20.1112 25.0016 18.3334M21 6.33333L25.0001 18.3333M21 6.33333L13 3.66667M13 1V3.66667M13 25V3.66667M13 25H9M13 25H17"
                                            stroke="#5747AF" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>

                                </div>
                                <div class="small-grinding-sub-text">
                                    Поможем подобрать наставника под ваш бюджет и предложим скидки за длительное
                                    сотрудничество
                                </div>
                            </div>
                        </div>
                        <div class="about-grinding-small-card">
                            <div class="small-grinding-text">
                                <div class="small-grinding-title">
                                    Комьюнити<br> единомышленников
                                    <svg width="30" height="24" viewBox="0 0 30 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21.667 22.6667H28.3337V20C28.3337 17.7909 26.5428 16 24.3337 16C23.0595 16 21.9245 16.5958 21.192 17.5239M21.667 22.6667H8.33366M21.667 22.6667V20C21.667 19.1251 21.4984 18.2894 21.192 17.5239M8.33366 22.6667H1.66699V20C1.66699 17.7909 3.45785 16 5.66699 16C6.94114 16 8.07614 16.5958 8.80865 17.5239M8.33366 22.6667V20C8.33366 19.1251 8.50222 18.2894 8.80865 17.5239M8.80865 17.5239C9.79166 15.0681 12.1934 13.3334 15.0003 13.3334C17.8072 13.3334 20.209 15.0681 21.192 17.5239M19.0003 5.33337C19.0003 7.54251 17.2095 9.33337 15.0003 9.33337C12.7912 9.33337 11.0003 7.54251 11.0003 5.33337C11.0003 3.12423 12.7912 1.33337 15.0003 1.33337C17.2095 1.33337 19.0003 3.12423 19.0003 5.33337ZM27.0003 9.33337C27.0003 10.8061 25.8064 12 24.3337 12C22.8609 12 21.667 10.8061 21.667 9.33337C21.667 7.86061 22.8609 6.66671 24.3337 6.66671C25.8064 6.66671 27.0003 7.86061 27.0003 9.33337ZM8.33366 9.33337C8.33366 10.8061 7.13975 12 5.66699 12C4.19423 12 3.00033 10.8061 3.00033 9.33337C3.00033 7.86061 4.19423 6.66671 5.66699 6.66671C7.13975 6.66671 8.33366 7.86061 8.33366 9.33337Z"
                                            stroke="#5747AF" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>


                                </div>
                                <div class="small-grinding-sub-text">
                                    На групповых мероприятиях вы сможете найти друзей по интересам, делиться успехами и
                                    вместе захватить мир
                                </div>
                            </div>
                        </div>
                        <div class="about-grinding-small-card">
                            <div class="small-grinding-text">
                                <div class="small-grinding-title">
                                    Поддержка<br> на всех этапах
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.22876 16.7712C11.3116 18.854 14.6884 18.854 16.7712 16.7712M17 10.3333H16.9867M9 10.3333H8.98667M0.999999 13C0.999999 19.6274 6.37258 25 13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 0.999999 6.37258 0.999999 13Z"
                                            stroke="#5747AF" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div class="small-grinding-sub-text">
                                    Cлужба поддержки всегда готова помочь и сопроводить на всех этапах обучения
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="main-text-highlight">
                <span style="color:#A2A5B2"> Мы верим, что каждый может найти <br> себе </span> <span
                    style="color:#525A7D"> занятие по душе и приобрести <br> новые впечатления </span>
            </div>

            <section>
                <div class="about-beliefs">
                    <div class="about-beliefs-small-cards">
                        <div class="about-beliefs-card">
                            <div class="belief-text-top">01</div>
                            <div class="belief-text-bottom">Поможем <br> вам определиться <br> с интересами</div>
                        </div>
                        <div class="about-beliefs-card">
                            <div class="belief-text-top">02</div>
                            <div class="belief-text-bottom">Покажем <br> разнообразие новых хобби и <br> увлечений</div>
                        </div>
                        <div class="about-beliefs-card">
                            <div class="belief-text-top">03</div>
                            <div class="belief-text-bottom">Познакомим с <br> наставниками и <br> составим траекторию
                                обучения</div>
                        </div>
                        <div class="about-beliefs-card">
                            <div class="belief-text-top">04</div>
                            <div class="belief-text-bottom">Превратим мечту в <br> цель с <br> конкретными шагами по
                                <br> её
                                <br> достижению
                            </div>
                        </div>
                    </div>
                    <div class="about-beliefs-photo">
                        <img src="https://storage.yandexcloud.net/testbucketplatforma34/pages/LandingMenteePage/team_work.jpeg"
                            alt="team-work">
                    </div>

                </div>
            </section>

            <section>
                <div class="mentors-section">
                    <div class="mentors-section-header-text">А с этим вам помогут наши
                        наставники</div>

                    <div class="mentors-row">
                        <div v-for="(mentor, index) in mentors" :key="mentor.id">
                            <div v-if="index == 1" id="middle-mentor" class="content-block mentor-card">
                                <link-to-mentor :mentorId="mentor.id">
                                    <div class="MentorAvatar">
                                        <div class="profile-container">
                                            <div class="profile-photo">
                                                <!-- <img src="https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200"
                                                :alt="alt" @error="handleImageError"> -->
                                                <img :src="mentor.mentor_avatar_url" :alt="alt"
                                                    @error="handleImageError">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mentor-info">
                                        <h3>{{ mentor.mentor_name + " " + mentor.mentor_surname }} </h3>
                                        <p>{{ mentor.mentor_tg_title }} </p>
                                        <div class="mentor-price">
                                            <span>от 11 000 ₽ за сессию</span>
                                            <span class="likes">
                                                <svg width="16" height="16" viewBox="0 0 24 24">
                                                    <path
                                                        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                                </svg>
                                                1666
                                            </span>
                                        </div>
                                    </div>
                                </link-to-mentor>
                            </div>
                            <div v-else class="content-block mentor-card">
                                <link-to-mentor :mentorId="mentor.id">
                                    <div class="MentorAvatar">
                                        <div class="profile-container">
                                            <div class="profile-photo">
                                                <!-- <img src="https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200"
                                            :alt="alt" @error="handleImageError"> -->
                                                <img :src="mentor.mentor_avatar_url" :alt="alt"
                                                    @error="handleImageError">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mentor-info">
                                        <h3>{{ mentor.mentor_name + " " + mentor.mentor_surname }} </h3>
                                        <p>{{ mentor.mentor_tg_title }} </p>
                                        <div class="mentor-price">
                                            <span>от 11 000 ₽ за сессию</span>
                                            <span class="likes">
                                                <svg width="16" height="16" viewBox="0 0 24 24">
                                                    <path
                                                        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                                </svg>
                                                1666
                                            </span>
                                        </div>
                                    </div>
                                </link-to-mentor>
                            </div>
                        </div>
                    </div>

                    <button @click="navigateToPage('/AllMentors')" class="choose-mentor-btn">Выбрать наставника</button>
                </div>
            </section>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { useRouter } from 'vue-router';
import { ref, onMounted, defineComponent } from 'vue';
import LandingLoadingAnimation from '@/components/Animations/LandingLoadingAnimation.vue';
import axios from 'axios';
import LinkToMentor from '@/components/Links/LinkToMentor.vue';
import LinkToEvent from '../components/Links/LinkToEvent.vue';

export default defineComponent({
    name: 'LandingMenteePage',
    components: {
        LandingLoadingAnimation,
        LinkToEvent,
        LinkToMentor,
    },
    setup() {
        const loading = ref(true);
        const router = useRouter();
        const navigateToPage = (route: string) => {
            router.push(route);
        };

        const masterclasses = ref<any[]>([]);

        const categories = [
            {
                title: 'Фитнес тренер',
                image: 'https://images.unsplash.com/photo-1571019614242-c5c5dee9f50b?w=300&h=200'
            },
            {
                title: 'Иллюстратор',
                image: 'https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?w=300&h=200'
            },
            {
                title: 'Музыкант',
                image: 'https://images.unsplash.com/photo-1511379938547-c1f69419868d?w=300&h=200'
            },
            {
                title: 'Тату-мастер',
                image: 'https://images.unsplash.com/photo-1598371839696-5c5bb00bdc28?w=300&h=200'
            }
        ]

        const mentors = ref<any[]>([]);

        const events = ref<any[]>([]);

        const eventsMentors = ref<any[]>([]);


        onMounted(async () => {
            try {
                loading.value = true;
                try {
                    const responseEvents = await axios.get('https://platforma34.tw1.ru/api/events/GetTopEvents/');
                    events.value = await responseEvents.data;
                    for (var some_event of events.value) {
                        const responseEventsMentor = await axios.get('https://platforma34.tw1.ru/api/users/GetUser/?id=' + some_event.event_id_mentor)
                        eventsMentors.value.push(responseEventsMentor.data)
                    }
                } catch (error) {
                    console.log("Error while fetching Events", error)
                } finally {
                    console.log(events.value);
                    console.log(events.value);
                    for (var idx = 0; idx < 3; idx++) {
                        masterclasses.value.push({
                            image: events.value[idx].event_imageUrl,
                            authorImage: eventsMentors.value[idx].mentor_avatar_url,
                            author: eventsMentors.value[idx].mentor_name + " " + eventsMentors.value[idx].mentor_surname,
                            title: events.value[idx].event_title,
                            date: '21.12',
                            likes: Math.floor(Math.random() * 1000),
                            id: events.value[idx].id,
                        })
                    }
                }
                const responseMentors = await axios.get('https://platforma34.tw1.ru/api/users/GetRandomUsers/');
                mentors.value = responseMentors.data;

            } catch (error) {
                console.log("Error while fetching data", error);
            } finally {
                setTimeout(() => {
                    loading.value = false; // Установить индикатор загрузки в false после задержки
                }, 500);
            }
        });

        return {
            navigateToPage,
            events,
            mentors,
            categories,
            masterclasses,
            loading
        };
    },
})


</script>

<style scoped>
.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    /* Чтобы индикатор покрывал весь экран */
    top: 0;
    left: 0;
    display: flex;
    /* Для центрирования содержимого */
    justify-content: center;
    /* Горизонтальное центрирование */
    align-items: center;
    /* Вертикальное центрирование */
    opacity: 1;
    z-index: 40;
    transition: opacity 0.5s;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
    /* Время анимации */
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active в версиях <2.1.8 */
    {
    opacity: 0;
    /* Начальное значение прозрачности */
}


.landing-page {
    margin: 0 auto;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    background: #F3F4F5;
    min-height: 100vh;
}

/* Общие стили секций */
.section {
    margin-bottom: 4rem;
}

/* Заголовок секции */
.section-title-text {
    font-size: 60px;
    font-weight: 600;
    line-height: 68px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #525A7D;
    padding: 0 12rem 2rem 12rem;
    padding-bottom: 2rem;
}

.content-block {
    background: white;
    border-radius: 20px;
}

.content-block2 {
    background: white;
    border-radius: 20px;
    padding: 2rem;
}

/* Hero Section */
.hero-section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "A B" "A B" "A C";
    gap: 30px;
    margin-bottom: 4rem;
}

.hero-block {
    grid-area: A;
}

.photo-card {
    grid-area: B;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    /* Добавлено для центрирования содержимого */
    align-items: center;
    /* Центрирование по вертикали */
    justify-content: center;
    /* Центрирование по горизонтали */
    /* Задана фиксированная высота */
    position: relative;
}

.profile2 {
    display: flex;
    /* Добавлено */
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.profile2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.stats-card {
    grid-area: C;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: white;
    border-radius: 20px;
    padding: 20px 24px;
}

.stats-svg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stats-text strong {
    display: block;
    color: #525A7D;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.stats-text p {
    color: #8F8D97;
    font-size: 1rem;
    margin: 0;
}

/* Hero Content */
.hero-content {
    font-size: 70px;
    font-weight: 600;
    color: #525A7D;
    padding: 100px 60px;
}

.hero-content p {
    color: #525A7D;
    margin-bottom: 2rem;
    font-weight: 400;
    line-height: 28px;
    font-size: 20px;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
}

.button-landing-all-mentors {
    background: #7B65F7;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    transition: background-color 0.3s;
}

.button-landing-all-mentors:hover {
    background: #705ce2;
}

.button-landing-more {
    background: transparent;
    color: #525A7D;
    padding: 0.8rem 1.5rem;
    border: 2px solid #525A7D;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
}

.button-landing-more:hover {
    background: #525A7D;
    color: white;
}

/* Masterclass Section */
.masterclass-section .section-title-text {
    padding: 0;
}

.masterclass-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 3rem 0rem;
}

.masterclass-card {
    background: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.masterclass-card:hover {
    transform: translateY(-5px);
}

.masterclass-card--featured {
    margin-top: -2rem;
    margin-bottom: 2rem;
}

.masterclass-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.masterclass-info {
    padding: 1.5rem;
}

.author {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.author-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.author span {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral900, #434966);

}

.masterclass-info h3 {

    margin-bottom: 1rem;
    font-size: 1.1rem;
    display: -webkit-box;
    /* Используем flexbox-структуру для текстового блока */
    -webkit-box-orient: vertical;
    /* Вертикальная ориентация flexbox */
    -webkit-line-clamp: 2;
    /* Ограничиваем до 3 строк */
    overflow: hidden;
    /* Обрезаем текст, если он выходит за пределы */
    text-overflow: ellipsis;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--Base-Base-Black, #2A2037);


}

.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #8F8D97;
    font-size: 0.9rem;
}

.heart-icon {
    width: 16px;
    height: 16px;
    fill: #7B65F7;
    margin-right: 4px;
    vertical-align: middle;
}

.btn-more {
    display: block;
    margin: 0 auto;
    background: #DCD8FC;
    color: #7B65F7;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s;
}

.btn-more:hover {
    background: #C5BFFA;
}

/* Categories Section */
.categories-section .categories-header {
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 60px;
}

.categories-text {
    font-size: 60px;
    color: #525A7D;
}

.categories-highlight {
    font-size: 60px;
    color: #7B65F7;
}

.categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.category-card {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 3 / 2;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.category-card:hover {
    transform: translateY(-5px);
}

.category-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.category-label {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: #525A7D;
    font-weight: 500;
    font-size: 1rem;
}

/* About Grinding Section */

.about-grinding {
    border-radius: 32px;
    background-color: #998BF8;
    color: #525A7D;
    padding: 32px;
}

.about-grinding-large {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 50px;
    margin-bottom: 50px;
}

.about-grinding-large-card {
    border-radius: 32px;
    background-color: white;
    display: flex;
}

.about-grinding-small {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.about-grinding-small-card {
    display: flex;
    flex-direction: column;
    color: #5747AF;
    font-size: 22px;
    font-weight: 400;
    justify-content: space-between;
    border-radius: 32px;
    background-color: white;
    padding: 20px;
}

.small-grinding-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.small-grinding-sub-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, #5747AF);
}

.backgound-text {
    size: 60px;
    color: #525A7D;
}

.about-beliefs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 135px;
}

.about-beliefs-small-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
}

.about-beliefs-card {
    border-radius: 20px;
    background-color: #998BF8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 22px;
    font-weight: 400;
    justify-content: space-between;
}

.about-beliefs-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: white;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.about-beliefs-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Responsive Design */
/* @media (max-width: 768px) {
    .hero-section {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "B" "A";
    }

    .stats-card {
        display: none;
    }

    .hero-buttons {
        flex-direction: column;
    }

    .btn-primary,
    .btn-secondary {
        width: 100%;
    }

    .masterclass-grid {
        grid-template-columns: 1fr;
    }

    .categories-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
} */

.mentors-section h2 {
    text-align: center;
    color: #525A7D;
    font-size: 32px;
    margin-bottom: 30px;
}

.mentors-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 30px;
}

.mentor-card {
    display: flex;
    flex-direction: column;
    gap: 15px;
    transition: transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mentor-image {
    width: 100px;
    height: 100px;
    background: #F3F4F5;
    border-radius: 50%;
    margin-bottom: 15px;
}

.mentor-card h3 {
    color: #525A7D;
    font-size: 18px;
}

.mentor-card p {
    color: #8F8D97;
    font-size: 14px;
}

.mentor-card:hover {
    transform: translateY(-5px);
}

.mentor-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #525A7D;
}

.likes {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #7B65F7;
}

.likes svg {
    fill: currentColor;
}

.choose-mentor-btn {
    display: block;
    margin: 0 auto;
    background: #7B65F7;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s;
}

.choose-mentor-btn:hover {
    background: #5747AF;
}



.grinding-text {
    display: flex;
    color: #5747AF;
    flex-direction: column;
    padding: 60px;
}

.grinding-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #6553CA;
    margin-bottom: 12px;
}

.grinding-sub-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, #5747AF);


}

.grinding-photo {
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* width: 60%; */
    height: 100%;
}

.grinding-photo2 {
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 60%;
    height: 100%;
}

.grinding-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
}

.grinding-photo2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
}

/* .grinding-photo2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
} */

.grinding-header {
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 60px;
}

.MentorAvatar {
    grid-area: A;
    background-color: #F6F6F6;
    border-radius: 0.5208vw;
    /* padding: 1.5vw; */
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    border: solid lightgray 1px;
    border-radius: 12px;
}

.profile-container {
    width: 100%;
    height: 100%;
    /* aspect-ratio: 2 / 1; */
    background: repeating-linear-gradient(45deg,
            #f0f0f0,
            #f0f0f0 10px,
            #e0e0e0 10px,
            #e0e0e0 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mentor-info {
    padding: 20px;
}

.mentor-info p {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral900, #434966);

}

.mentor-info h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--Base-Base-Black, #2A2037);
}


#middle-mentor {
    margin-top: -2rem;
    margin-bottom: 2rem;
}

.photos {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    width: 50%;
    position: relative;
}

.magic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 20px;
}

.main-text-highlight {
    margin-top: 200px;
    margin-bottom: 40px;
    font-size: 60px;
    font-weight: 600;
}

.mentors-section-header-text {
    margin-bottom: 60px;
    font-size: 60px;
    color: #525A7D;
}

@media (max-width: 1024px) {

    .hero-section {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "B" "A";
    }

    .stats-card {
        display: none;
    }

    .hero-buttons {
        flex-direction: column;
    }

    .btn-primary,
    .btn-secondary {
        width: 100%;
    }

    .masterclass-grid {
        grid-template-columns: 1fr;
    }

    .categories-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    /* .hero-section {
        grid-template-columns: 1fr;
    } */

    .hero-buttons {
        flex-direction: column;
    }

    .btn-primary,
    .btn-secondary {
        width: 100%;
    }

    .masterclass-grid {
        grid-template-columns: 1fr;
    }

    .categories-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .features-row,
    .steps-row {
        grid-template-columns: repeat(2, 1fr);
    }

    .content-row,
    .mentors-row {
        grid-template-columns: 1fr;
    }

    .features-row,
    .steps-row {
        grid-template-columns: 1fr;
    }

    .about-beliefs {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin-bottom: 135px;
        grid-template-areas: "D" "E";
    }

    .about-beliefs-photo {
        grid-area: "D";
    }

    .about-grinding-small {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .about-beliefs-small-cards {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        grid-area: "E";
    }

    .about-beliefs-card {
        border-radius: 20px;
        background-color: #998BF8;
        padding: 20px;
        display: flex;
        flex-direction: column;
        color: white;
        font-size: 22px;
        font-weight: 400;
        justify-content: space-between;
        height: 220px;
    }

    #middle-mentor {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .masterclass-card--featured {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .section-title-text {
        font-size: 40px;
    }

    .categories-section .categories-header {
        text-align: center;
        margin-bottom: 2rem;
        font-weight: 600;
        font-size: 40px;
    }

    .grinding-header {
        text-align: center;
        margin-bottom: 2rem;
        font-weight: 600;
        font-size: 40px;
    }

    .main-text-highlight {
        margin-top: 200px;
        margin-bottom: 40px;
        font-size: 40px;
        font-weight: 600;
    }

    .mentors-section-header-text {
        margin-bottom: 60px;
        font-size: 40px;
        font-weight: 600;
        color: #525A7D;
    }

    .content-block2 {
        border-radius: 20px;
        padding: 0px;
        background-color: #F3F4F5;
    }

    .hero-content {
        font-size: 40px;
        font-weight: 600;
        color: #525A7D;
        padding: 0px;
    }

    .about-grinding-large {
        display: grid;
        grid-template-columns: 1fr;
    }

    .about-grinding-large-card {
        border-radius: 32px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .grinding-text {
        padding: 20px;
    }

    .grinding-photo {
        width: 100%;
    }

    .grinding-photo2 {
        width: 100%;
    }

    .grinding-photo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 32px;
        border-top-right-radius: 0px;
    }

    .grinding-photo2 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-right-radius: 32px;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 0px;
    }
}
</style>