import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chats-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", null, "Чаты", -1),
    _createElementVNode("p", null, "Скоро здесь появятся чаты", -1),
    _createElementVNode("div", { class: "pad" }, null, -1)
  ])))
}