<template>
    <div class="event-card">
        <div class="left-column">
            <div class="event-image">
                <img :src="event.image" alt="Event photo">
            </div>
        </div>
        <div class="content">
            <div class="content-title">
                {{ event.event_name }}
            </div>
            <div class="EventMentor">
                <div class="event-mentor-image">
                    <img :src="event.mentor_image" alt="Mentor Image" />
                </div>
                <div class="MentorText">
                    <div class="MentorName">
                        {{ event.mentor_name }}
                    </div>
                    <div class="MentorPosition">
                        {{ event.mentor_position }}
                    </div>
                </div>
            </div>
            <hr class="platforma-line">
            <div class="event-additional-info">
                <div class="event-block-info">
                    <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 6V2M15 6V2M6 10H16M4 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20Z"
                            stroke="#525A7D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="date-info">
                        {{ event.event_date }}
                    </div>
                </div>
                <div class="event-block-info">
                    <svg width="24" height="24" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="toggleLike" :class="{ 'liked': isLiked }" class="like-button">
                        <path
                            d="M3.21761 3.63411C1.09413 5.75759 1.09413 9.20042 3.21761 11.3239L12.5001 20.6064L21.7824 11.3239C23.9059 9.20042 23.9059 5.75759 21.7824 3.63411C19.6589 1.51063 16.2161 1.51064 14.0926 3.63411L12.5001 5.22678L10.9074 3.63411C8.78392 1.51064 5.34108 1.51064 3.21761 3.63411Z"
                            stroke="#7B65F7" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="likes-info">
                        {{ event.likes }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';

const props = defineProps({
    event: {
        type: Object,
        required: true
    }
});

const isLiked = ref(false);

const toggleLike = () => {
    setTimeout(() => {
        isLiked.value = !isLiked.value;
    }, 500);
};
</script>


<!-- <script setup>
/* eslint-disable */
import { ref } from 'vue';

const props = defineProps({
    event: {
        type: Object,
        required: true
    }
});

const isLiked = ref(false);

const toggleLike = () => {
    isLiked.value = !isLiked.value;
};
</script> -->

<style scoped>
/* .event-card {
    position: relative;
    width: 820px;
    height: 280px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
} */

.event-card {
    position: relative;
    width: 820px;
    height: 250px;
    background: #FFFFFF;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
}

.left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
}

.content {
    flex: 1;
    padding: 20px;
}

.content-hero {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.name {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #2A2037;
    margin: 0;
}

.position {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #5747AF;
    margin: 10px 0 0;
}

.price {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2A2037;
    letter-spacing: 0.01em;
    text-align: right;
}

.divider {
    width: 100%;
    height: 1px;
    background: #D5D7DC;
    margin: 20px 0;
}

.description {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #525A7D;
    margin: 20px 0;
}

.like-button {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
    padding: 0;
}

.like-button path {
    transition: fill 0.5s ease;
}

.like-button:hover path {
    fill: #7B65F7;
}

.like-button.liked path {
    fill: #7B65F7;
}

/* Остальной ваш CSS */
.likes {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.like-count {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #7B65F7;
}

.likes-info {
    font-size: 24px;
    font-weight: 500;
    color: #7B65F7;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.tag {
    display: inline-flex;
    align-items: center;
    padding: 1px 8px 4px;
    background: #FFFFFF;
    border: 1px solid #A2A5B3;
    border-radius: 16px;
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #434966;
    letter-spacing: 0.01em;
}

.profile-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: 150px;
    height: 36px;
    background: #C8C2FB;
    border-radius: 8px;
    border: none;
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #473A8F;
    letter-spacing: 0.01em;
    cursor: pointer;
}

.profile-button:hover {
    background: #B8B0FA;
}

.event-image {
    width: 340px;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
}

.event-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-mentor-image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
}

.event-mentor-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.EventMentor {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.MentorText {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    /* align-items: center; */
}

.MentorName {
    color: #434966;
    font-weight: 600;
    font-size: 24px;
}

.MentorPosition {
    color: #3A4058;
    font-weight: 500;
    font-size: 18px;
}

.content-title {
    font-weight: 600;
    font-size: 28px;
    color: #2A2037;
    margin-bottom: 17px;
}

.event-additional-info {
    display: flex;
    justify-content: space-between;
}

.event-block-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.date-info {
    font-weight: 600;
    font-size: 24px;
    color: #525A7D;
}

.platforma-line {
    border: 1px solid #D5D7DC;
}

</style>