import { createRouter, createWebHistory } from 'vue-router'

import AllEvents from '../views/AllEvents.vue';
import AllEventsDiffShow from '../views/AllEventsDiffShow.vue';
import AllMentors from '../views/AllMentors.vue';
import MentorPage from '../views/MentorPage.vue';
import MentorPage2 from '../views/MentorPage2.vue';
import HomePage from '../views/HomePage.vue';
import LandingMenteePage from '../views/LandingMenteePage.vue';
import EventInfo from '../views/EventInfoPage.vue';
import RegisterPage from '../views/RegisterPage.vue';
import LoginPage from '../views/LoginPage.vue';
import EducationBlockDesktop from '../components/pages/mentor/EducationBlockDesktop.vue';
import TestPage from '../views/TestPage.vue';
import NewTest from '../views/NewTestPage.vue';
import SaluteJazzTest from '../views/SaluteJazzTest.vue';
import CobferencePage from '../views/ConferencePage.vue';
import ConferencePage from '../views/ConferencePage.vue';
import PersonalPage from '../views/PersonalPage.vue';
import PersonalPage2 from '../views/PersonalPage2.vue';
import MeetingPage from '@/views/MeetingPage.vue';
import UploadPage from '../views/UploadPage.vue';
import CalendarPage from '@/views/CalendarPage.vue';
import NewEventsPage from '@/views/NewEventsPage.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import CreateEventPage from '@/views/CreateEventPage.vue';
import ChatsPage from '@/views/ChatsPage.vue';
import JumpingDotLoaderWithShadows from '@/components/Animations/JumpingDotLoaderWithShadows.vue';
import LandingLoadingAnimation from '@/components/Animations/LandingLoadingAnimation.vue';

// import TestPage2 from '../views/TestPageTest2.vue';
// import HomeView from './HomeView.vue'
// import AboutView from './AboutView.vue'

const routes = [
    {
        path: '/Home',
        component: HomePage
    },
    //   { path: '/about', component: AboutView },
    {
        path: '/AllEvents',
        component: AllEvents,
        meta: { header: 'Search'},
    },
    {
        path: '/AllEventsDiffShow',
        component: AllEventsDiffShow
    },
    {
        path: '/AllMentors',
        component: AllMentors,
        meta: { header: 'Search'},
    },
    {
        path: '/',
        component: HomePage,
        meta: { header: 'Landing', },
    },
    {
        path: '/event_info/:id/',
        name: 'EventInfo',
        component: EventInfo,
        meta: { header: 'Search'},
    },
    {
        path: '/mentor_info/:mentorId',
        name: 'MentorInfo',
        component: MentorPage, 
        meta: { header: 'Search'},
    },
    {
        path: '/mentor_info2/:mentorId',
        name: 'MentorInfo2',
        component: MentorPage2
    },
    {
        path: '/register',
        component: RegisterPage,
        meta: { header: 'Search'},
    },
    {
        path: '/login',
        component: LoginPage,
        meta: { header: 'Search'},
    },
    {
        path: '/TestPage',
        component: TestPage,
    },
    {
        path: '/NewTest',
        component: NewTest
    },
    {
        path: '/ProfileSettings',
        component: ProfileSettings,
        meta: { header: 'Search'},
    },
    {
        path: '/NewEventsPage',
        component: NewEventsPage
    },
    {
        path: '/SaluteJazzTest',
        component: SaluteJazzTest
    },
    {
        path: '/ConferencePage',
        component: ConferencePage,
        meta: { requiresAuth: false }
    },
    {
        path: '/MeetingPage/:id/',
        component: MeetingPage
    },
    {
        path: '/PersonalPage',
        component: PersonalPage,
        meta: { header: 'Search'},
    },
    {
        path: '/PersonalPage2',
        component: PersonalPage2
    },
    {
        path: '/CalendarPage',
        component: CalendarPage,
        meta: { header: 'Search'},
    },
    {
        path: '/UpdatePage',
        component: UploadPage
    },
    {
        path: '/CreateEventPage',
        component: CreateEventPage,
        meta: { header: 'Search'},
    },
    {
        path: '/ChatsPage',
        component: ChatsPage,
        meta: { header: 'Search'},
    },
    {
        path: '/LandingMenteePage',
        component: LandingMenteePage,
        meta: { header: 'Landing', footer: 'Landing' },
    },
    {
        path: '/loads',
        component: LandingLoadingAnimation,
    },
    //
    //    { path: '/TestPage2', 
    //     component: TestPage2 
    // },
    {
        path: '/dashboard',
        component: () => import('../views/DashboardPage.vue'),
        meta: { requiresAuth: true }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(/* to, from, savedPosition */) {

        // if (savedPosition) {
        // If there's a saved scroll position (e.g., after using the browser's back button), use that.
        //   return savedPosition;
        // } else {
        // Otherwise, scroll to the top of the page.
        return { top: 0 };
        // }
    }
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('accessToken');
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next('/login');  // Если не авторизован, перенаправляем на страницу входа
    } else {
        next();  // В противном случае, разрешаем переход
    }
});

// const router = new VueRouter({
//     mode: history,
//     base: process.env.BASE_URL, 
//     routes,
// })

export default router



