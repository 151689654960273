import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "experience-block",
  ref: "blockRef"
}
const _hoisted_2 = { class: "experience-header" }
const _hoisted_3 = { class: "experience-header-text" }
const _hoisted_4 = { class: "items-count" }
const _hoisted_5 = { class: "date" }
const _hoisted_6 = { class: "position" }
const _hoisted_7 = { class: "company" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.items.length), 1)
    ]),
    _createElementVNode("div", {
      class: "experience-content",
      style: _normalizeStyle(_ctx.contentStyle),
      ref: "contentRef"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["experience-item", { 'grayed-out': !_ctx.isExpanded && index >= _ctx.initialItemsCount }]),
          ref_for: true,
          ref: "itemRefs"
        }, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(item.dateRange), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(item.position), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(item.company), 1)
        ], 2))
      }), 128))
    ], 4),
    (_ctx.items.length > _ctx.initialItemsCount)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpand && _ctx.toggleExpand(...args))),
          class: "show-more-btn"
        }, _toDisplayString(_ctx.isExpanded ? 'Скрыть' : 'Показать всё'), 1))
      : _createCommentVNode("", true)
  ], 512))
}