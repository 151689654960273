import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/images/landing/tormen.svg'


const _hoisted_1 = { class: "border" }
const _hoisted_2 = { class: "magics" }
const _hoisted_3 = { class: "magic" }
const _hoisted_4 = { class: "photos" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpacerModule16 = _resolveComponent("SpacerModule16")!
  const _component_Spacer8 = _resolveComponent("Spacer8")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"image\" data-v-0da18204><img src=\"" + _imports_0 + "\" alt=\"Top Left Image\" class=\"tormen-img\" data-v-0da18204></div><div class=\"txtleftcolumn\" data-v-0da18204><div class=\"title\" data-v-0da18204> Станьте наставником для увлеченных людей со всего мира </div><div class=\"text\" data-v-0da18204> Делитесь своими знаниями и зарабатывайте на этом. Проводите индивидуальные занятия или открытые мастер-классы, а мы найдем вам первых учеников и возьмем всю организационную работу на себя </div></div>", 2)),
    _createVNode(_component_SpacerModule16),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageURLs, (src, index) => {
            return (_openBlock(), _createElementBlock("img", {
              key: index,
              src: src,
              alt: 'Image ' + (index + 1),
              class: "overlay-image",
              style: _normalizeStyle(_ctx.getStyle(index))
            }, null, 12, _hoisted_5))
          }), 128))
        ]),
        _createElementVNode("div", null, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text1" }, " 2000+ наставников уже с нами ", -1)),
          _createVNode(_component_Spacer8),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text2" }, " Специалисты в разных сферах и с разным опытом ", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToPage('/register'))),
        class: "button1"
      }, " Стать наставником "),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "button2" }, " Подробнее ", -1))
    ])
  ]))
}