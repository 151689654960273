<template>
    <div>
        <transition name="fade" @before-leave="beforeLeave" @leave="leave">
            <div v-if="loading" class="loading">
                <JumpingDotLoaderWithShadows />
            </div>
        </transition>
        <div v-if="loading == false" id="events">
            <!-- <BackButtonVue /> -->
            <Spacer-32 />
            <!-- <div class="hero-page">
            <div class="search-container">
                <input type="text" placeholder="Поиск" class="search-input" v-model="searchQuery" />
            </div>
            <select v-model="minPrice" class="price-select">
                <option value="300">300 руб</option>
                <option value="500">500 руб</option>
                <option value="1000">1000 руб</option>
            </select>
            <select v-model="maxPrice" class="price-select">
                <option value="500">500 руб</option>
                <option value="1000">1000 руб</option>
                <option value="2000">2000 руб</option>
            </select>
            <div class="sorting">
                <select v-model="sortingOption" class="sorting-select">
                    <option value="">Без сортировки</option>
                    <option value="price-asc">По возрастанию цены</option>
                    <option value="price-desc">По убыванию цены</option>
                    <option value="popular">По популярности</option>
                </select>
            </div>
        </div> -->
            <!-- <CategoryFilter :categories="categories" :selectedCategory="selectedCategory"
            @category-selected="filterByCategory" :selectCategory="(category) => {
                this.selectedCategory = category
                this.$emit('category-selected', category);
                console.log(this.selectedCategory)
            }">
            <DropdownMenuVue label="Формат" :options="formats" @select="handleFormatSelect" />
        </CategoryFilter> -->
            <!-- 
        <Spacer-32 />
        <label>Event price</label>
        <input type="number" v-model="event_price" placeholder="Точно" />
        <input type="number" v-model="event_priceGt" placeholder="Больше чем" />
        <input type="number" v-model="event_priceLt" placeholder="Меньше чем" />

        <Spacer-32 />
        <label>Event Date (After)</label>
        <input type="date" v-model="event_dateAfter" />
        <label>Event Date (Before)</label>
        <input type="date" v-model="event_dateBefore" />

        <Spacer-32 />
        <button @click="applyFilters">Apply Filters</button>
-->


            <!-- <div class="card-list">
            <div v-for="event of events" :key="event.id">

                <link-to-event :id="event.id">

                    <router-link :to="{name: 'EventInfo', params: {id: event.id} }" style="text-decoration: none; color: inherit;">
                    <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                        :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                        :event_ageRestriction="event.event_ageRestriction"
                        :event_availiableSpots="event.event_availiableSpots" :event_totalSpots="event.event_totalSpots"
                        :event_category="event.event_category" :id="event.id">
                    </EventCardVue>
                </link-to-event>
                </router-link>
            </div>
        </div> -->
            <MobileFilters v-if="isMobile" :searchQuery="searchQuery" :categories="categories"
                :selectedCategories="selectedCategories" :minPrice="minPrice" :maxPrice="maxPrice"
                :sortingOption="sortingOption" :mentorSearchMobileWidth="mentorSearchMobileWidth"
                :isExpanded="isExpanded" :showIcon="showIcon" :hideIcon="hideIcon" :contentStyle="contentStyle"
                @update:searchQuery="searchQuery = $event" @toggle-category="toggleCategory"
                @update:minPrice="minPrice = $event" @update:maxPrice="maxPrice = $event"
                @update:sortingOption="sortingOption = $event" @toggle-expand="toggleExpand" ref="mobileFiltersRef" />
            <div class="masonry-container">
                <!-- Each column -->
                <div class="masonry-column" v-for="n in colNum" :key="n">
                    <div v-for="(event, index) in events.filter((_, i) => i % colNum === n - 1)" :key="index"
                        style="margin-bottom: 2rem;">

                        <!-- <link-to-event :id="45"> -->
                        <link-to-event :id="event.id">
                            <!-- <router-link :to="{name: 'EventInfo', params: {id: event.id} }" style="text-decoration: none; color: inherit;"> -->
                            <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                                :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                                :event_ageRestriction="event.event_ageRestriction"
                                :event_availiableSpots="event.event_availiableSpots"
                                :event_totalSpots="event.event_totalSpots" :event_category="event.event_category"
                                :id="event.id" :event_id_mentor="event.event_id_mentor">
                            </EventCardVue>
                        </link-to-event>
                        <!-- </router-link> -->
                    </div>
                </div>
            </div>
            <div>
                <!-- <h1> The end of page </h1> -->
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { ref, } from 'vue';
import EventCardVue from "../components/EventCard.vue";
// import CategoryFilter from '../components/CategoryFilter.vue';
import Spacer32 from '../components/Spacers/SpacerModule32.vue';
// import BackButtonVue from '../components/Navigations/BackButton.vue';
// import DropdownMenuVue from '../components/DropdownMenu.vue';
import LinkToEvent from "../components/Links/LinkToEvent.vue";
import axios from 'axios'
import JumpingDotLoaderWithShadows from "@/components/Animations/JumpingDotLoaderWithShadows.vue";
import MobileFilters from '@/components/filters/MobileFilters.vue';

export default {
    name: 'AllEvents',
    components: {
        // CategoryFilter,
        EventCardVue,
        Spacer32,
        // BackButtonVue,
        // DropdownMenuVue,
        LinkToEvent,
        JumpingDotLoaderWithShadows,
    },
    data() {
        return {
            formats: ['Формат 1', 'Формат 2', 'Формат 3'],
            categories: [
                'Все категории',
                'Здоровье',
                'Карьера',
                'Образование',
                'Красота',
                'Хобби',
                'Просвещение'
            ],
            selectedCategory: 'Все категории',
            events: [],
            currentPage: 1,
            hasNext: true,
            event_price: null,
            event_priceGt: null,
            event_priceLt: null,
            event_dateAfter: null,
            event_dateBefore: null,
            loading: ref(true),
            colNum: ref(3),
        };
    },
    methods: {
        updateMasonryWidth() {
            const masonryContainer = this.$refs.masonryContainer;
            if (masonryContainer) {
                this.masonryContainerWidth = masonryContainer.offsetWidth;
            }
        },
        // },
        // handleFormatSelect(option) {
        //     console.log('Selected format:', option);
        // },

        async applyFilters() {
            try {
                const params = {
                    event_price: this.event_price,
                    event_price_gt: this.event_priceGt,
                    event_price_lt: this.event_priceLt,
                    event_date_after: this.event_dateAfter,
                    event_date_before: this.event_dateBefore,
                };
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvents/', { params });
                console.log(response)
                this.events = response.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setTimeout(() => {
                    this.loading = false; // Установить индикатор загрузки в false после задержки
                }, 300);
            }
        },
        // async fetchEvents() {
        //   try {
        //     const url = 'http://platforma34.tw1.ru/api/events/GetEvents/'
        //     const response = await axios.get(url)
        //     const results = response.data.results
        //     this.posts = results.map(post => ({
        //       title: post.title,
        //       abstract: post.abstract,
        //       url: post.url,
        //       thumbnail: this.extractImage(post).url,
        //       caption: this.extractImage(post).caption,
        //       byline: post.byline,
        //       published_date: post.published_date,
        //     }))
        //   } catch (err) {
        //     if (err.response) {
        //       // client received an error response (5xx, 4xx)
        //       console.log("Server Error:", err)
        //     } else if (err.request) {
        //       // client never received a response, or request never left
        //       console.log("Network Error:", err)
        //     } else {
        //       console.log("Client Error:", err)
        //     }
        //   }
        // },

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        // async fetchData() {
        //   try {
        //     const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvents/');
        //     this.events = response.data;
        //     console.log(response.data)
        //     console.log(this.events)
        //   } catch (error) {
        //     console.error('Error fetching data:', error);
        //   }
        // },

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        getEvents() {
            console.log('Get Events')
            axios.get(`https://platforma34.tw1.ru/api/events/paginated_events/?page=${this.currentPage}`)
                .then(response => {
                    const data = response.data;
                    console.log(data);

                    this.hasNext = !!data.next; // Set hasNext based on whether there is a next page

                    // Push the new posts into the existing posts array
                    this.events.push(...data.results);
                })
                .catch(error => {
                    console.log(error);
                });

        },
        checkIfMobile() {
            if (window.innerWidth <= 1024) {
                this.colNum = 2;
            }
        },
        handleResize() {
            if (window.innerWidth <= 1024) {
                this.colNum = 2;
            } else {
                this.colNum = 3;
            }
        }

    },
    mounted() {
        this.checkIfMobile();
        this.applyFilters();
        window.onscroll = () => {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight

            if (bottomOfWindow && this.hasNext) {
                this.currentPage += 1
                this.getEvents()
            }
        };
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
/* html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

} */


.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    /* Чтобы индикатор покрывал весь экран */
    top: 0;
    left: 0;
    display: flex;
    /* Для центрирования содержимого */
    justify-content: center;
    /* Горизонтальное центрирование */
    align-items: center;
    /* Вертикальное центрирование */
    opacity: 1;
    transition: opacity 0.5s;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
    /* Время анимации */
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active в версиях <2.1.8 */
    {
    opacity: 0;
    /* Начальное значение прозрачности */
}


.card-list {
    display: grid;
    margin: 0;
    padding: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}

#events {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/*
.masonry-container {
    display: flex;
    gap: 3vw;
    margin-left: 3vw;
}
*/
/* Each column */
/* 
.masonry-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3vw;
    max-width: calc((100vw - 16vw - 2*4vw)/3);
}
*/

.masonry-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    margin-left: 2vw;
    margin-right: 2vw;
}

.masonry-column {
    flex: 0 0 calc(33.33% - 2vw);
    max-width: calc(33.33% - 2vw);
}

@media (max-width: 1024px) {
    .masonry-container {
        gap: 4vw;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .masonry-column {
        flex: 0 0 calc(50% - 2vw);
        max-width: calc(50% - 2vw);
    }

    .masonry-container>.masonry-column:nth-child(3) {
        display: none;
    }
}

.hero-page {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 2fr;
    gap: 20px;
    max-width: calc(470px * 3 + 6vw);
    margin-left: 3vw;
}

.price-select,
.sorting-select {
    width: 100%;
    padding: 8px;
    height: 100%;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    background: white;
    color: #434966;
}

.search-input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    font-size: 16px;
    background: white;
}
</style>
