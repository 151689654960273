<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="footer-main">
                <div class="logo">ФОРМА</div>

                <nav class="footer-nav">
                    <div class="nav-column">
                        <a href="/AllMentors" class="nav-link">Наставники</a>
                        <a href="/AllEvents" class="nav-link">Мероприятия</a>
                        <a href="#" class="nav-link">Поддержка</a>
                    </div>

                    <div class="nav-column">
                        <a href="/register" class="nav-link">Стать наставником</a>
                        <a href="#" class="nav-link">Раздел</a>
                        <a href="#" class="nav-link">Раздел</a>
                    </div>

                    <div class="nav-column">
                        <a href="#" class="nav-link">Раздел</a>
                        <a href="#" class="nav-link">Контакты</a>
                        <a href="#" class="nav-link">Вакансии</a>
                    </div>
                </nav>
            </div>

            <div class="footer-bottom">
                <span class="company">ИП ту-ту-ту</span>
                <a href="#" class="policy">Политика конфиденциальности</a>
                <a href="#" class="offer">Публичная оферта</a>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
</script>

<style scoped>
.footer {
    background: #8162e9;
    border-radius: 24px 24px 0 0;
    margin-left: 8rem;
    padding: 64px 0 32px;
    color: #FFFFFF;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 32px;
}

.footer-main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;
}

.logo {
    font-size: 24px;
    font-weight: 700;
    color: white;
}

.footer-nav {
    display: flex;
    gap: 120px;
}

.nav-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.nav-link {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
    transition: opacity 0.2s ease;
}

.nav-link:hover {
    opacity: 0.8;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.company {
    opacity: 0.7;
    color: white;
}

.policy,
.offer {
    color: #FFFFFF;
    text-decoration: none;
    transition: opacity 0.2s ease;
}

.policy:hover,
.offer:hover {
    opacity: 0.8;
}

@media (max-width: 1024px) {
    .footer-nav {
        gap: 60px;
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 40px 0 24px;
        margin-left: 0rem;

    }

    .footer-content {
        padding: 0 20px;
    }

    .footer-main {
        flex-direction: column;
        gap: 40px;
        margin-bottom: 60px;
    }

    .footer-nav {
        flex-direction: column;
        gap: 32px;
    }

    .footer-bottom {
        flex-direction: column;
        gap: 16px;
        text-align: center;
    }
}
</style>