import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading"
}
const _hoisted_2 = {
  key: 0,
  class: "mentors-content"
}
const _hoisted_3 = { class: "mentors-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JumpingDotLoaderWithShadows = _resolveComponent("JumpingDotLoaderWithShadows")!
  const _component_MobileFilters = _resolveComponent("MobileFilters")!
  const _component_SidebarFilters = _resolveComponent("SidebarFilters")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, {
      name: "fade",
      onBeforeLeave: _ctx.beforeLeave,
      onLeave: _ctx.leave
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_JumpingDotLoaderWithShadows)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onBeforeLeave", "onLeave"]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_MobileFilters, {
                key: 0,
                searchQuery: _ctx.searchQuery,
                categories: _ctx.categories,
                selectedCategories: _ctx.selectedCategories,
                minPrice: _ctx.minPrice,
                maxPrice: _ctx.maxPrice,
                sortingOption: _ctx.sortingOption,
                mentorSearchMobileWidth: _ctx.mentorSearchMobileWidth,
                isExpanded: _ctx.isExpanded,
                showIcon: _ctx.showIcon,
                hideIcon: _ctx.hideIcon,
                contentStyle: _ctx.contentStyle,
                "onUpdate:searchQuery": _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchQuery = $event)),
                onToggleCategory: _ctx.toggleCategory,
                "onUpdate:minPrice": _cache[1] || (_cache[1] = ($event: any) => (_ctx.minPrice = $event)),
                "onUpdate:maxPrice": _cache[2] || (_cache[2] = ($event: any) => (_ctx.maxPrice = $event)),
                "onUpdate:sortingOption": _cache[3] || (_cache[3] = ($event: any) => (_ctx.sortingOption = $event)),
                onToggleExpand: _ctx.toggleExpand,
                ref: "mobileFiltersRef"
              }, null, 8, ["searchQuery", "categories", "selectedCategories", "minPrice", "maxPrice", "sortingOption", "mentorSearchMobileWidth", "isExpanded", "showIcon", "hideIcon", "contentStyle", "onToggleCategory", "onToggleExpand"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mentors, (mentor) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), {
                key: mentor.id,
                mentor: mentor,
                onToggleLike: _ctx.toggleLike
              }, null, 40, ["mentor", "onToggleLike"]))
            }), 128))
          ]),
          (!_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_SidebarFilters, {
                key: 1,
                searchQuery: _ctx.searchQuery,
                categories: _ctx.categories,
                selectedCategories: _ctx.selectedCategories,
                minPrice: _ctx.minPrice,
                maxPrice: _ctx.maxPrice,
                sortingOption: _ctx.sortingOption,
                "onUpdate:searchQuery": _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchQuery = $event)),
                onToggleCategory: _ctx.toggleCategory,
                "onUpdate:minPrice": _cache[5] || (_cache[5] = ($event: any) => (_ctx.minPrice = $event)),
                "onUpdate:maxPrice": _cache[6] || (_cache[6] = ($event: any) => (_ctx.maxPrice = $event)),
                "onUpdate:sortingOption": _cache[7] || (_cache[7] = ($event: any) => (_ctx.sortingOption = $event))
              }, null, 8, ["searchQuery", "categories", "selectedCategories", "minPrice", "maxPrice", "sortingOption", "onToggleCategory"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}