import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "filters-sidebar" }
const _hoisted_2 = { class: "mentors-content-search-container" }
const _hoisted_3 = { class: "categories" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "price-filter" }
const _hoisted_6 = { class: "price-inputs" }
const _hoisted_7 = { class: "sorting" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "Поиск",
        class: "search-input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalSearchQuery) = $event))
      }, null, 512), [
        [_vModelText, _ctx.internalSearchQuery]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
        return (_openBlock(), _createElementBlock("button", {
          key: category,
          class: _normalizeClass(["category-tag", { active: _ctx.selectedCategories.includes(category) }]),
          onClick: ($event: any) => (_ctx.onToggleCategory(category))
        }, _toDisplayString(category), 11, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Стоимость", -1)),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalMinPrice) = $event)),
          class: "price-select"
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("option", { value: "300" }, "300 руб", -1),
          _createElementVNode("option", { value: "500" }, "500 руб", -1),
          _createElementVNode("option", { value: "1000" }, "1000 руб", -1)
        ]), 512), [
          [_vModelSelect, _ctx.internalMinPrice]
        ]),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalMaxPrice) = $event)),
          class: "price-select"
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("option", { value: "500" }, "500 руб", -1),
          _createElementVNode("option", { value: "1000" }, "1000 руб", -1),
          _createElementVNode("option", { value: "2000" }, "2000 руб", -1)
        ]), 512), [
          [_vModelSelect, _ctx.internalMaxPrice]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.internalSortingOption) = $event)),
        class: "sorting-select"
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("option", { value: "" }, "Без сортировки", -1),
        _createElementVNode("option", { value: "price-asc" }, "По возрастанию цены", -1),
        _createElementVNode("option", { value: "price-desc" }, "По убыванию цены", -1),
        _createElementVNode("option", { value: "popular" }, "По популярности", -1)
      ]), 512), [
        [_vModelSelect, _ctx.internalSortingOption]
      ])
    ])
  ]))
}