<template>
    <div>
        <div class="Review">
            <Spacer32 />
            <div class="ReviewContainer1">
                <div class="ReviewContainer2">
                    <div class="Title">Отзывы</div>
                    <Spacer8></Spacer8>
                    <div class="ReviewContainer3">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.78265 2.36469C10.0131 1.89775 10.1284 1.66428 10.2848 1.58969C10.4209 1.52479 10.5791 1.52479 10.7152 1.58969C10.8717 1.66428 10.9869 1.89775 11.2174 2.36469L13.4041 6.79466C13.4721 6.93251 13.5061 7.00144 13.5558 7.05495C13.5999 7.10233 13.6527 7.14073 13.7113 7.168C13.7776 7.1988 13.8536 7.20992 14.0057 7.23215L18.897 7.94708C19.4121 8.02237 19.6696 8.06001 19.7888 8.18581C19.8925 8.29527 19.9412 8.44567 19.9215 8.59515C19.8988 8.76696 19.7124 8.94856 19.3395 9.31176L15.8014 12.7578C15.6912 12.8652 15.636 12.9189 15.6004 12.9828C15.5689 13.0394 15.5487 13.1016 15.5409 13.1659C15.5321 13.2385 15.5451 13.3144 15.5711 13.4661L16.406 18.3335C16.494 18.8469 16.538 19.1036 16.4553 19.2559C16.3833 19.3884 16.2554 19.4814 16.1071 19.5089C15.9366 19.5404 15.7061 19.4192 15.2451 19.1768L10.8724 16.8772C10.7361 16.8056 10.668 16.7697 10.5962 16.7557C10.5327 16.7432 10.4673 16.7432 10.4038 16.7557C10.332 16.7697 10.2639 16.8056 10.1277 16.8772L5.75492 19.1768C5.29392 19.4192 5.06341 19.5404 4.89297 19.5089C4.74468 19.4814 4.61672 19.3884 4.54474 19.2559C4.462 19.1036 4.50603 18.8469 4.59407 18.3335L5.42889 13.4661C5.45491 13.3144 5.46793 13.2385 5.45912 13.1659C5.45132 13.1016 5.43111 13.0394 5.39961 12.9828C5.36402 12.9189 5.30888 12.8652 5.19859 12.7578L1.66056 9.31176C1.28766 8.94856 1.10121 8.76696 1.07853 8.59515C1.05879 8.44567 1.10755 8.29527 1.21125 8.18581C1.33044 8.06001 1.58797 8.02237 2.10304 7.94708L6.99431 7.23215C7.14642 7.20992 7.22248 7.1988 7.28872 7.168C7.34736 7.14073 7.40016 7.10233 7.44419 7.05495C7.49391 7.00144 7.52793 6.93251 7.59597 6.79466L9.78265 2.36469Z"
                                stroke="#454152" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="ReviewRating">4.96</div>
                        <div class="ReviewButtonLink"> {{ reviews.length }} {{ plural(reviews.length, ['отзыв',
                            'отзыва',
                            'отзывов']) }}</div>
                        <!-- <div class="ReviewButtonLink"> {{ reviews.length }} отзывов</div> -->
                        <!-- {{ reviews.length|plural(['квартира доступна', 'квартиры доступны', 'квартир доступно']) }} -->
                        <!-- <span :class="$style.blue">&nbsp;</span> -->
                    </div>
                </div>
                <div class="ReviewButtons">
                    <button class="ReviewLeftArrowButton" @click="swiper && swiper.slidePrev()">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1.41113" width="31" height="31" rx="15.5" fill="white" />
                            <rect x="1" y="1.41113" width="31" height="31" rx="15.5" stroke="#DAD9DC" />
                            <path d="M19 21.9111L14 16.9111L19 11.9111" stroke="#454152" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>

                    <button class="ReviewRightArrowButton" @click="swiper && swiper.slideNext()">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1.41113" width="31" height="31" rx="15.5" fill="white" />
                            <rect x="1" y="1.41113" width="31" height="31" rx="15.5" stroke="#DAD9DC" />
                            <path d="M14 21.9111L19 16.9111L14 11.9111" stroke="#454152" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
            <Spacer16></Spacer16>
            <Swiper style = "width:calc(100vw-180px)" :slidesPerView="slidesPerView" :spaceBetween="spaceBetween" :breakpoints="swiperBreakpoints"
                @swiper="setSwiper">
                <SwiperSlide v-for="review in reviews" :key="review.id">
                    <ReviewItem :review="review" />
                </SwiperSlide>
            </Swiper>
            <!-- <Swiper :slidesPerView="3" :spaceBetween="30" @swiper="setSwiper">
                <SwiperSlide v-for="review in reviews" :key="review.id">
                    <ReviewItem :review="review" />
                </SwiperSlide>
            </Swiper> -->
            <Spacer-16 />
            <div class="Button" @click="showPopup">Оставить отзыв</div>
        </div>
        <div id="overlay"></div>
        <div id="popup">
            <div class="reviewField">
                <div class="closePopup" @click="closePopup">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 2L2 22M2 2L22 22" stroke="grey" stroke-width="3" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <form @submit.prevent="submitReview">
                    <div class="form-group">
                        <h3>Напишите свой отзыв здесь</h3>
                        <textarea v-model="form.review_text" @input="checkMaxLength" id="review_text"
                            class="form-control" maxlength="1000" required></textarea>
                        <p>{{ remainingChars }} characters remaining</p>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit Review</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import Spacer32 from '../Spacers/SpacerModule32.vue';
import Spacer16 from '../Spacers/SpacerModule16.vue';
import Spacer8 from '../Spacers/SpacerModule8.vue';
import ReviewItem from './ReviewItem.vue';
import axios from 'axios';

export default {
    name: 'ReviewBlock',
    components: {
        ReviewItem,
        Spacer32,
        Spacer16,
        Spacer8,
        Swiper,
        SwiperSlide,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        instance: {
            type: String,
            required: true,
        },
        reviews: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            swiper: null,
            form: {
                review_text: localStorage.getItem('review_text') || '', // Load saved review from localStorage
                review_stars: 5,
                review_date: '20 августа 2024'
            },
            maxChars: 1000,
            // ... ваши существующие данные
            slidesPerView: 3,
            spaceBetween: 30,
            swiperBreakpoints: {
                // Экран шире 768px - показываем 3 слайда
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                // Экран уже 768px - показываем 1 слайд
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
        };
    },
    methods: {
        checkMaxLength() {
            if (this.form.review_text.length > this.maxChars) {
                this.form.review_text = this.form.review_text.slice(0, this.maxChars);
            }
        },
        submitReview() {
            console.log("submit happend")
            console.log("it is form", this.form)
            localStorage.setItem('review_text', this.form.review_text);
            console.log("it is instance", this.instance)
            if (this.instance == "mentor") {
                console.log("it is mentor")
                console.log("it is form", this.form)
                this.form.to_user_id = String(this.id)
                axios.post('https://platforma34.tw1.ru/api/user_reviews/AddUserReview/', this.form)
                    .then(() => {
                        alert('Review submitted successfully!');
                    })
                    .catch(error => {
                        console.error(error);
                        alert('Failed to submit the review.');
                    });
            } else if (this.instance == "event") {
                console.log("it is event")
                console.log("it is form", this.form)
                this.form.event_id = String(this.id)
                axios.post('https://platforma34.tw1.ru/api/event_reviews/AddEventReview/', this.form)
                    .then(() => {
                        alert('Review submitted successfully!');
                    })
                    .catch(error => {
                        console.error(error);
                        alert('Failed to submit the review.');
                    });
            }
        },
        showPopup() {
            document.getElementById('overlay').style.display = 'block';
            document.getElementById('popup').style.display = 'block';
        },
        closePopup() {
            document.getElementById('overlay').style.display = 'none';
            document.getElementById('popup').style.display = 'none';
        },
        setSwiper(swiperInstance) {
            this.swiper = swiperInstance;
        },
    },
    computed: {
        remainingChars() {
            return this.maxChars - this.form.review_text.length;
        },
        plural() {
            return (num, postfixes) => {
                if (isNaN(num)) {
                    return '';
                }
                const cases = [2, 0, 1, 1, 1, 2];
                return postfixes[
                    num % 100 > 4 && num % 100 < 20 ? 2 : cases[Math.min(num % 10, 5)]
                ];
            };
        },
    }
};
</script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
}

.ReviewList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}

.ReviewContainer {}

.ReviewRating {
    margin-left: 0.2vw;
    margin-right: 0.5vw;
    color: #454152;
    font-size: 1.2vw;
    font-weight: 300;
}

.Title {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A1C26;
}

.ReviewButtonLink {
    border: 1px solid #454152;
    border-radius: 1vw;
    padding: 4px 8px 4px 8px;
    text-align: center;
    font-weight: 300;
}

.ReviewContainer1 {
    display: flex;
    justify-content: space-between;
}

.ReviewContainer2 {
    display: flex;
    flex-direction: column;
    /* width: 8vw; */
}

.ReviewContainer3 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SmallInfoText {
    color: #454152;
    font-size: 1vw;
    font-weight: 300;
}

.BigInfoText {
    font-size: 2.4vw;
    font-weight: 500;
    /* height: 25px; */
    color: #454152;
    text-align: left;
}

.ReviewButtons {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.ReviewLeftArrowButton,
.ReviewRightArrowButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.ReviewLeftArrowButton svg,
.ReviewRightArrowButton svg {
    width: 33px;
    height: 33px;
}

.Button {
    width: 6rem;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    padding: 6px 24px 8px 24px;
    background: white;
    border-radius: 20px;
    border: 1px solid #DAD9DC;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    /* Длительность увеличена до 0.5s и плавный переход */

}

.Button:hover {
    background: #454152;
    color: white;
    cursor: pointer;
    transform: scale(1.02);
    /* Небольшое увеличение карточки при наведении */
    opacity: 0.95;
    /* Изменение прозрачности */
    transition-delay: 0.01s;
    /* Задержка перед началом анимации */
}

#overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9998;
}

#popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    text-align: center;
    border-radius: 8px;
    max-width: 300px;
    width: 100%;
    height: 500px;
}

.form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    height: 100%;
    text-align: start;
    vertical-align: top;
    resize: none;
}

.closePopup {
    display: flex;
    justify-content: end;
}

.btn {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.btn:hover {
    background-color: #0056b3;
}

p {
    font-size: 12px;
    color: grey;
}

@media (max-width: 767px) {
    .ReviewContainer1 {
        flex-direction: column;
        align-items: flex-start;
    }

    .ReviewButtons {
        justify-content: center;
        margin-top: 16px;
    }

    .ReviewButtonLink {
        margin-top: 8px;
    }

}
</style>
