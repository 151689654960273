<template>
    <footer class="footer">
        <div class="footer__container">
            <!-- Left Section - Form -->
            <div class="footer__form">
                <form @submit.prevent="handleSubmit">
                    <div class="form-group">
                        <label for="name">Имя</label>
                        <input type="text" id="name" v-model="formData.name" placeholder="Сергей Мейерхольд">
                    </div>

                    <div class="form-group">
                        <label for="phone">Телефон</label>
                        <div class="phone-input">
                            <div class="country-select">
                                <svg width="24" height="16" viewBox="0 0 24 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_56093_10162)">
                                        <path
                                            d="M23.1538 13.5377C23.1538 14.1906 22.8945 14.8167 22.4329 15.2783C21.9713 15.7399 21.3451 15.9992 20.6923 15.9992H3.46154C2.8087 15.9992 2.1826 15.7399 1.72097 15.2783C1.25934 14.8167 1 14.1906 1 13.5377V11.0762H23.1538V13.5377Z"
                                            fill="#CE2028" />
                                        <path d="M1 4.92383H23.1538V11.0777H1V4.92383Z" fill="#123A9F" />
                                        <path
                                            d="M20.6923 0H3.46154C2.8087 0 2.1826 0.25934 1.72097 0.720968C1.25934 1.1826 1 1.8087 1 2.46154L1 4.92308H23.1538V2.46154C23.1538 1.8087 22.8945 1.1826 22.4329 0.720968C21.9713 0.25934 21.3451 0 20.6923 0Z"
                                            fill="#E9E9E9" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_56093_10162">
                                            <rect width="24" height="16" rx="1" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>


                                <span class="chevron">▼</span>
                            </div>
                            <input type="tel" id="phone" v-model="formData.phone" placeholder="+78005553535">
                        </div>
                    </div>

                    <div class="checkbox-group">
                        <input type="checkbox" id="privacy" v-model="formData.privacyAccepted">
                        <label for="privacy">
                            Я принимаю политику конфиденциальности
                        </label>
                    </div>

                    <button type="submit" class="submit-button" :disabled="!formData.privacyAccepted">
                        Задать вопрос
                    </button>
                </form>
            </div>

            <!-- Right Section - Content -->
            <div class="footer__content">
                <div class="questions-section">
                    <h2>Остались вопросы?</h2>
                    <p>
                        Оставьте заявку. Дождитесь нашего звонка — позвоним с 8:00 до 22:00. Задайте вопросы, а мы на
                        них ответим и поможем подобрать занятия.
                    </p>
                </div>

                <div class="footer__links">
                    <div class="links-column">
                        <a href="#">Наставники</a>
                        <a href="#">Мероприятия</a>
                        <a href="#">Поддержка</a>
                    </div>
                    <div class="links-column">
                        <a href="#">Стать наставником</a>
                        <a href="#">Раздел</a>
                        <a href="#">Раздел</a>
                    </div>
                    <div class="links-column">
                        <a href="#">Раздел</a>
                        <a href="#">Контакты</a>
                        <a href="#">Вакансии</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Bottom Section -->
        <div class="footer__bottom">
            <div class="logo">ФОРМА</div>
            <div class="bottom-links">
                <a href="#">Политика конфиденциальности</a>
                <a href="#">Публичная оферта</a>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { reactive } from 'vue'

const formData = reactive({
    name: '',
    phone: '',
    privacyAccepted: false
})

const handleSubmit = () => {
    console.log('Form submitted:', formData)
}
</script>

<style scoped>
.footer {
    background-color: #7B65F7;
    padding: 64px 0 32px;
    color: #FFFFFF;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.footer__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 64px;
}

.footer__form {
    background: #FFFFFF;
    padding: 32px;
    border-radius: 16px;
}

.form-group {
    margin-bottom: 24px;
}

.form-group label {
    display: block;
    color: #454152;
    margin-bottom: 8px;
    font-size: 16px;
}

input[type="text"],
input[type="tel"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    font-size: 16px;
}

.phone-input {
    display: flex;
    align-items: center;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    overflow: hidden;
}

.country-select {
    display: flex;
    align-items: center;
    padding: 12px;
    border-right: 1px solid #D5D7DC;
    cursor: pointer;
}

.flag-icon {
    width: 24px;
    height: 16px;
    margin-right: 8px;
}

.chevron {
    font-size: 12px;
    color: #8F8D97;
    padding: 4px;
}

.phone-input input {
    border: none;
    flex: 1;
}

.checkbox-group {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    color: #454152;
}

.submit-button {
    width: 100%;
    padding: 16px;
    background-color: #7B65F7;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:disabled {
    background-color: #D5D7DC;
    cursor: not-allowed;
}

.questions-section {
    margin-bottom: 48px;
}

.questions-section h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 24px;
}

.questions-section p {
    font-size: 18px;
    line-height: 1.5;
}

.footer__links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}

.links-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.links-column a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
    transition: opacity 0.3s;
}

.links-column a:hover {
    opacity: 0.8;
}

.footer__bottom {
    max-width: 1200px;
    margin: 64px auto 0;
    padding: 32px 24px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.bottom-links {
    display: flex;
    gap: 32px;
}

.bottom-links a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 14px;
    transition: opacity 0.3s;
}

.bottom-links a:hover {
    opacity: 0.8;
}

@media (max-width: 1024px) {
    .footer__container {
        grid-template-columns: 1fr;
        gap: 48px;
    }
}

@media (max-width: 768px) {
    .footer__links {
        grid-template-columns: 1fr 1fr;
    }

    .footer__bottom {
        flex-direction: column;
        gap: 24px;
        text-align: center;
    }

    .bottom-links {
        flex-direction: column;
        gap: 16px;
    }
}
</style>