import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "auth-wrapper" }
const _hoisted_2 = {
  key: "registration",
  class: "auth-content"
}
const _hoisted_3 = { class: "social-login" }
const _hoisted_4 = { class: "social-buttons" }
const _hoisted_5 = {
  key: "verification",
  class: "auth-content"
}
const _hoisted_6 = { class: "auth-description" }
const _hoisted_7 = { class: "otp-input-wrapper" }

import { ref, onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import type { VForm } from 'vuetify/components'


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPage',
  setup(__props) {

/* eslint-disable */
const activeTab = ref('sms')
const phone = ref('')
const email = ref('')
const otpCode = ref('')
const privacyAccepted = ref(false)
const rememberMe = ref(false)
const showVerification = ref(false)
const loading = ref(false)
const resendTimer = ref(30)
const emailForm = ref()
const smsForm =ref()
const phoneRules = [
    (v: string) => !!v || 'Телефон обязателен',
    (v: string) => /^\+7\d{10}$/.test(v) || 'Неверный формат телефона'
]
const emailRules = [
    (v: string) => !!v || 'Email обязателен',
    (v: string) => /.+@.+\..+/.test(v) || 'Неверный формат email'
]

const socialIcons = [
    { icon: 'mdi-vk', color: '#4C75A3' },
    { icon: 'mdi-telegram', color: '#0088cc' },
    { icon: 'mdi-google', color: '#DB4437' }
]

let timerInterval: number | null = null

const handleSubmit = async () => {
    const { valid } = await (activeTab.value === 'sms' ? smsForm.value : emailForm.value)?.validate()

    if (valid) {
        loading.value = true
        try {
            const response = await axios.post('https://platforma34.tw1.ru/api/users/register/', {
                username: activeTab.value === 'sms' ? phone.value : email.value,
                password: 'password143',
                // email: email.value,
            })
            loading.value = false
            showVerification.value = true
            startResendTimer()
        } catch (error) {
            loading.value = false
            console.error(error)
        }
    }
}

const handleOtpComplete = async (code: string) => {
    try {
        const response = await axios.post('https://platforma34.tw1.ru/api/verify-passcode/VerifyMail/', {
            passcode: code,
            email: email.value
        })
        console.log('Verification successful:', response.data)
    } catch (error) {
        console.error('Verification failed:', error)
    }
}

const startResendTimer = () => {
    resendTimer.value = 30
    if (timerInterval) clearInterval(timerInterval)
    timerInterval = setInterval(() => {
        if (resendTimer.value > 0) {
            resendTimer.value--
        } else {
            clearInterval(timerInterval)
        }
    }, 1000)
}

const resendCode = () => {
    startResendTimer()
    // Реализовать логику повторной отправки кода
}

return (_ctx: any,_cache: any) => {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_otp_input = _resolveComponent("v-otp-input")!
  const _component_v_fade_transition = _resolveComponent("v-fade-transition")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, {
      class: "auth-card",
      elevation: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_fade_transition, { mode: "out-in" }, {
          default: _withCtx(() => [
            (!showVerification.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _cache[17] || (_cache[17] = _createElementVNode("h1", { class: "auth-title" }, "Станьте частью сообщества!", -1)),
                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "auth-description" }, " Получите доступ к нашему экспертному сообществу наставников по дизайну, продукту, ИИ, технологий и многим другим во всем мире. ", -1)),
                  _createVNode(_component_v_tabs, {
                    modelValue: activeTab.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeTab).value = $event)),
                    color: "#7b65f7",
                    grow: "",
                    class: "mb-6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_tab, { value: "sms" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("По СМС")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tab, { value: "email" }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("По почте")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_v_window, {
                    modelValue: activeTab.value,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((activeTab).value = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_window_item, { value: "sms" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_form, {
                            onSubmit: _withModifiers(handleSubmit, ["prevent"]),
                            ref_key: "smsForm",
                            ref: smsForm
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: phone.value,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((phone).value = $event)),
                                rules: phoneRules,
                                label: "Телефон",
                                "prepend-inner-icon": "mdi-phone",
                                variant: "outlined",
                                class: "mb-4",
                                "hide-details": "auto"
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_v_checkbox, {
                                modelValue: privacyAccepted.value,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((privacyAccepted).value = $event)),
                                rules: [(v) => !!v || 'Необходимо принять политику'],
                                color: "#7b65f7",
                                class: "mb-2",
                                "hide-details": ""
                              }, {
                                label: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createElementVNode("span", null, "Я принимаю ", -1),
                                  _createElementVNode("a", {
                                    href: "#",
                                    class: "privacy-link"
                                  }, "политику конфиденциальности", -1)
                                ])),
                                _: 1
                              }, 8, ["modelValue", "rules"]),
                              _createVNode(_component_v_checkbox, {
                                modelValue: rememberMe.value,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((rememberMe).value = $event)),
                                label: "Запомнить меня на этом устройстве",
                                color: "#7b65f7",
                                class: "mb-6",
                                "hide-details": ""
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_v_btn, {
                                block: "",
                                color: "#7b65f7",
                                size: "large",
                                type: "submit",
                                class: "white--text mb-8",
                                loading: loading.value,
                                height: "56"
                              }, {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createTextVNode(" Далее ")
                                ])),
                                _: 1
                              }, 8, ["loading"])
                            ]),
                            _: 1
                          }, 512)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_window_item, { value: "email" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_form, {
                            onSubmit: _withModifiers(handleSubmit, ["prevent"]),
                            ref_key: "emailForm",
                            ref: emailForm
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: email.value,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((email).value = $event)),
                                rules: emailRules,
                                label: "Почта",
                                "prepend-inner-icon": "mdi-email",
                                variant: "outlined",
                                class: "mb-4",
                                "hide-details": "auto",
                                placeholder: "sergey@mail.ru"
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_v_checkbox, {
                                modelValue: privacyAccepted.value,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((privacyAccepted).value = $event)),
                                rules: [(v) => !!v || 'Необходимо принять политику'],
                                color: "#7b65f7",
                                class: "mb-2",
                                "hide-details": ""
                              }, {
                                label: _withCtx(() => _cache[14] || (_cache[14] = [
                                  _createElementVNode("span", null, "Я принимаю ", -1),
                                  _createElementVNode("a", {
                                    href: "#",
                                    class: "privacy-link"
                                  }, "политику конфиденциальности", -1)
                                ])),
                                _: 1
                              }, 8, ["modelValue", "rules"]),
                              _createVNode(_component_v_checkbox, {
                                modelValue: rememberMe.value,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((rememberMe).value = $event)),
                                label: "Запомнить меня на этом устройстве",
                                color: "#7b65f7",
                                class: "mb-6",
                                "hide-details": ""
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_v_btn, {
                                block: "",
                                color: "#7b65f7",
                                size: "large",
                                type: "submit",
                                class: "white--text mb-8",
                                loading: loading.value,
                                height: "56"
                              }, {
                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                  _createTextVNode(" Далее ")
                                ])),
                                _: 1
                              }, 8, ["loading"])
                            ]),
                            _: 1
                          }, 512)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_3, [
                    _cache[16] || (_cache[16] = _createElementVNode("p", { class: "social-login-text" }, "Продолжить с помощью соцсетей", -1)),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(socialIcons, (icon, index) => {
                        return _createVNode(_component_v_btn, {
                          key: index,
                          icon: "",
                          variant: "outlined",
                          class: "social-button"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(icon.icon), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      }), 64))
                    ])
                  ]),
                  _cache[19] || (_cache[19] = _createElementVNode("div", { class: "login-link" }, [
                    _createElementVNode("span", null, "Уже есть аккаунт? "),
                    _createElementVNode("a", {
                      href: "#",
                      class: "login-link-text"
                    }, "Войти")
                  ], -1))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[21] || (_cache[21] = _createElementVNode("h1", { class: "auth-title" }, "Введите код из смс", -1)),
                  _createElementVNode("p", _hoisted_6, "Мы отправили его на " + _toDisplayString(phone.value), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_v_otp_input, {
                      modelValue: otpCode.value,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((otpCode).value = $event)),
                      length: 4,
                      variant: "underlined",
                      onFinish: handleOtpComplete
                    }, null, 8, ["modelValue"])
                  ]),
                  _createVNode(_component_v_btn, {
                    block: "",
                    variant: "text",
                    disabled: resendTimer.value > 0,
                    class: "mb-4",
                    onClick: resendCode
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Отправить код через " + _toDisplayString(resendTimer.value) + " сек. ", 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_v_btn, {
                    block: "",
                    variant: "text",
                    color: "#7b65f7",
                    class: "mb-8",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (showVerification.value = false))
                  }, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode(" Войти с другим номером ")
                    ])),
                    _: 1
                  }),
                  _cache[22] || (_cache[22] = _createElementVNode("div", { class: "login-link" }, [
                    _createElementVNode("span", null, "Уже есть аккаунт? "),
                    _createElementVNode("a", {
                      href: "#",
                      class: "login-link-text"
                    }, "Войти")
                  ], -1))
                ]))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})