<!-- src/components/filters/SidebarFilters.vue -->
<template>
  <aside class="filters-sidebar">
    <div class="mentors-content-search-container">
      <input
        type="text"
        placeholder="Поиск"
        class="search-input"
        v-model="internalSearchQuery"
      />
    </div>

    <div class="categories">
      <button
        v-for="category in categories"
        :key="category"
        class="category-tag"
        :class="{ active: selectedCategories.includes(category) }"
        @click="onToggleCategory(category)"
      >
        {{ category }}
      </button>
    </div>

    <div class="price-filter">
      <h3>Стоимость</h3>
      <div class="price-inputs">
        <select v-model="internalMinPrice" class="price-select">
          <option value="300">300 руб</option>
          <option value="500">500 руб</option>
          <option value="1000">1000 руб</option>
        </select>
        <select v-model="internalMaxPrice" class="price-select">
          <option value="500">500 руб</option>
          <option value="1000">1000 руб</option>
          <option value="2000">2000 руб</option>
        </select>
      </div>
    </div>

    <div class="sorting">
      <select v-model="internalSortingOption" class="sorting-select">
        <option value="">Без сортировки</option>
        <option value="price-asc">По возрастанию цены</option>
        <option value="price-desc">По убыванию цены</option>
        <option value="popular">По популярности</option>
      </select>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';

export default defineComponent({
  name: 'SidebarFilters',
  props: {
    searchQuery: {
      type: String,
      required: true
    },
    categories: {
      type: Array as PropType<string[]>,
      required: true
    },
    selectedCategories: {
      type: Array as PropType<string[]>,
      required: true
    },
    minPrice: {
      type: String,
      required: true
    },
    maxPrice: {
      type: String,
      required: true
    },
    sortingOption: {
      type: String,
      required: true
    }
  },
  emits: [
    'update:searchQuery',
    'toggle-category',
    'update:minPrice',
    'update:maxPrice',
    'update:sortingOption'
  ],
  setup(props, { emit }) {
    // Внутренние вычисляемые свойства для управления v-model
    const internalSearchQuery = computed({
      get: () => props.searchQuery,
      set: (value: string) => emit('update:searchQuery', value)
    });

    const internalMinPrice = computed({
      get: () => props.minPrice,
      set: (value: string) => emit('update:minPrice', value)
    });

    const internalMaxPrice = computed({
      get: () => props.maxPrice,
      set: (value: string) => emit('update:maxPrice', value)
    });

    const internalSortingOption = computed({
      get: () => props.sortingOption,
      set: (value: string) => emit('update:sortingOption', value)
    });

    const onToggleCategory = (category: string) => {
      emit('toggle-category', category);
    };

    return {
      internalSearchQuery,
      internalMinPrice,
      internalMaxPrice,
      internalSortingOption,
      onToggleCategory
    };
  }
});
</script>

<style scoped>
.filters-sidebar {
  flex: 3;
  width: 300px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 80px;
  height: 350px;
  overflow-y: auto;
}

.mentors-content-search-container {
  margin-bottom: 0px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d5d7dc;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}

.category-tag {
  padding: 6px 12px;
  border: 1px solid #d5d7dc;
  border-radius: 20px;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #434966;
}

.category-tag.active {
  background: #7b65f7;
  color: white;
  border-color: #7b65f7;
}

.price-filter {
  margin-bottom: 30px;
}

.price-filter h3 {
  font-size: 18px;
  color: #2a2037;
  margin-bottom: 15px;
}

.price-inputs {
  display: flex;
  gap: 10px;
}

.price-select,
.sorting-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #d5d7dc;
  border-radius: 8px;
  background: white;
  color: #434966;
}

.sorting {
  margin-top: 20px;
}
</style>
