<template>
    <div>
        <transition name="fade" @before-leave="beforeLeave" @leave="leave">
            <div v-if="this.loading == true" class="loading">
                <LandingLoadingAnimation />
            </div>
        </transition>
        <!-- <div style="padding-right: 56px"> -->
        <div>
            <HomeQuestionerMain class="desktop-only" />
            <HomeQuestionerMobile class="mobile-only" />
            <Spacer132 />
            <HomeWho />
            <Spacer160 />
            <HomeHow />
            <SpacerModule24 />
            <SpacerModule8 />
            <ReviewsComponent />
            <SpacerModule24 />
            <SpacerModule8 />
            <section class="cta-section">
                <div class="texts">
                    <div class="light-text">
                        Мы верим, что ты уже можешь
                    </div>
                    <div class="dark-text">
                        монетизировать свой опыт и сделать мир чуточку лучше
                    </div>
                </div>
                <div></div>
                <div></div>
                <div class="buttons">
                    <button @click="navigateToPage('/register')" class="btn btn-primary">Стать наставником</button>
                    <button class="btn btn-text"
                        onclick="window.open('https://t.me/vladislav_yakunin', '_blank')">Задать вопрос</button>
                </div>
            </section>
        </div>
    </div>

</template>

<script>
/* eslint-disable */

import HomeQuestionerMain from '../components/HomePage/HomeQuestionerMain.vue';
import HomeQuestionerMobile from '../components/HomePage/HomeQuestionerMobile.vue';
import HomeWho from '../components/HomePage/HomeWho.vue';
import SpacerModule64 from '@/components/Spacers/SpacerModule64.vue';
import SpacerModule16 from '@/components/Spacers/SpacerModule16.vue';
import Spacer132 from '../components/Spacers/SpacerModule132.vue';
import Spacer160 from '../components/Spacers/SpacerModule160.vue';
import SpacerModule24 from '@/components/Spacers/SpacerModule24.vue';
import SpacerModule8 from '@/components/Spacers/SpacerModule8.vue';
import HomeHow from '../components/HomePage/HomeHow.vue';
import ReviewsComponent from '@/components/HomePage/ReviewsComponent.vue';
import LandingLoadingAnimation from '@/components/Animations/LandingLoadingAnimation.vue';
import ref from 'vue';


export default {
    name: 'HomePage',
    components: {
        HomeQuestionerMain,
        HomeWho,
        Spacer132,
        Spacer160,
        SpacerModule64,
        SpacerModule16,
        HomeHow,
        ReviewsComponent,
        SpacerModule24,
        SpacerModule8,
        LandingLoadingAnimation,
        HomeQuestionerMobile,
    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        navigateToPage(route) {
            this.$router.push(route);
        },
    },
    mounted() {
        setTimeout(() => {
            this.loading = false; // Установить индикатор загрузки в false после задержки
        }, 400);
    }
}


</script>


<style scoped>
html,
body,
head {
    margin: 0;
    padding: 0;
    max-width: 100vw;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    /* Чтобы индикатор покрывал весь экран */
    top: 0;
    left: 0;
    display: flex;
    /* Для центрирования содержимого */
    justify-content: center;
    /* Горизонтальное центрирование */
    align-items: center;
    /* Вертикальное центрирование */
    opacity: 1;
    z-index: 40;
    transition: opacity 0.5s;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
    /* Время анимации */
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active в версиях <2.1.8 */
    {
    opacity: 0;
    /* Начальное значение прозрачности */
}

.cta-section {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.texts {
    display: flex;
    flex-direction: column;

}

.light-text {
    color: rgba(129, 98, 233, 0.4);
    font-size: 60px;
    font-weight: 600;
    line-height: 68px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.dark-text {
    color: rgba(129, 98, 233, 1);
    font-size: 60px;
    font-weight: 600;
    line-height: 68px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.btn {
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}

.btn-primary {
    background: #8162e9;
    color: #FFFFFF;
    padding: 16px 32px;
    border-radius: 12px;
    width: 100%;
    transition: background-color 0.2s ease;
}

.btn-primary:hover {
    background: #7b65f7;
}

.btn-text {
    background: transparent;
    color: #8162e9;
    padding: 8px;
}

.btn-text:hover {
    text-decoration: underline;
}

.mobile-only {
    display: none;
}

@media (max-width: 1024px) {
    .cta-section {
        flex-direction: column;
        text-align: center;
        padding: 4rem 1rem;
    }

    h2 {
        font-size: 36px;
        max-width: 100%;
    }

    .buttons {
        width: 100%;
        max-width: 320px;
    }
}

@media (max-width: 768px) {
    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none;
    }

    .cta-section {
        display: flex;
        flex-direction: column;
    }

    .light-text {
        color: rgba(129, 98, 233, 0.4);
        font-size: 30px;
        font-weight: 600;
        line-height: 33.96px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    .dark-text {
        color: rgba(129, 98, 233, 1);
        font-size: 30px;
        font-weight: 600;
        line-height: 33.96px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
}
</style>