<template>
    <div class="HomeWho">
        <div class="title">
            Кто нам подходит
        </div>
        <Spacer48 />
        <Spacer16 />
        <div class="who">
            <div class="imgs">
                <img src='../../assets/images/landing/img.svg' alt='first'>
                <div class="txt">
                    <div class="subtitle">
                        Опытные мастера
                    </div>
                    <Spacer8 />
                    Подтвержденные сертификатами и дипломами навыки,
                    которым вы долгое время обучались когда-то или только что,
                    то поможет повысить стоимость ваших занятий и ускорит набор первых учеников
                </div>
            </div>
            <div class="imgs">
                <img src='../../assets/images/landing/img-2.svg' alt='second'>
                <div class="txt">
                    <div class="subtitle">
                        Начинающие наставники
                    </div>
                    <Spacer8 />
                    Если у вас есть навыки, но вы еще ими не делились,
                    главное — уверенные знания в своем ремесле, а с остальным мы поможем
                </div>
            </div>
            <div class="imgs">
                <img src='../../assets/images/landing/img-3.svg' alt='third'>
                <div class="txt">
                    <div class="subtitle">
                        Визионеры
                    </div>
                    <Spacer8 />
                    Если вам хочется делиться не навыками,
                    а знаниями, консультируйте и продвигайте свои идеи,
                    монетизируйте их и помогайте новичкам
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spacer48 from '../Spacers/SpacerModule48.vue';
import Spacer16 from '../Spacers/SpacerModule16.vue';
import Spacer8 from '../Spacers/SpacerModule8.vue';
export default {
    name: 'HomeWho',
    components: {
        Spacer48,
        Spacer16,
        Spacer8,
    },
}
</script>

<style scoped>
.HomeWho {
    color: #525A7D;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}

.imgs {
    object-fit: cover;
    width: 100%;
    background: white;
    border-radius: 20px;
}

.title {
    font-size: 64px;
    font-weight: 550;
    line-height: 72px;
    letter-spacing: -0.02em;
    text-align: center;
}

.who {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
}

.who img {
    border-radius: 20px;
    width: 100%;
}

.subtitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral700, rgba(82, 90, 125, 1));

    /*
        font-size: 24px;
        font-weight: 550;
        line-height: 28px;
        text-align: left;
        */
}

.txt {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    /*
font-size: 20px;
*/
}

@media (max-width: 768px) {
    .who {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

</style>
