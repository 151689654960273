<template>
    <div class="mentor-card-mobile" :style="{ width: mentorCardMobileWidth }">
        <div class="mentor-avatar">
            <div class="profile-container">
                <LinkToMentor :mentorId="mentor.id">
                    <div class="profile-image">
                        <img :src="mentor.image">
                    </div>
                </LinkToMentor>
            </div>
        </div>

        <div class="content-hero">
            <LinkToMentor :mentorId="mentor.id">
                <div class="content-hero-name">
                    {{ mentor.name }}
                </div>
            </LinkToMentor>
            <div class="content-hero-title">
                {{ mentor.tg_title }}
            </div>
            <div class="content-hero-info">
                <div class="content-hero-price">
                    от {{ mentor.price }} за сессию
                </div>
                <div class="content-hero-likes">
                    <button class="content-hero-like-button" @click="toggleLike" :class="{ 'liked': isLiked }">
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.31802 2.38052C0.56066 4.13788 0.56066 6.98712 2.31802 8.74448L10.0001 16.4265L17.682 8.74448C19.4393 6.98712 19.4393 4.13788 17.682 2.38052C15.9246 0.62316 13.0754 0.62316 11.318 2.38052L10.0001 3.69859L8.68198 2.38052C6.92462 0.62316 4.07538 0.62316 2.31802 2.38052Z"
                                stroke="#7B65F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                    <div class="content-hero-likes-count">
                        {{ mentor.likes }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { computed, ref, defineProps } from 'vue';
import LinkToMentor from '@/components/Links/LinkToMentor.vue';
import { useWindowSize } from '@vueuse/core'

const props = defineProps({
    mentor: {
        type: Object,
        required: true
    }
});

const isLiked = ref(false);

const toggleLike = () => {
    isLiked.value = !isLiked.value;
};

const mentorCardMobileWidth = computed(() => {
    const { width } = useWindowSize();
    const minWidth = 320;
    return `${Math.max(width.value - 40, minWidth)}px`;
});
</script>

<style scoped>
.mentor-card-mobile {
    position: relative;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mentor-avatar {
    width: 100%;
    background-color: #F6F6F6;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    border-radius: 12px;
}

.profile-container {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(45deg,
            #f0f0f0,
            #f0f0f0 10px,
            #e0e0e0 10px,
            #e0e0e0 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
}

.profile-photo {
    width: 100%;
    max-width: 150px;
    height: auto;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-image {
    width: 150px;
    height: 148.06px;
    border-radius: 136px;
    overflow: hidden;
    margin-bottom: 15px;
}

.profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-hero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.content-hero-name {
    font-weight: 600;
    font-size: 32px;
    color: #2A2037;
    margin: 0;
}

.content-hero-title {
    font-size: 20px;
    font-weight: 500;
    color: #434966;
}

.content-hero-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.content-hero-price {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #525A7D;
}

.content-hero-like-button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 8px;
    background: none;
    cursor: pointer;
    padding: 0;
}

.content-hero-like-button.liked svg path {
    fill: #7B65F7;
}

.content-hero-likes {
    display: flex;
    align-items: center;
}

.content-hero-likes-count {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #7B65F7;
}
</style>
