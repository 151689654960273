<template>
    <section class="testimonials">
        <h2>Истории наших наставников</h2>

        <div class="testimonials-container" ref="scrollContainer">
            <div class="testimonials-track">
                <div class="testimonial-card">
                    <p>
                        Я уже давно использую [Название платформы] для проведения вебинаров и очень доволен! Интерфейс
                        простой и интуитивный, даже начинающий преподаватель легко разберется. Возможности для
                        взаимодействия с аудиторией широкие: чат, опросы, поднятие руки, демонстрация экрана. Качество
                        трансляции стабильное, звук четкий. Особенно ценю возможность записи вебинаров и возможность
                        поделиться записью с участниками, которые не смогли присутствовать в прямом эфире. Рекомендую
                        всем преподавателям!
                    </p>
                    <div class="testimonial-author">
                        <strong>Иван Петров</strong>
                        <span>Преподаватель онлайн-курсов по маркетингу</span>
                    </div>
                </div>

                <div class="testimonial-card">
                    <p>
                        Я был приятно удивлен возможностями [Название платформы]. Раньше я использовал другие платформы,
                        но они не были не так функциональны. Здесь есть все, что нужно для проведения успешных
                        вебинаров: четкая трансляция, удобный чат, возможность запускать опросы и различные
                        интерактивные элементы. Очень удобно, что можно провести вебинар как в онлайн-режиме, так и в
                        записи. Рекомендую всем, кто ищет простую и эффективную платформу для проведения вебинаров!
                        Рекомендую всем, кто ищет простую и эффективную платформу д...
                    </p>
                    <div class="testimonial-author">
                        <strong>Дмитрий Соколов</strong>
                        <span>Фитнес-тренер</span>
                    </div>
                </div>

                <div class="testimonial-card">
                    <p>
                        Для меня как для психолога это настоящим спасением. Удобное устройства, без необходимости
                        скачивать программы. Платформа предоставляет все необходимые функции для работы, но особенно
                        ценю возможность общения с участниками. Рекомендую как простую и эффективную платформу...
                    </p>
                    <div class="testimonial-author">
                        <strong>Ольга И.</strong>
                        <span>Психолог</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-controls">
            <button class="slider-btn prev" @click="scroll('left')" aria-label="Предыдущий отзыв">
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.8889 38.8881L14 27.9992M14 27.9992L24.8889 17.1104M14 27.9992L42 27.9992"
                        stroke="#434966" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </button>
            <button class="slider-btn next" @click="scroll('right')" aria-label="Следующий отзыв">
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.1111 17.1104L42 27.9992M42 27.9992L31.1111 38.8881M42 27.9992L14 27.9992"
                        stroke="#434966" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </button>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const scrollContainer = ref(null)

const scroll = (direction) => {
    if (!scrollContainer.value) return

    const cardWidth = scrollContainer.value.offsetWidth * 0.4  // 40% of container width
    const currentScroll = scrollContainer.value.scrollLeft

    scrollContainer.value.scrollTo({
        left: direction === 'right'
            ? currentScroll + cardWidth
            : currentScroll - cardWidth,
        behavior: 'smooth'
    })
}

onMounted(() => {
    if (scrollContainer.value) {
        scrollContainer.value.scrollLeft = 0  // Reset scroll position on mount
    }
})
</script>

<style scoped>
.testimonials {
    padding: 5% 0;
    width: 100%;
    overflow: hidden;
}

h2 {
    font-size: clamp(2rem, 4vw, 2.5rem);
    color: #434966;
    margin-bottom: 2.5rem;
    font-weight: 600;
    text-align: center;
}

.testimonials-container {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    padding: 1.25rem 0;
    width: 100%;
}

.testimonials-container::-webkit-scrollbar {
    display: none;
}

.testimonials-track {
    display: flex;
    gap: 1.5rem;
    padding: 0 1px;
    width: fit-content;
}

.testimonial-card {
    background: #FFFFFF;
    border-radius: 1rem;
    padding: 2rem;
    /* 40% of container width minus half of the gap */
    /* width: calc(40% - 0.75rem);  */
    width: calc(40% - 5rem);
    /* 40% of container width minus half of the gap */
    flex-shrink: 0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.testimonial-card p {
    color: #525a7d;
    font-size: 0.875rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

.testimonial-author {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.testimonial-author strong {
    color: #2a2037;
    font-size: 1rem;
    font-weight: 600;
}

.testimonial-author span {
    color: #525a7d;
    font-size: 0.875rem;
}

.slider-controls {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
}

.slider-btn {
    /*
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #8162e9;
    color: #8162e9;
    display: flex;
    align-items: center;
    justify-content: center;
    */
    border: none;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease;
}

.slider-btn:hover {
    background: #faf7fd;
}

@media (max-width: 1024px) {
    .testimonial-card {
        width: calc(50% - 0.75rem);
        /* 50% of container width minus half of the gap */
    }
}

@media (max-width: 768px) {
    .testimonials {
        padding: 2.5rem 1.25rem;
    }

    .testimonial-card {
        width: calc(60% - 0.75rem);
        /* 85% of container width minus half of the gap */
    }
}
</style>