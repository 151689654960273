<template>
    <div class="description-block" ref="blockRef">
      <div class="description-header">
        <span>Обо мне</span>
      </div>
  
      <div class="description-content" ref="contentRef">
        <div class="description-text">
          {{ truncatedDescription }}
        </div>
      </div>
  
      <button v-if="description.length > 250" @click="openModal" class="show-more-btn">
        Показать всё
      </button>
  
      <teleport to="body">
        <transition name="modal">
          <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
            <div class="modal-content platforma-font" @click.stop>
              <button @click="closeModal" class="close-btn">&times;</button>
              <span>{{ title }}</span>
              <div class="description-text">
                {{ description }}
              </div>
            </div>
          </div>
        </transition>
      </teleport>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed } from 'vue'
  
  export default defineComponent({
    name: 'DescriptionComponentDesktop',
    props: {
    //   title: {
    //     type: String,
    //     default: 'Обо мне'
    //   },
      description: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const isModalOpen = ref(false);
      const blockRef = ref<HTMLElement | null>(null);
      const contentRef = ref<HTMLElement | null>(null);
  
      const truncatedDescription = computed(() => {
        return props.description.length > 250
          ? props.description.slice(0, 250) + '...'
          : props.description;
      });
  
      const openModal = () => {
        isModalOpen.value = true;
        document.body.style.overflow = 'hidden';
      };
  
      const closeModal = () => {
        isModalOpen.value = false;
        document.body.style.overflow = '';
      };
  
      return {
        isModalOpen,
        openModal,
        closeModal,
        blockRef,
        contentRef,
        truncatedDescription
      };
    },
  })
  </script>
  
  <style scoped>
  .description-block {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 224px;
    overflow: hidden;
  }
  
  .description-header {
    margin-bottom: 20px;
  }
  
  .description-header span {
    font-size: 32px;
    font-weight: 400;
    color: #2A2037;
    margin: 0;
  }
  
  .description-content {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .description-text {
    font-size: 16px;
    color: #454152;
    line-height: 1.5;
  }
  
  .show-more-btn {
    background: none;
    border: none;
    color: #5850ec;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin-top: 10px;
    transition: color 0.3s ease;
  }
  
  .show-more-btn:hover {
    color: #4338ca;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 32px;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
  }
  
  .modal-content span {
    font-size: 32px;
    font-weight: 400;
    color: #2A2037;
    margin-bottom: 20px;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #2A2037;
  }
  
  .modal-enter-active,
  .modal-leave-active {
    transition: all 0.3s ease;
  }
  
  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
  }
  
  .modal-enter-from .modal-content,
  .modal-leave-to .modal-content {
    transform: scale(0.9);
  }
  </style>