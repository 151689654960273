<template>
    <div class="Review">
        <div class="ReviewItem">
            <div class="ReviewText">
                {{ review.review_text }}
            </div>
            <Spacer16></Spacer16>
            <div class="ReviewAuthor">
                <img src="https://s3-alpha-sig.figma.com/img/418a/f7ad/deab4a457eff43838555f24a193d678f?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=opVAeNPhkgiLQtg8GXspCyr7svd3Ip9tyvsz8NvlOrUgtO0LFF35f5kOdmATAlZI8OCA9VkCsZwdq~UoJL2aV4PjHlEcPysnnKHAk0unYuSbSP1hOYTsi4PFHUaKH1Ift2EEm5jWHzlRsh7vBi8SWz5B7qmE50hBsyW8WpmFm9gTwIVoEPGaGn7ZyeoiA3DzIrgRXgc2GypPngfC-TMWrlyt-zGp~VTh~94VoXyUVWKa6ofiBG1eg9n1G4zVqofCHS859M4NhZFhSdAiUP7QcviiTGxccRM50mdU~O6yq3DQufqVkggqFU1jWFvU3K0wkY2Nk2pRKidQ9MROByrrrg__"
                    alt="Mentor Image" class="ReviewPFP" />
                <div class="ReviewName">
                    Дмитрий Карацуба
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//   import axios from 'axios';
// import ReviewItem from './ReviewItem.vue';
// import ReviewList from './ReviewList.vue';
import Spacer16 from "../Spacers/SpacerModule16.vue"
export default {
    name: 'ReviewItem',
    components: {
        //     ReviewItem,
        Spacer16,
        //     ReviewList,
    },
    props: {
        review: {
            type: Object,
            default: () => ({}),
        },
        // from_user_id: {
        //     type: String,
        //     required: true
        // },
        // to_user_id: {
        //     type: String,
        //     required: true
        // },
        // review_text: {
        //     type: String,
        //     required: true
        // },
        // review_stars: {
        //     type: String,
        //     required: true
        // },
        // review_date: {
        //     type: String,
        //     required: true
        // },
    },
    // props: {
    //   review_text: {
    //     type: String,
    //     required: true
    //   },
    //   review_author: {
    //     type: String,
    //     required: true
    //   },
    //   review_event: {
    //     type: Number,
    //     required: true
    //   },
    //   event_imageUrl: {
    //     type: String,
    //     required: true
    //   },
    //   event_ageRestriction: {
    //     type: String,
    //     required: true
    //   },
    //   event_availableSpots: {
    //     type: Number,
    //     required: true
    //   },
    //   event_totalSpots: {
    //     type: Number,
    //     required: true
    //   },
    //   event_category: {
    //     type: String,
    //     required: true
    //   }
    // }
}

</script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
    width: 100vw;
}

.ReviewItem {
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    background-color: white;
    /* width: 100%; */
    /* height: 12vh; */
    border-radius: 0.5208vw;
    padding: 1.5vw;
}

.ReviewPFP {
    height: 1.5vw;
    width: 1.5vw;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    align-self: center;
    margin-right: 1vw;
}

.ReviewAuthor {
    display: flex;
    flex-direction: row;
}

.ReviewName {
    color: #454152;
    font-size: 1vw;
    font-weight: 500;
}

.ReviewText {
    color: #454152;
    font-size: 1vw;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>