<template>
    <div class="loader-container" :class="{ 'is-loading': isLoading }">
        <div class="loader" role="status">
            <div class="dot-container">
                <div class="dot"></div>
                <div class="shadow"></div>
            </div>
            <div class="dot-container">
                <div class="dot"></div>
                <div class="shadow"></div>
            </div>
            <div class="dot-container">
                <div class="dot"></div>
                <div class="shadow"></div>
            </div>
            <div class="dot-container">
                <div class="dot"></div>
                <div class="shadow"></div>
            </div>
        </div>
        <p v-if="loadingText" class="loading-text">{{ loadingText }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'JumpingDotLoaderWithShadows',
    props: {
        isLoading: {
            type: Boolean,
            default: true
        },
        loadingText: {
            type: String,
            default: ''
        }
    },
    // setup(props) {
    //     const { isLoading, loadingText } = toRefs(props)
    //     // return {
    //     //     isLoading,
    //     //     loadingText,
    //     // }
    // }
})
</script>

<style scoped>
.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--neutral-neutral100, #F3F4F5);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.loader-container.is-loading {
    opacity: 1;
    visibility: visible;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.dot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8px;
}

.dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #8b5cf6;
    animation: jump 0.5s ease-in-out infinite alternate;
    box-shadow: 0 2px 5px rgba(139, 92, 246, 0.3);
}

.shadow {
    width: 16px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    filter: blur(1px);
    animation: shadow 0.5s ease-in-out infinite alternate;
}

.dot-container:nth-child(1) .dot,
.dot-container:nth-child(1) .shadow {
    animation-delay: -0.4s;
}

.dot-container:nth-child(2) .dot,
.dot-container:nth-child(2) .shadow {
    animation-delay: -0.3s;
}

.dot-container:nth-child(3) .dot,
.dot-container:nth-child(3) .shadow {
    animation-delay: -0.2s;
}

.dot-container:nth-child(4) .dot,
.dot-container:nth-child(4) .shadow {
    animation-delay: -0.1s;
}

.loading-text {
    margin-top: 1.5rem;
    font-size: 1rem;
    color: #4a5568;
    font-weight: 500;
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

@keyframes shadow {
    0% {
        transform: scale(1);
        opacity: 0.3;
    }

    100% {
        transform: scale(0.7);
        opacity: 0.1;
    }
}
</style>
