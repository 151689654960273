<template>
    <header class="header">
        <a href="/" class="logo">ФОРМА</a>

        <div class="toggle-container">
            <div class="toggle-wrapper">
                <div class="toggle-slider"
                    :style="{ transform: `translateX(${currentPath === '/AllMentors' ? '100%' : '0'})`, background: `${(currentPath === '/AllMentors' || currentPath === '/AllEvents') ? 'var(--accent-accent100, #DCD8FC)' : 'none'}` }">
                </div>
                <router-link to="/AllEvents" class="toggle-button" :class="{ active: currentPath === '/AllEvents' }">
                    Мероприятия
                </router-link>
                <router-link to="/AllMentors" class="toggle-button" :class="{ active: currentPath === '/AllMentors' }">
                    Менторы
                </router-link>
            </div>
        </div>

        <button @click="navigateToPage('/ProfileSettings')" class="login-button">Войти</button>
    </header>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const currentPath = ref(route.path)

// Watch for route changes
watch(
    () => route.path,
    (newPath) => {
        currentPath.value = newPath
    }
)

const navigateToPage = (route) => {
    router.push(route);
}

// Set initial path on component mount
onMounted(() => {
    currentPath.value = route.path
})
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    /* background-color: #ffffff; */
    background: var(--neutral-neutral100, #F3F4F5);
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); */
    box-shadow: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.logo {
    color: #8162e9;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.toggle-container {
    position: relative;
}

.toggle-wrapper {
    position: relative;
    display: flex;
    /* background-color: #f3f4f5; */
    background-color: white;

    border-radius: 100px;
    padding: 4px;
    cursor: pointer;
    width: 280px;
}

.toggle-slider {
    position: absolute;
    width: 49%;
    height: calc(100% - 8px);
    background: var(--accent-accent100, #DCD8FC);
    border-radius: 100px;
    transition: all 0.3s ease;

}

.toggle-button {
    position: relative;
    z-index: 1;
    width: 50%;
    padding: 8px 16px;
    border: none;
    background: none;
    color: var(--accent-accent800, #5747AF);

    /*color: #454152;*/
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;
    text-align: center;
}

.toggle-button.active {
    color: var(--accent-accent600, #705CE2);
}

.login-button {
    padding: 8px 24px;
    border: none;
    background: none;
    color: #454152;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.2s ease;
}

.login-button:hover {
    color: #8162e9;
}
</style>


<!-- <template>
    <header class="header">

        <div class="logo-section">
            <svg width="111" height="21" viewBox="0 0 111 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M103.223 16.98H96.2031L94.7631 21H89.6631L97.3131 0H102.713L110.363 21H104.663L103.223 16.98ZM97.7331 12.84H101.693L99.7131 6.6L97.7331 12.84Z"
                    fill="#8162E9" />
                <path
                    d="M66.5742 0H72.0942L77.9142 10.65L83.6742 0H89.0742V21H83.6742V8.97L79.3242 16.95H76.3242L71.9742 8.97V21H66.5742V0Z"
                    fill="#8162E9" />
                <path
                    d="M48.2764 0H56.8264C59.7464 0 61.8964 0.64 63.2764 1.92C64.6764 3.18 65.3764 4.93 65.3764 7.17C65.3764 9.41 64.6764 11.17 63.2764 12.45C61.8964 13.71 59.7464 14.34 56.8264 14.34H53.6764V21H48.2764V0ZM56.2264 9.9C57.6064 9.9 58.5764 9.67 59.1364 9.21C59.6964 8.75 59.9764 8.07 59.9764 7.17C59.9764 6.27 59.6964 5.59 59.1364 5.13C58.5764 4.67 57.6064 4.44 56.2264 4.44H53.6764V9.9H56.2264Z"
                    fill="#8162E9" />
                <path
                    d="M35.7998 21C34.4469 21 33.1625 20.8072 31.9469 20.4215C30.7312 20.0358 29.6625 19.4284 28.741 18.5992C27.839 17.7507 27.1233 16.6804 26.5939 15.3884C26.0645 14.0771 25.7998 12.4959 25.7998 10.6446V10.0661C25.7998 8.29201 26.0645 6.7686 26.5939 5.49587C27.1233 4.22314 27.839 3.18182 28.741 2.3719C29.6625 1.56198 30.7312 0.964188 31.9469 0.578513C33.1625 0.192838 34.4469 0 35.7998 0C37.1527 0 38.4371 0.192838 39.6527 0.578513C40.8684 0.964188 41.9273 1.56198 42.8292 2.3719C43.7508 3.18182 44.4763 4.22314 45.0057 5.49587C45.5351 6.7686 45.7998 8.29201 45.7998 10.0661V10.6446C45.7998 12.4959 45.5351 14.0771 45.0057 15.3884C44.4763 16.6804 43.7508 17.7507 42.8292 18.5992C41.9273 19.4284 40.8684 20.0358 39.6527 20.4215C38.4371 20.8072 37.1527 21 35.7998 21ZM35.7998 16.6612C36.388 16.6612 36.9567 16.5744 37.5057 16.4008C38.0547 16.208 38.5351 15.8898 38.9469 15.4463C39.3586 14.9835 39.692 14.3664 39.9469 13.595C40.2018 12.8237 40.3292 11.8402 40.3292 10.6446V10.0661C40.3292 8.94766 40.2018 8.02204 39.9469 7.28926C39.692 6.53719 39.3586 5.94904 38.9469 5.52479C38.5351 5.08127 38.0547 4.77273 37.5057 4.59917C36.9567 4.42562 36.388 4.33884 35.7998 4.33884C35.2116 4.33884 34.6429 4.42562 34.0939 4.59917C33.5449 4.77273 33.0645 5.08127 32.6527 5.52479C32.241 5.94904 31.9076 6.53719 31.6527 7.28926C31.3978 8.02204 31.2704 8.94766 31.2704 10.0661V10.6446C31.2704 11.8402 31.3978 12.8237 31.6527 13.595C31.9076 14.3664 32.241 14.9835 32.6527 15.4463C33.0645 15.8898 33.5449 16.208 34.0939 16.4008C34.6429 16.5744 35.2116 16.6612 35.7998 16.6612Z"
                    fill="#8162E9" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.14 19.2C8.58 19.2 7.17 18.97 5.91 18.51C4.67 18.05 3.61 17.4 2.73 16.56C1.85 15.72 1.17 14.71 0.69 13.53C0.23 12.35 0 11.04 0 9.6V9C0 7.72 0.23 6.53 0.69 5.43C1.17 4.33 1.85 3.38 2.73 2.58C3.61 1.76 4.67 1.13 5.91 0.69C7.17 0.23 8.58 0 10.14 0H15.36C16.92 0 18.32 0.23 19.56 0.69C20.82 1.13 21.89 1.76 22.77 2.58C23.65 3.38 24.32 4.33 24.78 5.43C25.26 6.53 25.5 7.72 25.5 9V9.6C25.5 11.04 25.26 12.35 24.78 13.53C24.32 14.71 23.65 15.72 22.77 16.56C21.89 17.4 20.82 18.05 19.56 18.51C18.32 18.97 16.92 19.2 15.36 19.2V21H10.14V19.2ZM18.72 5.58C19.52 6.3 19.92 7.44 19.92 9V9.6C19.92 11.44 19.53 12.75 18.75 13.53C17.97 14.31 16.84 14.7 15.36 14.7V4.5C16.8 4.5 17.92 4.86 18.72 5.58ZM6.72 13.53C5.96 12.75 5.58 11.44 5.58 9.6V9C5.58 7.44 5.97 6.3 6.75 5.58C7.53 4.86 8.66 4.5 10.14 4.5V14.7C8.62 14.7 7.48 14.31 6.72 13.53Z"
                    fill="#8162E9" />
            </svg>
        </div>

        <div class="search-section">
            <div class="dropdown">
                <button class="dropdown-button">
                    Выбрать
                    <span class="dropdown-arrow">
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.292893 0.292893C0.683417 -0.097631 1.31658 -0.097631 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z"
                                fill="#A2A5B3" />
                        </svg>
                    </span>
                </button>
                <div class="dropdown-content">
                    <a @click="handleAllMentorsClick">Поиск ментора</a>
                    <a @click="handleAllEventsClick">Поиск мероприятия</a>
                </div>
            </div>
            <div class="search-container">
                <button class="search-button">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                            stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
                <input type="text" placeholder="Искать менторов" class="search-input" />
            </div>
        </div>

        <div class="profile" @click="handleProfileClick">
            <img class="profile"
                src="https://storage.yandexcloud.net/testbucketplatforma34/photo_woman_beauty.webp">
        </div>
        
    </header>
</template>

<script>
import { defineComponent } from 'vue'

import { useRouter } from 'vue-router'

export default defineComponent({
    name: "HeaderDefault",
    setup() {
        const router = useRouter()

        const handleProfileClick = () => {
            router.push('/ProfileSettings')
        }

        const handleAllMentorsClick = () => {
            router.push('/AllMentors')
        }

        const handleAllEventsClick = () => {
            router.push('/AllEvents')
        }

        return {
            handleProfileClick,
            handleAllMentorsClick,
            handleAllEventsClick
        }
    },
})
</script>


<style scoped>
.header {
    position: fixed;
    top: 0;
    left: 0;
    /* Width of sidebar */
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    background: white;
    z-index: 1000;
    border-bottom: 1px solid #E5E7EB;
    padding: 0px 80px;
}

.logo-section {
    cursor: pointer;
}


.search-section {
    display: flex;
    align-items: center;
    gap: 16px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background: #F3F4F6;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 14px;
}

.dropdown-arrow {
    font-size: 10px;
    color: #6B7280;
}

.dropdown-content {
    display: none;
    position: absolute;
    background: white;
    min-width: 160px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a {
    color: #1F2937;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 14px;
}

.dropdown-content a:hover {
    background: #F3F4F6;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}

.search-input {
    width: 100%;
    padding: 8px 8px 8px 36px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    background: transparent;
}

.search-input:focus {
    outline: none;
}

.search-container:focus-within {
    border-color: #8162E9;
    box-shadow: 0 0 0 2px rgba(129, 98, 233, 0.1);
}

.search-button {
    position: absolute;
    left: 8px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
}

.profile {
    width: 42px;
    height: 42px;
    background: #F3F4F6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    object-fit: cover;
}

</style> -->